import fetch from "auth/FetchInterceptor";

const TestobxPerTechnicianService = {};

TestobxPerTechnicianService.get = function (payload) {
  return fetch({
    url:
      "/reports/testobx-per-techincian?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=" + payload.agency + 
      "&department=null",
    method: "get",
  });
};

export default TestobxPerTechnicianService;
