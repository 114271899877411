import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_PATIENT_REQUEST,
  DELETE_PATIENT_REQUEST,
  FETCH_PATIENTS_REQUEST,
  UPDATE_PATIENT_REQUEST,
} from "constants/Patient";
import {
  createPatientFailure,
  createPatientSuccess,
  deleteOrderAllPatientFailure,
  deleteOrderAllPatientRequest,
  deleteOrderAllPatientSuccess,
  deletePatientFailure,
  fetchPatientsFailure,
  fetchPatientsSuccess,
  setPatientValidationErrors,
  updatePatientDemographySuccess,
  updatePatientFailure,
  updatePatientSuccess,
} from "redux/actions";
import PatientService from "services/Patient";

export function* onFetchPatients() {
  yield takeLatest(FETCH_PATIENTS_REQUEST, function* () {
    try {
      const result = yield call(PatientService.index);
      if (result.status) {
        yield put(fetchPatientsSuccess(result.data));
      } else {
        yield put(fetchPatientsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPatientsFailure(error.message));
    }
  });
}

export function* onUpdatePatient() {
  yield takeLatest(UPDATE_PATIENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(PatientService.update, payload.webSampleId, payload);
      if (result.status) {
        message.success("Patient Data Updated Successfully");
        yield put(updatePatientSuccess(result.data));
        yield put(updatePatientDemographySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setPatientValidationErrors(result.data.errors));
        yield put(updatePatientFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updatePatientFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updatePatientFailure(error.message));
    }
  });
}

export function* onDeletePatient() {
  yield takeLatest(DELETE_PATIENT_REQUEST, function* ({ payload }) {
    try {
      
      //start the loading action
      yield put(deleteOrderAllPatientRequest(null));
      const result = yield call(PatientService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteOrderAllPatientSuccess(result.data));
      } else {
        message.success("Try again");
        yield put(deleteOrderAllPatientFailure(null))
      }
    } catch (error) {
      // console.log(error)
      message.success("Try again");
      yield put(deleteOrderAllPatientFailure(null))
    }
  });
}

export function* onCreatePatient() {
  yield takeLatest(CREATE_PATIENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(PatientService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createPatientSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setPatientValidationErrors(result.data.errors));
        yield put(createPatientFailure(result.message));
      } else {
        yield put(createPatientFailure(result.message));
      }
    } catch (error) {
      yield put(deletePatientFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchPatients),
    fork(onUpdatePatient),
    fork(onDeletePatient),
    fork(onCreatePatient),
  ]);
}
