import _ from "lodash";

export const addTestRegistration = (state, action) => {
  let list = state.list;
  const savedTestRegistration = action.payload;

  return [savedTestRegistration, ...list];
};

export const updateTestRegistration = (state, action) => {
  let list = state.list;
  const updatedTestRegistration = action.payload;

  const index = _.findIndex(list, ["id", updatedTestRegistration.id]);
  list[index] = updatedTestRegistration;

  return [...list];
};

export const removeTestRegistration = (state, action) => {
  let list = state.list;
  const deletedTestRegistrationId = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedTestRegistrationId;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
