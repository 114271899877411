import fetch from "auth/FetchInterceptor";

const PatientResultService = {};

PatientResultService.index = function (payload) {
  return fetch({
    url:
      "/reports/patientresult-all?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=" + payload.agency + "&department=null",
    method: "get",
  });
};

PatientResultService.searchpatient = function (payload) {
  return fetch({
    url: "/searchpatient",
    method: "post",
    data:payload
  });
}

PatientResultService.fetchDetailedPatientResult = function (patientid) {
  return fetch({
    url: "/reports/patientresult-all?patientid=" + patientid,
    method: "get",
  });
}


export default PatientResultService;
