import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_INPERSON_DELIVERY_REQUEST } from "constants/InPersonDelivery";
import {
    fetchInPersonDeliveryFailure,
    fetchInPersonDeliverySuccess
} from "redux/actions/InPersonDelivery"
import { message } from "antd";
import InPersonDelivery from "services/InPersonDeliver";

export function* onFetchInpersonDelivery() {
    yield takeLatest(FETCH_INPERSON_DELIVERY_REQUEST, function* (payload) {
      try {
        const result = yield call(InPersonDelivery.get, payload.payload);
        if (result.status) {
          yield put(fetchInPersonDeliverySuccess(result.data));
        } else {
          yield put(fetchInPersonDeliveryFailure(result.message));
        }
      } catch (error) {
        message.error("Something went wrong");
        yield put(fetchInPersonDeliveryFailure(error.message));
      }
    });
  }


  export default function* rootSaga() {
    yield all([fork(onFetchInpersonDelivery)]);
  }