export const SHOW_ORDER_STATUS_EDIT_FORM = "SHOW_ORDER_STATUS_EDIT_FORM";

export const FETCH_ALL_ORDER_REQUEST = "FETCH_ALL_ORDER_REQUEST";
export const FETCH_ALL_ORDER_SUCCESS = "FETCH_ALL_ORDER_SUCCESS";
export const FETCH_ALL_ORDER_FAILURE = "FETCH_ALL_ORDER_FAILURE";

export const FETCH_ORDER_OBX_REQUEST = "FETCH_ORDER_OBX_REQUEST";
export const FETCH_ORDER_OBX_SUCCESS = "FETCH_ORDER_OBX_SUCCESS";
export const FETCH_ORDER_OBX_FAILURE = "FETCH_ORDER_OBX_FAILURE";

export const UPDATE_ALL_ORDER_REQUEST = "UPDATE_ALL_ORDER_REQUEST";
export const UPDATE_ALL_ORDER_SUCCESS = "UPDATE_ALL_ORDER_SUCCESS";
export const UPDATE_ALL_ORDER_FAILURE = "UPDATE_ALL_ORDER_FAILURE";

export const DELETE_ORDER_ALL_PATIENT_REQUEST = "DELETE_ORDER_ALL_PATIENT_REQUEST";
export const DELETE_ORDER_ALL_PATIENT_SUCCESS = "DELETE_ORDER_ALL_PATIENT_SUCCESS";
export const DELETE_ORDER_ALL_PATIENT_FAILURE = "DELETE_ORDER_ALL_PATIENT_FAILURE";

export const UPDATE_PATIENT_DEMOGRAPHY_SUCCESS = "UPDATE_PATIENT_DEMOGRAPHY_SUCCESS";
export const SHOW_ALL_ORDER_OBX_DIALOG = "SHOW_ALL_ORDER_OBX_DIALOG";

export const FETCH_COMMUNICATED_CONTENT_REQUEST = "FETCH_COMMUNICATED_CONTENT_REQUEST";
export const FETCH_COMMUNICATED_CONTENT_SUCCESS = "FETCH_COMMUNICATED_CONTENT_SUCCESS";
export const FETCH_COMMUNICATED_CONTENT_FAILURE = "FETCH_COMMUNICATED_CONTENT_FAILURE";

export const SAVE_COMMUNICATED_CONTENT_REQUEST = "SAVE_COMMUNICATED_CONTENT_REQUEST";
export const SAVE_COMMUNICATED_CONTENT_SUCCESS = "SAVE_COMMUNICATED_CONTENT_SUCCESS";
export const SAVE_COMMUNICATED_CONTENT_FAILURE = "SAVE_COMMUNICATED_CONTENT_FAILURE";

