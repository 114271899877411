import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_DAILY_DEPARTMENT_RELEASED_TESTS_REQUEST } from "constants/DailyDepartmentReleasedTests";
import {
    fetchDailyDepartmentReleasedTestsSuccess,
    fetchDailyDepartmentReleasedTestsFailure
} from "redux/actions/DailyDepartmentReleasedTests";
import DailyDepartmentReleasedTests from "services/DailyDepartmentReleasedTests";

export function* onFetchDailyDepartmentReleasedTests() {
  yield takeLatest(FETCH_DAILY_DEPARTMENT_RELEASED_TESTS_REQUEST, function* (payload) {
    try {
      const result = yield call(DailyDepartmentReleasedTests.get, payload.payload);
      if (result.status) {
        yield put(fetchDailyDepartmentReleasedTestsSuccess(result.data));
      } else {
        message.error("Something went wrong");
        yield put(fetchDailyDepartmentReleasedTestsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(fetchDailyDepartmentReleasedTestsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchDailyDepartmentReleasedTests)]);
}
