import {
  DELETE_BRANCH_TEST_CAPACITY_FAILURE,
  DELETE_BRANCH_TEST_CAPACITY_REQUEST,
  DELETE_BRANCH_TEST_CAPACITY_SUCCESS,
  FETCH_BRANCH_TEST_CAPACITIES_FAILURE,
  FETCH_BRANCH_TEST_CAPACITIES_REQUEST,
  FETCH_BRANCH_TEST_CAPACITIES_SUCCESS,
  CREATE_BRANCH_TEST_CAPACITY_FAILURE,
  CREATE_BRANCH_TEST_CAPACITY_REQUEST,
  CREATE_BRANCH_TEST_CAPACITY_SUCCESS,
  SHOW_BRANCH_TEST_CAPACITY_EDIT_FORM,
  SHOW_BRANCH_TEST_CAPACITY_NEW_FORM,
  UPDATE_BRANCH_TEST_CAPACITY_FAILURE,
  UPDATE_BRANCH_TEST_CAPACITY_REQUEST,
  UPDATE_BRANCH_TEST_CAPACITY_SUCCESS,
  SET_BRANCH_TEST_CAPACITY_VALIDATION_ERRORS,
  FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST,
  FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_SUCCESS,
  FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_FAILURE,
  CLEAR_AVAILABLE_BRANCH_TEST_CAPACITIES,
} from "constants/BranchTestCapacity";

export const fetchBranchTestCapacitiesRequest = () => ({
  type: FETCH_BRANCH_TEST_CAPACITIES_REQUEST,
});

export const fetchBranchTestCapacitiesSuccess = (branchTestCapacities) => ({
  type: FETCH_BRANCH_TEST_CAPACITIES_SUCCESS,
  payload: branchTestCapacities,
});

export const fetchBranchTestCapacitiesFailure = (error) => ({
  type: FETCH_BRANCH_TEST_CAPACITIES_FAILURE,
  payload: error,
});

export const setBranchTestCapacityValidationErrors = (validationErrors) => ({
  type: SET_BRANCH_TEST_CAPACITY_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleBranchTestCapacityEditForm = () => ({
  type: SHOW_BRANCH_TEST_CAPACITY_EDIT_FORM,
});

export const toggleBranchTestCapacityNewForm = () => ({
  type: SHOW_BRANCH_TEST_CAPACITY_NEW_FORM,
});

export const updateBranchTestCapacityRequest = (branchTestCapacity) => ({
  type: UPDATE_BRANCH_TEST_CAPACITY_REQUEST,
  payload: branchTestCapacity,
});

export const updateBranchTestCapacitySuccess = (branchTestCapacity) => ({
  type: UPDATE_BRANCH_TEST_CAPACITY_SUCCESS,
  payload: branchTestCapacity,
});

export const updateBranchTestCapacityFailure = (error) => ({
  type: UPDATE_BRANCH_TEST_CAPACITY_FAILURE,
  payload: error,
});

export const deleteBranchTestCapacityRequest = (id) => ({
  type: DELETE_BRANCH_TEST_CAPACITY_REQUEST,
  payload: id,
});

export const deleteBranchTestCapacitySuccess = (branchTestCapacity) => ({
  type: DELETE_BRANCH_TEST_CAPACITY_SUCCESS,
  payload: branchTestCapacity,
});

export const deleteBranchTestCapacityFailure = (error) => ({
  type: DELETE_BRANCH_TEST_CAPACITY_FAILURE,
  payload: error,
});

export const createBranchTestCapacityRequest = (branchTestCapacity) => ({
  type: CREATE_BRANCH_TEST_CAPACITY_REQUEST,
  payload: branchTestCapacity,
});

export const createBranchTestCapacitySuccess = (branchTestCapacity) => ({
  type: CREATE_BRANCH_TEST_CAPACITY_SUCCESS,
  payload: branchTestCapacity,
});

export const createBranchTestCapacityFailure = (error) => ({
  type: CREATE_BRANCH_TEST_CAPACITY_FAILURE,
  payload: error,
});

export const fetchAvailableBranchTestCapacitiesRequest = (params) => ({
  type: FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST,
  payload: params,
});

export const fetchAvailableBranchTestCapacitiesSuccess = (
  avialableBranchTestCapacities
) => ({
  type: FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_SUCCESS,
  payload: avialableBranchTestCapacities,
});

export const fetchAvailableBranchTestCapacitiesFailure = (error) => ({
  type: FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_FAILURE,
  payload: error,
});

export const clearAvailableBranchTestCapacitiesRequest = () => ({
  type: CLEAR_AVAILABLE_BRANCH_TEST_CAPACITIES,
});
