import {
  DELETE_EQUIPMENT_FAILURE,
  DELETE_EQUIPMENT_REQUEST,
  DELETE_EQUIPMENT_SUCCESS,
  FETCH_EQUIPMENT_FAILURE,
  FETCH_EQUIPMENT_REQUEST,
  FETCH_EQUIPMENT_SUCCESS,
  CREATE_EQUIPMENT_FAILURE,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  SHOW_EQUIPMENT_EDIT_FORM,
  SHOW_EQUIPMENT_NEW_FORM,
  UPDATE_EQUIPMENT_FAILURE,
  UPDATE_EQUIPMENT_REQUEST,
  UPDATE_EQUIPMENT_SUCCESS,
  SET_EQUIPMENT_VALIDATION_ERRORS,
} from "constants/Equipment";

export const fetchEquipmentRequest = () => ({
  type: FETCH_EQUIPMENT_REQUEST,
});

export const fetchEquipmentSuccess = (equipment) => ({
  type: FETCH_EQUIPMENT_SUCCESS,
  payload: equipment,
});

export const fetchEquipmentFailure = (error) => ({
  type: FETCH_EQUIPMENT_FAILURE,
  payload: error,
});

export const setEquipmentValidationErrors = (validationErrors) => ({
  type: SET_EQUIPMENT_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleEquipmentEditForm = () => ({
  type: SHOW_EQUIPMENT_EDIT_FORM,
});

export const toggleEquipmentNewForm = () => ({
  type: SHOW_EQUIPMENT_NEW_FORM,
});

export const updateEquipmentRequest = (equipment) => ({
  type: UPDATE_EQUIPMENT_REQUEST,
  payload: equipment,
});

export const updateEquipmentSuccess = (equipment) => ({
  type: UPDATE_EQUIPMENT_SUCCESS,
  payload: equipment,
});

export const updateEquipmentFailure = (error) => ({
  type: UPDATE_EQUIPMENT_FAILURE,
  payload: error,
});

export const deleteEquipmentRequest = (id) => ({
  type: DELETE_EQUIPMENT_REQUEST,
  payload: id,
});

export const deleteEquipmentSuccess = (equipment) => ({
  type: DELETE_EQUIPMENT_SUCCESS,
  payload: equipment,
});

export const deleteEquipmentFailure = (error) => ({
  type: DELETE_EQUIPMENT_FAILURE,
  payload: error,
});

export const createEquipmentRequest = (equipment) => ({
  type: CREATE_EQUIPMENT_REQUEST,
  payload: equipment,
});

export const createEquipmentSuccess = (equipment) => ({
  type: CREATE_EQUIPMENT_SUCCESS,
  payload: equipment,
});

export const createEquipmentFailure = (error) => ({
  type: CREATE_EQUIPMENT_FAILURE,
  payload: error,
});
