import{
    UPLOAD_CSV_FILE_REQUEST,
    UPLOAD_CSV_FILE_FAILURE,
    UPLOAD_CSV_FILE_SUCCCESS
} from "constants/UploadCsvFiles"

const initialState = {
     message : "",
     loading : false,
     errorMessage : ""
}

 const UploadCsvFile = (state =initialState, action) =>{
     switch(action.type){
         case UPLOAD_CSV_FILE_REQUEST :
            return{
                 loading: true
            }
         case UPLOAD_CSV_FILE_SUCCCESS
          : 
            return{
                 loading : false,
                 message : action.paylaod
            }
         case UPLOAD_CSV_FILE_FAILURE : 
            return{
                 loading : false,
                 errorMessage : action.paylaod
            }
        default : 
         return state;
     }
}
export default UploadCsvFile;