import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_SECTION_REQUEST,
  DELETE_SECTION_REQUEST,
  FETCH_SECTIONS_REQUEST,
  UPDATE_SECTION_REQUEST,
} from "constants/Section";
import {
  createSectionFailure,
  createSectionSuccess,
  deleteSectionFailure,
  deleteSectionSuccess,
  fetchSectionsFailure,
  fetchSectionsSuccess,
  setSectionValidationErrors,
  updateSectionFailure,
  updateSectionSuccess,
} from "redux/actions";
import SectionService from "services/Section";

export function* onFetchSections() {
  yield takeLatest(FETCH_SECTIONS_REQUEST, function* () {
    try {
      const result = yield call(SectionService.index);
      if (result.status) {
        yield put(fetchSectionsSuccess(result.data));
      } else {
        yield put(fetchSectionsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchSectionsFailure(error.message));
    }
  });
}

export function* onUpdateSection() {
  yield takeLatest(UPDATE_SECTION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        SectionService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateSectionSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setSectionValidationErrors(result.data.errors));
        yield put(updateSectionFailure(result.message));
      } else {
        yield put(updateSectionFailure(result.message));
      }
    } catch (error) {
      yield put(updateSectionFailure(error.message));
    }
  });
}

export function* onDeleteSection() {
  yield takeLatest(DELETE_SECTION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(SectionService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteSectionSuccess(result.data));
      } else {
        yield put(deleteSectionFailure(result.message));
      }
    } catch (error) {
      yield put(deleteSectionFailure(error.message));
    }
  });
}

export function* onCreateSection() {
  yield takeLatest(CREATE_SECTION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(SectionService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createSectionSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setSectionValidationErrors(result.data.errors));
        yield put(createSectionFailure(result.message));
      } else {
        yield put(createSectionFailure(result.message));
      }
    } catch (error) {
      yield put(deleteSectionFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchSections),
    fork(onUpdateSection),
    fork(onDeleteSection),
    fork(onCreateSection),
  ]);
}
