import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { connect } from "react-redux";
import {
  fetchAppointmentMetadataRequest,
  fetchBranchLookUpsRequest,
} from "redux/actions";

export const AppViews = ({
  fetchAppointmentMetadataRequest,
  fetchBranchLooUp,
  loadingAppointmentMetadata,
  loadingBranchLookUp,
}) => {
  useEffect(() => {
    fetchAppointmentMetadataRequest();
    fetchBranchLooUp();
  }, [fetchAppointmentMetadataRequest, fetchBranchLooUp]);
  return loadingAppointmentMetadata || loadingBranchLookUp ? (
    <Loading cover="content" />
  ) : (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
         <Route
          path={`${APP_PREFIX_PATH}/neworder`}
          component={lazy(() => import(`../admin-views/neworder/neworder/index.js`))}
        />
        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} /> */}
      </Switch>
    </Suspense>
  );
};
const mapDispatchToProps = (dispatch) => ({
  fetchAppointmentMetadataRequest: () =>
    dispatch(fetchAppointmentMetadataRequest()),
  fetchBranchLooUp: () => dispatch(fetchBranchLookUpsRequest()),
});

const mapStateToProps = (state) => ({
  loadingAppointmentMetadata: state.appointment.loadingAppointmentMetadata,
  loadingBranchLookUp: state.branchLookUp.loadingBranchLookUp,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AppViews));
