import {
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  SHOW_ROLE_EDIT_FORM,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  SET_ROLE_VALIDATION_ERRORS,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  SHOW_ROLE_NEW_FORM,
} from "constants/Role";

export const fetchRolesRequest = () => ({
  type: FETCH_ROLES_REQUEST,
});

export const fetchRolesSuccess = (roles) => ({
  type: FETCH_ROLES_SUCCESS,
  payload: roles,
});

export const fetchRolesFailure = (error) => ({
  type: FETCH_ROLES_FAILURE,
  payload: error,
});

export const setRoleValidationErrors = (validationErrors) => ({
  type: SET_ROLE_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleRoleEditForm = () => ({
  type: SHOW_ROLE_EDIT_FORM,
});

export const toggleRoleNewForm = () => ({
  type: SHOW_ROLE_NEW_FORM,
});

export const updateRoleRequest = (role) => ({
  type: UPDATE_ROLE_REQUEST,
  payload: role,
});

export const updateRoleSuccess = (role) => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: role,
});

export const updateRoleFailure = (error) => ({
  type: UPDATE_ROLE_FAILURE,
  payload: error,
});


export const createRoleRequest = (role) => ({
  type: CREATE_ROLE_REQUEST,
  payload: role,
});

export const createRoleSuccess = (role) => ({
  type: CREATE_ROLE_SUCCESS,
  payload: role,
});

export const createRoleFailure = (error) => ({
  type: CREATE_ROLE_FAILURE,
  payload: error,
});