export const SHOW_RELEASED_NEW_FORM = "SHOW_RELEASED_NEW_FORM";
export const SHOW_RELEASED_EDIT_FORM = "SHOW_RELEASED_EDIT_FORM";
export const SET_RELEASED_VALIDATION_ERRORS = "SET_RELEASED_VALIDATION_ERRORS";

export const FETCH_RELEASEDS_REQUEST = "FETCH_RELEASEDS_REQUEST";
export const FETCH_RELEASEDS_SUCCESS = "FETCH_RELEASEDS_SUCCESS";
export const FETCH_RELEASEDS_FAILURE = "FETCH_RELEASEDS_FAILURE";

export const UPDATE_RELEASED_REQUEST = "UPDATE_RELEASED_REQUEST";
export const UPDATE_RELEASED_SUCCESS = "UPDATE_RELEASED_SUCCESS";
export const UPDATE_RELEASED_FAILURE = "UPDATE_RELEASED_FAILURE";

export const DELETE_RELEASED_REQUEST = "DELETE_RELEASED_REQUEST";
export const DELETE_RELEASED_SUCCESS = "DELETE_RELEASED_SUCCESS";
export const DELETE_RELEASED_FAILURE = "DELETE_RELEASED_FAILURE";

export const CREATE_RELEASED_REQUEST = "CREATE_RELEASED_REQUEST";
export const CREATE_RELEASED_SUCCESS = "CREATE_RELEASED_SUCCESS";
export const CREATE_RELEASED_FAILURE = "CREATE_RELEASED_FAILURE";
