import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_TEST_REGISTRATION_REQUEST,
  DELETE_TEST_REGISTRATION_REQUEST,
  FETCH_TEST_REGISTRATIONS_REQUEST,
  UPDATE_TEST_REGISTRATION_REQUEST,
} from "constants/TestRegistration";
import {
  createTestRegistrationFailure,
  createTestRegistrationSuccess,
  deleteTestRegistrationFailure,
  deleteTestRegistrationSuccess,
  fetchTestRegistrationsFailure,
  fetchTestRegistrationsSuccess,
  setTestRegistrationValidationErrors,
  updateTestRegistrationFailure,
  updateTestRegistrationSuccess,
} from "redux/actions";
import TestRegistrationService from "services/TestRegistration";

export function* onFetchTestRegistrations() {
  yield takeLatest(FETCH_TEST_REGISTRATIONS_REQUEST, function* () {
    try {
      const result = yield call(TestRegistrationService.index);
      if (result.status) {
        yield put(fetchTestRegistrationsSuccess(result.data));
      } else {
        yield put(fetchTestRegistrationsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchTestRegistrationsFailure(error.message));
    }
  });
}

export function* onUpdateTestRegistration() {
  yield takeLatest(UPDATE_TEST_REGISTRATION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(TestRegistrationService.update, payload.id, payload);
      if (result.status) {
        message.success("Updated");
        yield put(updateTestRegistrationSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setTestRegistrationValidationErrors(result.data.errors));
        yield put(updateTestRegistrationFailure(result.message));
      } else {
        yield put(updateTestRegistrationFailure(result.message));
      }
    } catch (error) {
      yield put(updateTestRegistrationFailure(error.message));
    }
  });
}

export function* onDeleteTestRegistration() {
  yield takeLatest(DELETE_TEST_REGISTRATION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(TestRegistrationService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteTestRegistrationSuccess(result.data));
      } else {
        yield put(deleteTestRegistrationFailure(result.message));
      }
    } catch (error) {
      yield put(deleteTestRegistrationFailure(error.message));
    }
  });
}

export function* onCreateTestRegistration() {
  yield takeLatest(CREATE_TEST_REGISTRATION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(TestRegistrationService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createTestRegistrationSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setTestRegistrationValidationErrors(result.data.errors));
        yield put(createTestRegistrationFailure(result.message));
      } else {
        yield put(createTestRegistrationFailure(result.message));
      }
    } catch (error) {
      yield put(deleteTestRegistrationFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchTestRegistrations),
    fork(onUpdateTestRegistration),
    fork(onDeleteTestRegistration),
    fork(onCreateTestRegistration),
  ]);
}
