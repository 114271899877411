import {
  FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS,
  FETCH_PANICANDABNORMALTESTRESULTS_REQUEST,
  FETCH_PANICANDABNORMALTESTRESULTS_FAILURE,
} from "constants/PanicandAbnormalTestResults";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const panicandabnormaltestresults = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PANICANDABNORMALTESTRESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_PANICANDABNORMALTESTRESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default panicandabnormaltestresults;
