import fetch from "auth/FetchInterceptor";

const CompletedResultsService = {};

CompletedResultsService.get = function (payload) {
  return fetch({
    url:
      `/reports/patientresult-completed?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}&department=null`,
    method: "get",
  });
};

export default CompletedResultsService;
