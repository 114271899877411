import {
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  FETCH_SECTIONS_FAILURE,
  FETCH_SECTIONS_REQUEST,
  FETCH_SECTIONS_SUCCESS,
  CREATE_SECTION_FAILURE,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  SHOW_SECTION_EDIT_FORM,
  SHOW_SECTION_NEW_FORM,
  UPDATE_SECTION_FAILURE,
  UPDATE_SECTION_REQUEST,
  UPDATE_SECTION_SUCCESS,
  SET_SECTION_VALIDATION_ERRORS,
} from "constants/Section";

export const fetchSectionsRequest = () => ({
  type: FETCH_SECTIONS_REQUEST,
});

export const fetchSectionsSuccess = (sections) => ({
  type: FETCH_SECTIONS_SUCCESS,
  payload: sections,
});

export const fetchSectionsFailure = (error) => ({
  type: FETCH_SECTIONS_FAILURE,
  payload: error,
});

export const setSectionValidationErrors = (validationErrors) => ({
  type: SET_SECTION_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleSectionEditForm = () => ({
  type: SHOW_SECTION_EDIT_FORM,
});

export const toggleSectionNewForm = () => ({
  type: SHOW_SECTION_NEW_FORM,
});

export const updateSectionRequest = (section) => ({
  type: UPDATE_SECTION_REQUEST,
  payload: section,
});

export const updateSectionSuccess = (section) => ({
  type: UPDATE_SECTION_SUCCESS,
  payload: section,
});

export const updateSectionFailure = (error) => ({
  type: UPDATE_SECTION_FAILURE,
  payload: error,
});

export const deleteSectionRequest = (id) => ({
  type: DELETE_SECTION_REQUEST,
  payload: id,
});

export const deleteSectionSuccess = (section) => ({
  type: DELETE_SECTION_SUCCESS,
  payload: section,
});

export const deleteSectionFailure = (error) => ({
  type: DELETE_SECTION_FAILURE,
  payload: error,
});

export const createSectionRequest = (section) => ({
  type: CREATE_SECTION_REQUEST,
  payload: section,
});

export const createSectionSuccess = (section) => ({
  type: CREATE_SECTION_SUCCESS,
  payload: section,
});

export const createSectionFailure = (error) => ({
  type: CREATE_SECTION_FAILURE,
  payload: error,
});
