export const SET_CLIENT_COMPANY_VALIDATION_ERRORS = "SET_CLIENT_COMPANY_VALIDATION_ERRORS";
export const SHOW_CLIENT_COMPANY_EDIT_FORM = "SHOW_CLIENT_COMPANY_EDIT_FORM";
export const SHOW_CLIENT_COMPANY_NEW_FORM = "SHOW_CLIENT_COMPANY_NEW_FORM";


export const FETCH_CLIENT_COMPANIES_REQUEST = "FETCH_CLIENT_COMPANIES_REQUEST";
export const FETCH_CLIENT_COMPANIES_SUCCESS = "FETCH_CLIENT_COMPANIES_SUCCESS";
export const FETCH_CLIENT_COMPANIES_FAILURE = "FETCH_CLIENT_COMPANIES_FAILURE";

export const UPDATE_CLIENT_COMPANY_REQUEST = "UPDATE_CLIENT_COMPANY_REQUEST";
export const UPDATE_CLIENT_COMPANY_SUCCESS = "UPDATE_CLIENT_COMPANY_SUCCESS";
export const UPDATE_CLIENT_COMPANY_FAILURE = "UPDATE_CLIENT_COMPANY_FAILURE";

export const DELETE_CLIENT_COMPANY_REQUEST = "DELETE_CLIENT_COMPANY_REQUEST";
export const DELETE_CLIENT_COMPANY_SUCCESS = "DELETE_CLIENT_COMPANY_SUCCESS";
export const DELETE_CLIENT_COMPANY_FAILURE = "DELETE_CLIENT_COMPANY_FAILURE";

export const CREATE_CLIENT_COMPANY_REQUEST = "CREATE_CLIENT_COMPANY_REQUEST";
export const CREATE_CLIENT_COMPANY_SUCCESS = "CREATE_CLIENT_COMPANY_SUCCESS";
export const CREATE_CLIENT_COMPANY_FAILURE = "CREATE_CLIENT_COMPANY_FAILURE";

export const FETCH_DASHBOARD_REQUEST = "FETCH_DASHBOARD_REQUEST";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

