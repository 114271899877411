export const SHOW_PATIENT_NEW_FORM = "SHOW_PATIENT_NEW_FORM";
export const SHOW_PATIENT_EDIT_FORM = "SHOW_PATIENT_EDIT_FORM";
export const SET_PATIENT_VALIDATION_ERRORS = "SET_PATIENT_VALIDATION_ERRORS";

export const FETCH_PATIENTS_REQUEST = "FETCH_PATIENTS_REQUEST";
export const FETCH_PATIENTS_SUCCESS = "FETCH_PATIENTS_SUCCESS";
export const FETCH_PATIENTS_FAILURE = "FETCH_PATIENTS_FAILURE";

export const UPDATE_PATIENT_REQUEST = "UPDATE_PATIENT_REQUEST";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAILURE = "UPDATE_PATIENT_FAILURE";

export const DELETE_PATIENT_REQUEST = "DELETE_PATIENT_REQUEST";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
export const DELETE_PATIENT_FAILURE = "DELETE_PATIENT_FAILURE";

export const CREATE_PATIENT_REQUEST = "CREATE_PATIENT_REQUEST";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAILURE = "CREATE_PATIENT_FAILURE";
