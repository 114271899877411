import fetch from "auth/FetchInterceptor";

const ClientCompanyService = {};

ClientCompanyService.index = function () {
  return fetch({
    url: "/client-companies",
    method: "get",
  });
};

ClientCompanyService.getDashboard = function (payload) {
  return fetch({
    url: `/getdashboard?startdate=${payload.startdate}&enddate=${payload.enddate}&agency=${payload.agency}`,
    method: "get",
  });
};

ClientCompanyService.create = function (data) {
  return fetch({
    url: "/client-companies",
    method: "post",
    data,
  });
};

ClientCompanyService.update = function (id, data) {
  return fetch({
    url: `/client-companies/${id}`,
    method: "put",
    data,
  });
};

ClientCompanyService.delete = function (id) {
  return fetch({
    url: `/client-companies/${id}`,
    method: "delete",
  });
};

export default ClientCompanyService;
