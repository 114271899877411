import _ from "lodash";

export const addCheckList = (state, action) => {
  let list = state.list;
  const savedCheckList = action.payload;

  return [savedCheckList, ...list];
};

export const updateCheckList = (state, action) => {
  let list = state.list;
  const updatedCheckList = action.payload;

  const index = _.findIndex(list, ["id", updatedCheckList.id]);
  list[index] = updatedCheckList;

  return [...list];
};

export const removeCheckList = (state, action) => {
  let list = state.list;
  const deletedCheckListId = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedCheckListId;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
