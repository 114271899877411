import fetch from "auth/FetchInterceptor";

const ReferableOrdersService = {};

ReferableOrdersService.get = function (payload) {
  return fetch({
    url:
      "/reports/referable-orders?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=" +
      payload.agency +
      "&department=",
    method: "get",
  });
};

export default ReferableOrdersService;
