import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  FETCH_USERS_REQUEST,
  UPDATE_USER_REQUEST,
} from "constants/User";
import {
  createUserFailure,
  createUserSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  fetchUsersFailure,
  fetchUsersSuccess,
  setUserValidationErrors,
  updateUserFailure,
  updateUserSuccess,
} from "redux/actions";
import UserService from "services/User";

export function* onFetchUsers() {
  yield takeLatest(FETCH_USERS_REQUEST, function* () {
    try {
      const result = yield call(UserService.index);
      if (result.status) {
        yield put(fetchUsersSuccess(result.data));
      } else {
        yield put(fetchUsersFailure(result.message));
      }
    } catch (error) {
      yield put(fetchUsersFailure(error.message));
    }
  });
}

export function* onUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        UserService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateUserSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setUserValidationErrors(result.data.validation_errors));
        yield put(updateUserFailure(result.message));
      } else {
        yield put(updateUserFailure(result.message));
      }
    } catch (error) {
      yield put(updateUserFailure(error.message));
    }
  });
}

export function* onDeleteUser() {
  yield takeLatest(DELETE_USER_REQUEST, function* ( payload ) {
    try {
        
      const result = yield call(UserService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteUserSuccess(result.data));
      } else {
        yield put(deleteUserFailure(result.message));
      }
    } catch (error) {
      yield put(deleteUserFailure(error.message));
    }
  });
}

export function* onCreateUser() {
  yield takeLatest(CREATE_USER_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(UserService.create, payload);
      if (result.status) {
        message.success("User Created");
        yield put(createUserSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Please check your inputs");
        yield put(setUserValidationErrors(result.data.errors));
        yield put(createUserFailure(result.message));
      } else {
        message.error("Unable to create a user");
        yield put(createUserFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteUserFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchUsers),
    fork(onUpdateUser),
    fork(onDeleteUser),
    fork(onCreateUser),
  ]);
}
