import fetch from "auth/FetchInterceptor";

const WebLedgerInfoService = {};


WebLedgerInfoService.index = function (payload) {
  
  return fetch({
    
    url: "/reports/get-web-ledger/"+ payload.sampleId ,
    method: "get",
  });
};

WebLedgerInfoService.create = function (payload) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json'); 
 debugger
  var url="";
  if(payload.type==="panic"){
  url="/updatePanicLedger";
  }
  else if(payload.type==="discrepancy"){
    url="/updateDiscrepancyLedger";
  }
  else if(payload.type==="acknowledgePanic"){
    url="/acknowledgePanic";
  }
  else if(payload.type==="acknowledgediscrepancy"){
    url="/acknowledgeDiscrepancy";
  }
  else if(payload.type==="spoiled"){
    url="/conveyspoiledsamples";
  }
  else if(payload.type==="spoiledacknowledge"){
    url="/AcknowledgeSpoiledSamples";
  }
  
  
  delete payload.type;
  return fetch(url, {
    method: "post",
    data: payload
  });
};


export default WebLedgerInfoService;
