import {
  FETCH_PANICANDABNORMALTESTRESULTS_FAILURE,
  FETCH_PANICANDABNORMALTESTRESULTS_REQUEST,
  FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS,
} from "constants/PanicandAbnormalTestResults";

export const fetchPanicandAbnormalTestResultsRequest = (
  startDate,
  endDate,
  agency,
  department
) => ({
  type: FETCH_PANICANDABNORMALTESTRESULTS_REQUEST,
  payload: {
    startDate,
    endDate,
    agency,
    department,
  },
});

export const fetchPanicandAbnormalTestResultsSuccess = (
  panicandabnormaltestresults
) => ({
  type: FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS,
  payload: panicandabnormaltestresults,
});

export const fetchPanicandAbnormalTestResultsFailure = (error) => ({
  type: FETCH_PANICANDABNORMALTESTRESULTS_FAILURE,
  payload: error,
});
