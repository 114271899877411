export const SHOW_PANICANDABNORMALTESTRESULTS_NEW_FORM = "SHOW_PANICANDABNORMALTESTRESULTS_NEW_FORM";
export const SHOW_PANICANDABNORMALTESTRESULTS_EDIT_FORM = "SHOW_PANICANDABNORMALTESTRESULTS_EDIT_FORM";
export const SET_PANICANDABNORMALTESTRESULTS_VALIDATION_ERRORS = "SET_PANICANDABNORMALTESTRESULTS_VALIDATION_ERRORS";

export const FETCH_PANICANDABNORMALTESTRESULTS_REQUEST = "FETCH_PANICANDABNORMALTESTRESULTS_REQUEST";
export const FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS = "FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS";
export const FETCH_PANICANDABNORMALTESTRESULTS_FAILURE = "FETCH_PANICANDABNORMALTESTRESULTS_FAILURE";

export const UPDATE_PANICANDABNORMALTESTRESULTS_REQUEST = "UPDATE_PANICANDABNORMALTESTRESULTS_REQUEST";
export const UPDATE_PANICANDABNORMALTESTRESULTS_SUCCESS = "UPDATE_PANICANDABNORMALTESTRESULTS_SUCCESS";
export const UPDATE_PANICANDABNORMALTESTRESULTS_FAILURE = "UPDATE_PANICANDABNORMALTESTRESULTS_FAILURE";

export const DELETE_PANICANDABNORMALTESTRESULTS_REQUEST = "DELETE_PANICANDABNORMALTESTRESULTS_REQUEST";
export const DELETE_PANICANDABNORMALTESTRESULTS_SUCCESS = "DELETE_PANICANDABNORMALTESTRESULTS_SUCCESS";
export const DELETE_PANICANDABNORMALTESTRESULTS_FAILURE = "DELETE_PANICANDABNORMALTESTRESULTS_FAILURE";

export const CREATE_PANICANDABNORMALTESTRESULTS_REQUEST = "CREATE_PANICANDABNORMALTESTRESULTS_REQUEST";
export const CREATE_PANICANDABNORMALTESTRESULTS_SUCCESS = "CREATE_PANICANDABNORMALTESTRESULTS_SUCCESS";
export const CREATE_PANICANDABNORMALTESTRESULTS_FAILURE = "CREATE_PANICANDABNORMALTESTRESULTS_FAILURE";
