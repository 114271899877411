import { 
    FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
    FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS,
    FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE,
} from "constants/AdminDashboardReports/DashboardReport"

const initState = {
     adminReportStatisticsData : [],
     loading : true,
     errorMessage : ""
}

const DashboardReports = (state = initState, action) =>{
    switch(action.type)
    {
        case FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST :
        return { 
            ...state,
            loading : true
        }
        case FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS : 
        return {
            ...state,
            loading : false,
            adminReportStatisticsData : action.payload
        }
        case FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE : 
        return { 
             ...state, 
             loading : false,
             adminReportStatisticsData : [],
             errorMessage : action.payload
        }
        default : 
        return state
    }
}

export default DashboardReports;