import {
  FETCH_CLIENT_COMPANIES_SUCCESS,
  FETCH_CLIENT_COMPANIES_REQUEST,
  FETCH_CLIENT_COMPANIES_FAILURE,
  UPDATE_CLIENT_COMPANY_REQUEST,
  UPDATE_CLIENT_COMPANY_SUCCESS,
  UPDATE_CLIENT_COMPANY_FAILURE,
  SHOW_CLIENT_COMPANY_EDIT_FORM,
  DELETE_CLIENT_COMPANY_REQUEST,
  DELETE_CLIENT_COMPANY_SUCCESS,
  DELETE_CLIENT_COMPANY_FAILURE,
  SET_CLIENT_COMPANY_VALIDATION_ERRORS,
  SHOW_CLIENT_COMPANY_NEW_FORM,
  CREATE_CLIENT_COMPANY_REQUEST,
  CREATE_CLIENT_COMPANY_SUCCESS,
  CREATE_CLIENT_COMPANY_FAILURE,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS
} from "constants/ClientCompany";

import {
  addClientCompany,
  formtValidationErrors,
  removeClientCompany,
  updateClientCompany,
} from "utils/ClientCompany";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,

  loadingdashboard:false,
  dashboarddata:[],
  dashboardErrorMessage: "",
};

const clientCompany = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_CLIENT_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_CLIENT_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_CLIENT_COMPANY_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_CLIENT_COMPANY_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_CLIENT_COMPANY_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_CLIENT_COMPANY_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_CLIENT_COMPANY_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateClientCompany(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_CLIENT_COMPANY_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_CLIENT_COMPANY_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_CLIENT_COMPANY_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeClientCompany(state, action),
      };
    case DELETE_CLIENT_COMPANY_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_CLIENT_COMPANY_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_CLIENT_COMPANY_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addClientCompany(state, action),
      };
    case CREATE_CLIENT_COMPANY_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

      case FETCH_DASHBOARD_REQUEST:
        return {
          ...state,
          loadingdashboard: true,
        };
      case FETCH_DASHBOARD_SUCCESS:
        return {
          ...state,
          loadingdashboard: false,
          dashboarddata: action.payload,
        };
      case FETCH_DASHBOARD_FAILURE:
        return {
          ...state,
          loadingdashboard: false,
          dashboardErrorMessage: action.payload,
        };
  
    default:
      return state;
  }
};

export default clientCompany;
