import{
    FETCH_SAMPLE_IN_PROCESS_FAILURE,
    FETCH_SAMPLE_IN_PROCESS_REQUEST,
    FETCH_SAMPLE_IN_PROCESS_SUCCESS
} from "constants/SampleInProcess"

const initialValue = {
     list : [],
     loading : false
}

export const SampleInProcess = (state = initialValue, action)=>{
    switch(action.type){
         case FETCH_SAMPLE_IN_PROCESS_REQUEST :
            return {
                loading : true
            }
         case FETCH_SAMPLE_IN_PROCESS_SUCCESS : 
         return {
            loading : false,
            list  : action.payload
         }
         case FETCH_SAMPLE_IN_PROCESS_FAILURE : 
         return {
            loading : false,
            list : action.payload
         }
         default : return state;
    }
}