import{
    FETCH_FINANCIAL_REPORTS_REQUEST,
    FETCH_FINANCIAL_REPORTS_FAILURE,
    FETCH_FINANCIAL_REPORTS_SUCCESS
} from "constants/FinancialReport"


const initialState = {
     message : "",
     loading : false,
     errorMessage : ""
}

 const FinancialReportUpload = (state =initialState, action) =>{
     switch(action.type){
         case FETCH_FINANCIAL_REPORTS_REQUEST :
            return{
                 loading: true
            }
         case FETCH_FINANCIAL_REPORTS_SUCCESS : 
            return{
                 loading : false,
                 message : action.paylaod
            }
         case FETCH_FINANCIAL_REPORTS_FAILURE : 
            return{
                 loading : false,
                 errorMessage : action.paylaod
            }
        default : 
         return state;
     }
}
export default FinancialReportUpload;