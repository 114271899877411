
import {
  DashboardOutlined,
  HomeOutlined,
  OrderedListOutlined,
  BranchesOutlined,
  DollarCircleOutlined,
  DeleteOutlined,
  ClusterOutlined,
  ShoppingCartOutlined,
  ExperimentOutlined,
  CheckCircleOutlined,
  IssuesCloseOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  ProfileOutlined,
  MedicineBoxOutlined,
  BarcodeOutlined,
  WarningOutlined,
  BarChartOutlined,
  SafetyCertificateOutlined,
  ReconciliationOutlined,
  QuestionCircleOutlined,
  FlagOutlined,
  UserOutlined,
  TeamOutlined,
  UserAddOutlined,
  SettingOutlined,
  UploadOutlined,
  ExclamationOutlined,
  CarOutlined,
  MoneyCollectOutlined,
  YuqueOutlined,
  WechatOutlined,
  SoundOutlined,
  ContactsOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { Link } from 'react-router-dom';


import {
  ADMIN_PREFIX_PATH,
  APP_PREFIX_PATH,
  DOCTOR_PREFIX_PATH,
  QC_PREFIX_PATH,
  ROLE_GUEST,
  ROLE_QUALITY_CONTROL,
  ROLE_SUPER_ADMIN,
  ICL_TELEGRAM_Bot,
} from "configs/AppConfig";
import { CURRENT_USER } from "redux/constants/Auth";
import {
  VIEW_ALL_DISCREPANCIES,
  VIEW_ALL_ORDERS,
  VIEW_ALL_RELEASED_RESULTS,
  VIEW_APPROVED_DESCREPANCIES,
  VIEW_BRANCHLOOKUPS,
  VIEW_CLIENTCOMPANIES,
  VIEW_DASHBOARD,
  VIEW_DECLINED_DESCREPANCIES,
  VIEW_DEPARTMENTS,
  VIEW_PANEL_OBR,
  VIEW_FINANCIAL,
  VIEW_INPERSON_RESULTS,
  VIEW_NEWORDERS,
  VIEW_NOT_RELEASED_ORDERS,
  VIEW_PANIC_AND_ABNORMAL_TESTS,
  VIEW_PATIENT_RESULT_HISTORY,
  VIEW_PENDING_DISCREPANCIES,
  VIEW_RELEASED_PANEL_FOR_ICL,
  VIEW_REPORTS,
  VIEW_RESULTS_FOR_GUEST_USERS,
  VIEW_ROLES,
  VIEW_SAMPLE_NOT_RECIEVED_ORDERS,
  VIEW_TESTDIRECTORIES,
  VIEW_TOP_AGENCIES,
  VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS,
  VIEW_TOP_TESTS,
  VIEW_USERS,
  VIEW_APPROVED_ORDERS,
  VIEW_PENDING_ORDERS,
  VIEW_DELCINED_ORDERS,
  VIEW_AGENCY_TEST_ASSOCIATION_FORM,
  CREATE_NEWORDERS,
  VIEW_ALL_INCOMPLETE_RESULTS,
  VIEW_PANIC_STAT_RESULTS,
  VIEW_ORDER_STAT_RESULTS,
  VIEW_RELEASED_AFTER_TAT,
  VIEW_PENDING_RESULTS_OBX,
  VIEW_MICRO_BIOLOGY_RESULTS,
  UPLOAD_MICRO_BIOLOGIES_RESULTS,
  VIEW_TOP_PANELS,
  DAILY_RELEASED_TESTS,
  DAILY_DEPARTMENT_RELEASED_TESTS,
  VIEW_COMPLETED_RESULTS,
  VIEW_DISCONTINUED_TEST_DIRECTORIES,
  VIEW_FAULT_RESULTS,
  DAILY_SINGLE_RELEASED_TESTS,
  VIEW_REFERABLE_ORDERS,
  VIEW_TEST_OBX_PER_TECHNICIAN,
  VIEW_STAT_ORDERS,
  VIEW_TAT_APPROACHING,
  UPLOAD_CSV_PANELS,
  VIEW_TV_SCREEN_RESULTS,
  VIEW_FINANCIAL_REPORTS,
  UPLOAD_FINANCIAL_REPORT,
  VIEW_DAILY_FINANCIAL_SUMMARY,
  VIEW_LOGISITICS,
  Sample_Managment,
  HANDLE_PICKUP_REQUEST,
  VIEW_TELEGRAM_BOT_MENU,
} from "./UserPermissions";
import { DoesThisUserHasAPermissionToThisComponent, doesThisUserIsAdmin, doesThisUserIsAgencyClientUser, doesThisUserIsDoctor, doesThisUserIsIclUser } from "utils/CurrentUserUtil";
import { CONFIG_LABEL, TV_SCREEN_LABEL } from "./EnvironmentConfig";
import {store} from '../redux/store/index';

 function getItems(){

  const dashBoardNavTree = [
    {
      key: "home",
      path: `${APP_PREFIX_PATH}/home`,
      title: "Home",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "neworder",
      path: `${APP_PREFIX_PATH}/neworder`,
      title: "NewOrder",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [],
    },
  ];
  
  const qualityControlNavTree = [
    {
      key: "sections",
      path: `${QC_PREFIX_PATH}/sections`,
      title: "Section",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "machines",
      path: `${QC_PREFIX_PATH}/machines`,
      title: "Machine",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "equipment",
      path: `${QC_PREFIX_PATH}/equipment`,
      title: "Equipment",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "check-lists",
      path: `${QC_PREFIX_PATH}/check-lists`,
      title: "CheckList",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "maintenances",
      path: `${QC_PREFIX_PATH}/maintenances`,
      title: "Maintenance",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [],
    },
  ];


  const currentState = store.getState();
  const language = currentState.theme.language;
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.role;
  let navigationConfig = [];


  const doctorsAppNavTree = [
    {
      key: "activities",
      path: `${DOCTOR_PREFIX_PATH}/activities`,
      title: 'Activities',
      icon: SoundOutlined,
      breadcrumb: false,
      hidden: true,
      submenu: [
        {
          key: "dashboard",
          path: `${DOCTOR_PREFIX_PATH}/dashboard`,
          title: "Dashboard",
          icon: HomeOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "test-directories",
          path: `${DOCTOR_PREFIX_PATH}/test-directories`,
          title: "Our Tests",
          icon: ExperimentOutlined,
          breadcrumb: true,
          submenu: [],
        },
        {
          key: "neworder",
          path: `${DOCTOR_PREFIX_PATH}/order/new`,
          title: "Place Order",
          icon: ShoppingCartOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "released",
          path: `${DOCTOR_PREFIX_PATH}/all-orders`,
          title: language.order_status,
          icon: MedicineBoxOutlined,
          breadcrumb: false,
          submenu: [],
        },
      /*  {
          key: "orders",
          path: `${ADMIN_PREFIX_PATH}/client`,
          title: language.order_status,
          icon: MedicineBoxOutlined,
          breadcrumb: true,
          submenu: [...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) ? [
                {
                  key: "released",
                  path: `${ADMIN_PREFIX_PATH}/all-orders`,
                  title: language.all,
                  icon: ProfileOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              : []),

            ...(DoesThisUserHasAPermissionToThisComponent(
              VIEW_PENDING_ORDERS
            )
              ? [
                {
                  key: "pending-results",
                  path: `${ADMIN_PREFIX_PATH}/pending-orders`,
                  title: language.order_to_be_sent,
                  icon: ClockCircleOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              : []),

            ...(DoesThisUserHasAPermissionToThisComponent(
              VIEW_STAT_ORDERS
            )
              ? [
                {
                  key: "stat-orders",
                  path: `${ADMIN_PREFIX_PATH}/stat-orders`,
                  title: language.stat_orders,
                  icon: ClockCircleOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS)
              ? ([{
                key: "approved-orders",
                path: `${ADMIN_PREFIX_PATH}/approved-orders`,
                title: language.approved,
                icon: CheckCircleOutlined,
                breadcrumb: true,
                submenu: [],
              }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(
              VIEW_ORDER_STAT_RESULTS
            )
              ? [
                {
                  key: "statorder",
                  path: `${ADMIN_PREFIX_PATH}/order-stat`,
                  title: "Stat (Coming Soon...)",
                  icon: WarningOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              : []),

            // ...(DoesThisUserHasAPermissionToThisComponent(
            //   VIEW_SAMPLE_NOT_RECIEVED_ORDERS
            // )
            //   ? [
            //       {
            //         key: "sampleaccepted",
            //         path: `${ADMIN_PREFIX_PATH}/sample-accepted`,
            //         title: "Sample Accepted",
            //         icon: FieldTimeOutlined,
            //         breadcrumb: true,
            //         submenu: [],
            //       },
            //     ]
            //   : []),

            ...(DoesThisUserHasAPermissionToThisComponent(
              VIEW_REFERABLE_ORDERS
            )
              ? [
                {
                  key: "referrableorders",
                  path: `${ADMIN_PREFIX_PATH}/referable-orders`,
                  title: language.referal_orders,
                  icon: FieldTimeOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              : []),

            // ...(DoesThisUserHasAPermissionToThisComponent(
            //   VIEW_NOT_RELEASED_ORDERS
            // )
            //   ? [
            //       {
            //         key: "notyetreleased",
            //         path: `${ADMIN_PREFIX_PATH}/not-yet-released`,
            //         title: "Accepted But Unreleased Orders",
            //         icon: ClockCircleOutlined,
            //         breadcrumb: true,
            //         submenu: [],
            //       },
            //     ]
            //   : []),

            ...(DoesThisUserHasAPermissionToThisComponent(
              VIEW_SAMPLE_NOT_RECIEVED_ORDERS
            )
              ? [
                {
                  key: "sampleinprogress",
                  path: `${ADMIN_PREFIX_PATH}/sample-in-process`,
                  title: language.sample_processing_in_lab,
                  icon: FieldTimeOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              : []),

            ...(DoesThisUserHasAPermissionToThisComponent(
              VIEW_DELCINED_ORDERS
            )
              ? [
                {
                  key: "declined-results",
                  path: `${ADMIN_PREFIX_PATH}/declined-orders`,
                  title: language.rejected,
                  icon: DeleteOutlined,
                  iconColor: "#550000",
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              : [])
          ],
        }, */
          ],
        },
    {
      key: "allpatientresult",
      path: `${DOCTOR_PREFIX_PATH}/patient-result`,
      title: 'All Results',
      icon: ProfileOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: "discrepancy",
      path: `${DOCTOR_PREFIX_PATH}/results/corrected/all`,
      title: "Corrected Results",
      icon: IssuesCloseOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "discontinuedtest",
      path: `${ADMIN_PREFIX_PATH}/discontinued-test-directories`,
      title: 'Discontinued Tests',
      icon: OrderedListOutlined,
      breadcrumb: true,
      submenu: [],
    },
    /* {
      key: "panic-results",
      path: `${DOCTOR_PREFIX_PATH}/results/panic-and-abnormal/all`,
      title: "Panic and Abnormal Results",
      icon: FlagOutlined,
      breadcrumb: false,
      submenu: [],
    }, */
    /* {
      key: "results",
      path: `${DOCTOR_PREFIX_PATH}/results/completed/all`,
      title: "Completed Results",
      icon: CheckCircleOutlined,
      breadcrumb: false,
      submenu: [],
    }, */
    /* {
      key: "incomplete-results",
      path: `${DOCTOR_PREFIX_PATH}/results/incomplete/all`,
      title: "Incomplete Results",
      icon: FieldTimeOutlined,
      breadcrumb: false,
      submenu: [],
    }, */
    /* {
      key: "discrepancy",
      path: `${DOCTOR_PREFIX_PATH}/results/corrected/all`,
      title: "Corrected Results",
      icon: IssuesCloseOutlined,
      breadcrumb: false,
      submenu: [],
    }, */
    // {
    //   key: "graphical-report",
    //   path: `${DOCTOR_PREFIX_PATH}/graphical-report`,
    //   title: "Graphical report",
    //   icon: AreaChartOutlined,
    //   breadcrumb: false,
    //   submenu: [],
    // },
    {
      key: "antibiogram-report",
      path: `${DOCTOR_PREFIX_PATH}/antibiogram-report`,
      title: "Antibiogram",
      icon: BarChartOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "members-discussion-forum",
      path: `${DOCTOR_PREFIX_PATH}/chat-room`,
      title: "Members Discussion Forum",
      icon: WechatOutlined,
      breadcrumb: false,
      submenu: [],
    },
    // {
    //   key: "customer-support",
    //   path: `${DOCTOR_PREFIX_PATH}/customer-support`,
    //   title: "Contact Us",
    //   icon: ContactsOutlined,
    //   breadcrumb: false,
    //   submenu: [],
    // },
    // {
    //   key: "customer-support",
    //   path: "https://t.me/Icladdisbot",
    //   title: "customer-support",
    //   icon: UserAddOutlined,
    //   submenu: [],
    //   // menuItem: (
    //   //   <a href="https://t.me/Icladdisbot" target="_blank" rel="noopener noreferrer">
    //   //     customer-support
    //   //   </a>
    //   // )
    // }

    // {
    //   key: "Customer Support",
    //   path: `${ADMIN_PREFIX_PATH}/t.me/Icladdisbot`,
    //   title: "Contact Us",
    //   icon: TeamOutlined,
    //   breadcrumb: true,
    //   submenu: [
    //     ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES)
    //       ? [
    //           {
    //             key: "tgbot",
    //             path: "https://t.me/Icladdisbot",
    //             title: "Telegram Bot",  
    //             icon: UserAddOutlined,
    //             breadcrumb: false,
    //             submenu: [],
    //             external: true,
    //             target: "_blank"
    //           }                
    //         ]
    //       : [])
    //   ]
    // }
  ]
  
  if (
    (role !== ROLE_QUALITY_CONTROL && role !== ROLE_GUEST && !doesThisUserIsDoctor()) || role === ROLE_SUPER_ADMIN) {
      navigationConfig = [
      ...(DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD)
        ? [
          {
            key: "home",
            path: `${ADMIN_PREFIX_PATH}`,
            title: language.home,
            icon: HomeOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ]
        : []),
  
      ...(
        (DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_USERS) ||
        // || DoesThisUserHasAPermissionToThisComponent(VIEW_COUPONS)
        DoesThisUserHasAPermissionToThisComponent(VIEW_DISCONTINUED_TEST_DIRECTORIES) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_DEPARTMENTS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANIES) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_AGENCY_TEST_ASSOCIATION_FORM) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS))
        && 
        (doesThisUserIsAdmin() || console.log(doesThisUserIsAdmin()) || doesThisUserIsAgencyClientUser())
        ? [
          {
            key: "configs",
            path: `${APP_PREFIX_PATH}/configs`,
            title: CONFIG_LABEL,
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) ||
                DoesThisUserHasAPermissionToThisComponent(VIEW_USERS)
                ? [
                  {
                    key: "usermanagement",
                    path: `${ADMIN_PREFIX_PATH}/client`,
                    title: language.user_management,
                    icon: TeamOutlined,
                    breadcrumb: true,
                    submenu: [
                      ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) ? [
                          {
                            key: "role",
                            path: `${ADMIN_PREFIX_PATH}/roles`,
                            title: language.role,
                            icon: UserAddOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_USERS
                      )
                        ? [
                          {
                            key: "user",
                            path: `${ADMIN_PREFIX_PATH}/users`,
                            title: language.user,
                            icon: UserOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
                    ],
                  },
                ]
                : []),
  
              // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_COUPONS)
              // ? ([{
              //   key: "coupon",
              //   path: `${ADMIN_PREFIX_PATH}/coupons`,
              //   title: "Coupons",
              //   icon: UserOutlined,
              //   submenu: [],
              // }]) : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_CLIENTCOMPANIES
              ) ||
                // || DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANYTESTDIRECTORIES)
                // || DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCH_TEST_CAPACITIES)
                DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_AGENCY_TEST_ASSOCIATION_FORM
                ) ||
                DoesThisUserHasAPermissionToThisComponent(VIEW_DEPARTMENTS) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_DISCONTINUED_TEST_DIRECTORIES
                )
                ? [
                  {
                    key: "client",
                    path: `${ADMIN_PREFIX_PATH}/client`,
                    title: CONFIG_LABEL === "Config" ? language.config : language.test_menu,
                    icon: SettingOutlined,
                    breadcrumb: true,
                    submenu: [
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_DEPARTMENTS
                      )
                        ? [
                          {
                            key: "department",
                            path: `${ADMIN_PREFIX_PATH}/departments`,
                            title: language.departments,
                            icon: ClusterOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_CLIENTCOMPANIES
                      )
                        ? [
                          {
                            key: "client-list",
                            path: `${ADMIN_PREFIX_PATH}/client-companies`,
                            title: "Client Definition",
                            icon: UserOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_DISCONTINUED_TEST_DIRECTORIES
                      )
                        ? [
                          {
                            key: "discontined-test-directories",
                            path: `${ADMIN_PREFIX_PATH}/discontinued-test-directories`,
                            title:
                             language.discontinued_test_results,
                            icon: OrderedListOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANYTESTDIRECTORIES)
                      //   ? ([{
                      //     key: "client-test-directories",
                      //     path: `${ADMIN_PREFIX_PATH}/client-company-test-directories`,
                      //     title: "Client-Test Directories",
                      //     icon: OrderedListOutlined,
                      //     breadcrumb: true,
                      //     submenu: [],
                      //   }]) : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_AGENCY_TEST_ASSOCIATION_FORM
                      )
                        ? [
                          {
                            key: "agencytests",
                            path: `${ADMIN_PREFIX_PATH}/agencies-tests`,
                            title: language.assign_test_to_agency,
                            icon: UserOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_BRANCHLOOKUPS
                      )
                        ? [
                          {
                            key: "branch-look-ups",
                            path: `${ADMIN_PREFIX_PATH}/branch-look-ups`,
                            title: language.branch_look_up,
                            icon: BranchesOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCH_TEST_CAPACITIES)
                      //   ? ([{
                      //     key: "branch-test-capacities",
                      //     path: `${ADMIN_PREFIX_PATH}/branch-test-capacities`,
                      //     title: "Branch Test Capacity",
                      //     icon: BranchesOutlined,
                      //     breadcrumb: true,
                      //     submenu: [],
                      //   }]) : []),
                    ],
                  },
                ]
                : []),
            ],
          },
        ]
        : []),
  
  
      ...(DoesThisUserHasAPermissionToThisComponent(
        VIEW_TESTDIRECTORIES
      )
        ? [
          {
            key: "test-directories",
            path: `${ADMIN_PREFIX_PATH}/test-directories`,
            title: doesThisUserIsIclUser() ? language.test_definition : "Test Directory",
            icon: OrderedListOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ]
        : []),
  
      ...(DoesThisUserHasAPermissionToThisComponent(
        VIEW_TV_SCREEN_RESULTS
      )
        ? [
          {
            key: "tv_screen_result",
            path: `${ADMIN_PREFIX_PATH}/tv_screen_result`,
            title: TV_SCREEN_LABEL,
            icon: SettingOutlined,
            breadcrumb: true,
            submenu: [
              ...[
                {
                  key: "core_lab",
                  path: `${ADMIN_PREFIX_PATH}/core_lab`,
                  title: language.core_lab,
                  icon: ClusterOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              ,
              ...[
                {
                  key: "tb_micro",
                  path: `${ADMIN_PREFIX_PATH}/tb_micro`,
                  title: language.tb_micro_pending,
                  icon: UserOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
              ,
              //  ...[
              //       {
              //         key: "pathology",
              //         path: `${ADMIN_PREFIX_PATH}/pathology`,
              //         title:
              //           "Pathology",
              //         icon: OrderedListOutlined,
              //         breadcrumb: true,
              //         submenu: [],
              //       },
              //     ]
  
            ],
          },
        ]
        : []),
  
  
  
      ...(DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_ORDERS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_DELCINED_ORDERS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_NOT_RELEASED_ORDERS) ||
        DoesThisUserHasAPermissionToThisComponent(
          VIEW_SAMPLE_NOT_RECIEVED_ORDERS
        ) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_PANEL_FOR_ICL) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_INPERSON_RESULTS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_PATIENT_RESULT_HISTORY) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_DISCREPANCIES) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_DESCREPANCIES) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_DISCREPANCIES) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_DECLINED_DESCREPANCIES) ||
        DoesThisUserHasAPermissionToThisComponent(UPLOAD_FINANCIAL_REPORT)
        ? [
          {
            key: "activities",
            path: `${APP_PREFIX_PATH}/activities`,
            title: "Activities",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [
              ...(
                DoesThisUserHasAPermissionToThisComponent(CREATE_NEWORDERS)
                ? [
                  {
                    key: "placeorder",
                    path: `${ADMIN_PREFIX_PATH}/neworder`,
                    title: language.place_order,
                    icon: ShoppingCartOutlined,
                    submenu: [],
                  }
                ]
                : []),

                ...(DoesThisUserHasAPermissionToThisComponent(HANDLE_PICKUP_REQUEST)
                ? [{
                  key: 'orderpickuprequest',
                  path: `${ADMIN_PREFIX_PATH}/order-pickup-request`,
                  title: language.pick_up_request,
                  icon: CarOutlined,
                  submenu: [],
                  breadcrumb: true
                }] : []),


              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) ||
                DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS) ||
                DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_ORDERS) ||
                DoesThisUserHasAPermissionToThisComponent(VIEW_DELCINED_ORDERS) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_NOT_RELEASED_ORDERS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_SAMPLE_NOT_RECIEVED_ORDERS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(VIEW_ORDER_STAT_RESULTS)
                ? [
                  {
                    key: "orders",
                    path: `${ADMIN_PREFIX_PATH}/client`,
                    title: language.order_status,
                    icon: MedicineBoxOutlined,
                    breadcrumb: true,
                    submenu: [
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_ALL_ORDERS
                      )
                        ? [
                          {
                            key: "all-orders",
                            path: `${ADMIN_PREFIX_PATH}/all-orders`,
                            title: language.all,
                            icon: ProfileOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_PENDING_ORDERS
                      )
                        ? [
                          {
                            key: "pending-orders",
                            path: `${ADMIN_PREFIX_PATH}/pending-orders`,
                            title: language.order_to_be_sent,
                            icon: ClockCircleOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_STAT_ORDERS
                      )
                        ? [
                          {
                            key: "stat-orders",
                            path: `${ADMIN_PREFIX_PATH}/stat-orders`,
                            title: language.stat_orders,
                            icon: ClockCircleOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS)
                      //   ? 
                      //     [{
                      //     key: "approved-orders",
                      //     path: `${ADMIN_PREFIX_PATH}/approved-orders`,
                      //     title: language.approved,
                      //     icon: CheckCircleOutlined,
                      //     breadcrumb: true,
                      //     submenu: [],
                      //   }] : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_ORDER_STAT_RESULTS
                      )
                        ? [
                          {
                            key: "statorder",
                            path: `${ADMIN_PREFIX_PATH}/order-stat`,
                            title: "Stat (Coming Soon...)",
                            icon: WarningOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      // ...(DoesThisUserHasAPermissionToThisComponent(
                      //   VIEW_SAMPLE_NOT_RECIEVED_ORDERS
                      // )
                      //   ? [
                      //       {
                      //         key: "sampleaccepted",
                      //         path: `${ADMIN_PREFIX_PATH}/sample-accepted`,
                      //         title: "Sample Accepted",
                      //         icon: FieldTimeOutlined,
                      //         breadcrumb: true,
                      //         submenu: [],
                      //       },
                      //     ]
                      //   : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_REFERABLE_ORDERS
                      )
                        ? [
                          {
                            key: "referrableorders",
                            path: `${ADMIN_PREFIX_PATH}/referable-orders`,
                            title: language.referal_orders,
                            icon: FieldTimeOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      // ...(DoesThisUserHasAPermissionToThisComponent(
                      //   VIEW_NOT_RELEASED_ORDERS
                      // )
                      //   ? [
                      //       {
                      //         key: "notyetreleased",
                      //         path: `${ADMIN_PREFIX_PATH}/not-yet-released`,
                      //         title: "Accepted But Unreleased Orders",
                      //         icon: ClockCircleOutlined,
                      //         breadcrumb: true,
                      //         submenu: [],
                      //       },
                      //     ]
                      //   : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_SAMPLE_NOT_RECIEVED_ORDERS
                      )
                        ? [
                          {
                            key: "sampleinprogress",
                            path: `${ADMIN_PREFIX_PATH}/sample-in-process`,
                            title: language.sample_processing_in_lab,
                            icon: FieldTimeOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_DELCINED_ORDERS
                      )
                        ? [
                          {
                            key: "declined-results",
                            path: `${ADMIN_PREFIX_PATH}/declined-orders`,
                            title: language.rejected,
                            icon: DeleteOutlined,
                            iconColor: "#550000",
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
                        
                      ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) ?
                      [
                        {
                          key: "timeline",
                          path: `${ADMIN_PREFIX_PATH}/show-patient-timeline`,
                          title: language.time_line,
                          icon: CheckCircleOutlined,
                          submenu: [],
                          breadcrumb: true
                        }
                      ]
                      : []),
                    ],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_ALL_RELEASED_RESULTS
              ) ||
                //  || DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS)
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_MICRO_BIOLOGY_RESULTS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_PANIC_AND_ABNORMAL_TESTS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_INPERSON_RESULTS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_PATIENT_RESULT_HISTORY
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_PANIC_STAT_RESULTS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_ALL_INCOMPLETE_RESULTS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_COMPLETED_RESULTS
                ) ||
                DoesThisUserHasAPermissionToThisComponent(VIEW_FAULT_RESULTS)
                ? [
                  {
                    key: "results",
                    path: `${ADMIN_PREFIX_PATH}/client`,
                    title: language.results,
                    icon: BarcodeOutlined,
                    breadcrumb: true,
                    submenu: [
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_ALL_RELEASED_RESULTS
                      )
                        ? [
                          {
                            key: "patientresult",
                            path: `${ADMIN_PREFIX_PATH}/patient-result`,
                            title: language.all,
                            icon: ProfileOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_COMPLETED_RESULTS
                      )
                        ? [
                          {
                            key: "Completed",
                            path: `${ADMIN_PREFIX_PATH}/completed-results`,
                            title: language.completed,
                            icon: CheckCircleOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_ALL_INCOMPLETE_RESULTS
                      )
                        ? [
                          {
                            key: "Incomplete",
                            path: `${ADMIN_PREFIX_PATH}/incomplete-results`,
                            title: language.incomplete,
                            icon: ClockCircleOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_MICRO_BIOLOGY_RESULTS
                      )
                        ? [
                          {
                            key: "micro-biology-results",
                            path: `${ADMIN_PREFIX_PATH}/micro-biology`,
                            title: "Micro & Patho Results",
                            icon: QuestionCircleOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS)
                      // ? ([{
                      //   key: "ordermadebyguestuser",
                      //   path: `${ADMIN_PREFIX_PATH}/ordermadebyguestusers`,
                      //   title: "For Guest Users",
                      //   icon: UserOutlined,
                      //   breadcrumb: true,
                      //   submenu: [],
                      // }]) : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_PANIC_STAT_RESULTS
                      )
                        ? [
                          {
                            key: "stat",
                            path: `${ADMIN_PREFIX_PATH}/panicandabnormaltests`,
                            title: language.comming_soon,
                            icon: WarningOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_PANIC_AND_ABNORMAL_TESTS
                      )
                        ? [
                          {
                            key: "panicandabnormaltests",
                            path: `${ADMIN_PREFIX_PATH}/panicandabnormaltests`,
                            title: language.pannic_results,
                            icon: FlagOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),

                          
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_PANIC_AND_ABNORMAL_TESTS
                      )
                        ? [
                          {
                            key: "stattests",
                            path: `${ADMIN_PREFIX_PATH}/stattests`,
                            title: 'Stat Results',
                            icon: FlagOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),

  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_INPERSON_RESULTS
                      )
                        ? [
                          {
                            key: "inpersonresultdelivery",
                            path: `${ADMIN_PREFIX_PATH}/in-person-result-delivery`,
                            title: language.confidential_results,
                            icon: SafetyCertificateOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_PATIENT_RESULT_HISTORY
                      )
                        ? [
                          {
                            key: "patienttestresulthistory",
                            path: `${ADMIN_PREFIX_PATH}/patienttestresulthistory`,
                            title: language.commulative_sum_report,
                            icon: BarChartOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_FAULT_RESULTS
                      )
                        ? [
                          {
                            key: "fault-results",
                            path: `${ADMIN_PREFIX_PATH}/fault-results`,
                            title: language.faulty_pdf,
                            icon: ExclamationOutlined,
                            iconColor: "#FF0000",
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
                    ],
                  },
                ]
                : []),
              ...(DoesThisUserHasAPermissionToThisComponent(
                UPLOAD_MICRO_BIOLOGIES_RESULTS
              )
                ? [
                  {
                    key: "fileupload",
                    path: `${ADMIN_PREFIX_PATH}/uploadresult`,
                    title: language.upload_results,
                    icon: UploadOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                UPLOAD_CSV_PANELS
              )
                ? [
                  {
                    key: "csvpanelupload",
                    path: `${ADMIN_PREFIX_PATH}/uploadcsv`,
                    title: language.upload_csv_results,
                    icon: UploadOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                UPLOAD_FINANCIAL_REPORT
              )
                ? [
                  {
                    key: "financialreportupload",
                    path: `${ADMIN_PREFIX_PATH}/uploadfinancial`,
                    title: language.upload_financial_report,
                    icon: UploadOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_ALL_DISCREPANCIES
              ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_APPROVED_DESCREPANCIES
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_PENDING_DISCREPANCIES
                ) ||
                DoesThisUserHasAPermissionToThisComponent(
                  VIEW_DECLINED_DESCREPANCIES
                )
                ? [
                  {
                    key: "discrepancy",
                    path: `${ADMIN_PREFIX_PATH}/discrepancy`,
                    title: language.corrected_results,
                    icon: ReconciliationOutlined,
                    breadcrumb: true,
                    submenu: [
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_ALL_DISCREPANCIES
                      )
                        ? [
                          {
                            key: "discrepancyall",
                            path: `${ADMIN_PREFIX_PATH}/discrepancy/all`,
                            title: language.all,
                            icon: ProfileOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_PENDING_DISCREPANCIES
                      )
                        ? [
                          {
                            key: "discrepancy-pending",
                            path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-pending`,
                            title: language.pending,
                            icon: QuestionCircleOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_APPROVED_DESCREPANCIES
                      )
                        ? [
                          {
                            key: "discrepancy-approved",
                            path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-approved`,
                            title: language.approved,
                            icon: CheckCircleOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
  
                      ...(DoesThisUserHasAPermissionToThisComponent(
                        VIEW_DECLINED_DESCREPANCIES
                      )
                        ? [
                          {
                            key: "discrepancy-declined",
                            path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-declined`,
                            title: language.declined,
                            icon: IssuesCloseOutlined,
                            breadcrumb: true,
                            submenu: [],
                          },
                        ]
                        : []),
                    ],
                  },
                ]
                : []),
              ...(DoesThisUserHasAPermissionToThisComponent(
                DAILY_RELEASED_TESTS
              )
                ? [
                  {
                    key: "daily-released-tests",
                    path: `${ADMIN_PREFIX_PATH}/daily-released-tests`,
                    title: language.daily_released_tests,
                    icon: ExperimentOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                DAILY_SINGLE_RELEASED_TESTS
              )
                ? [
                  {
                    key: "single-daily-released-tests",
                    path: `${ADMIN_PREFIX_PATH}/single-daily-released-tests`,
                    title: language.single_daily_released_tests,
                    icon: ExperimentOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                DAILY_DEPARTMENT_RELEASED_TESTS
              )
                ? [
                  {
                    key: "daily-department-released-tests",
                    path: `${ADMIN_PREFIX_PATH}/daily-department-released-tests`,
                    title: language.daily_department_released_tests,
                    icon: ExperimentOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_TEST_OBX_PER_TECHNICIAN
              )
                ? [
                  {
                    key: "testobxpertechnician",
                    path: `${ADMIN_PREFIX_PATH}/testobx-per-technician`,
                    title: language.tests_per_technician,
                    icon: FieldTimeOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
            ],
          },
        ]
        : []),
  
        
        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_DAILY_FINANCIAL_SUMMARY)
        ? [
          {
            key: "daily_financial_summary",
            path: `${ADMIN_PREFIX_PATH}/daily_financial_summary`,
            title: language.daily_financial_summary,
            icon: MoneyCollectOutlined,
            breadcrumb : true,
            submenu: [],
          },
        ]
        : []),
  
        ...(DoesThisUserHasAPermissionToThisComponent(Sample_Managment)
        ? [
          {
            key: "specimen_tracking",
            path: `${ADMIN_PREFIX_PATH}/specimen_tracking`,
            title: language.specimen_managment,
            breadcrumb: true,
            icon: YuqueOutlined,
            submenu: [],
          },
        ]
        : []),
        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_LOGISITICS) ? [
          {
            key: 'logisticsrequest',
            path: `${ADMIN_PREFIX_PATH}/logistics-request`,
            title: language.logistics,
            icon: CarOutlined,
            submenu: [],
            breadcrumb: true
          }] : []),
                
      ...(DoesThisUserHasAPermissionToThisComponent(
        VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS
      ) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_AGENCIES) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_PANEL_FOR_ICL) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_PANEL_OBR) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_AFTER_TAT) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_REPORTS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL_REPORTS) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_RESULTS_OBX) ||
        DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_PANELS)
        ? [
          {
            key: "reports",
            path: `${APP_PREFIX_PATH}/reports`,
            title: "Reports",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [
              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS
              )
                ? [
                  {
                    key: "topbranchesforwalkincustomer",
                    path: `${ADMIN_PREFIX_PATH}/top-branchs-for-walkin-customers`,
                    title: language.top_branches_for_walkin_customers,
                    icon: BranchesOutlined,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_AFTER_TAT) ? [
                {
                  key: "relasedaftertat",
                  path: `${ADMIN_PREFIX_PATH}/released-after-tat`,
                  title: language.released_after_tat,
                  icon: ClockCircleOutlined,
                  breadcrumb: true,
                  submenu: [],
                },
              ]
                : []),
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_REPORTS)
                ? [
                  {
                    key: "reports",
                    path: `${ADMIN_PREFIX_PATH}/reports`,
                    title: language.tat_routine_report,
                    icon: ClockCircleOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_RELEASED_PANEL_FOR_ICL
              )
                ? [
                  {
                    key: "released for icl",
                    path: `${ADMIN_PREFIX_PATH}/released-panels`,
                    title: language.released_pannels_for_icl,
                    icon: ClusterOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_AGENCIES)
                ? [
                  {
                    key: "topagencies",
                    path: `${ADMIN_PREFIX_PATH}/top-agencies`,
                    title: language.top_agencies,
                    icon: ClusterOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS)
                ? [
                  {
                    key: "toptests",
                    path: `${ADMIN_PREFIX_PATH}/top-tests`,
                    title: language.top_tests,
                    icon: ExperimentOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_PENDING_RESULTS_OBX
              )
                ? [
                  {
                    key: "pendingresults",
                    path: `${ADMIN_PREFIX_PATH}/pending-results-obx`,
                    title: "Pending Results",
                    icon: ClockCircleOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_PANELS)
                ? [
                  {
                    key: "toppanels",
                    path: `${ADMIN_PREFIX_PATH}/top-panels`,
                    title: language.top_orders,
                    icon: ClusterOutlined,
                    breadcrumb: true,
                    submenu: [],
                  },
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL_REPORTS)
                ? [
                  {
                    key: "cost",
                    path: `${ADMIN_PREFIX_PATH}/cost`,
                    title: language.credit_invoice,
                    icon: DollarCircleOutlined,
                    submenu: [],
                  },
                  
                ]
                : []),
  
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL)
                ? [
                  {
                    key: "financial",
                    path: `${ADMIN_PREFIX_PATH}/financial`,
                    title: language.financial,
                    icon: DollarCircleOutlined,
                    submenu: [],
                  },
                ]
                : []),
  
  
  
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TAT_APPROACHING)
                ? [
                  {
                    key: "tat-approaaching",
                    path: `${ADMIN_PREFIX_PATH}/tat-approaching`,
                    title: language.tat_approaching,
                    icon: ClockCircleOutlined,
                    submenu: [],
                  },
                ]
                : []),

  
              // {
              //   key: "topreferals",
              //   path: `${ADMIN_PREFIX_PATH}/top-referalss`,
              //   title: "Referrals (Coming Soon...)",
              //   icon: ClusterOutlined,
              //   submenu: [],
              // },
              // {
              //   key: "agency-tests-pending",
              //   path: `${
              //     ADMIN_PREFIX_PATH
              //   }/agency-tests-pending`,
              //   title: "Agency-Pending Tests (Coming Soon...)",
              //   icon: ClusterOutlined,
              //   submenu: [
              //   ],
              // },
              // {
              //   key: "agency-tests-rejected",
              //   path: `${
              //     ADMIN_PREFIX_PATH
              //   }/agency-tests-rejected`,
              //   title: "Agency-Rejected (Coming Soon...)",
              //   icon: RestOutlined,
              //   submenu: [
              //   ],
              // },
              // {
              //   key: "agency-approved-discrepancy",
              //   path: `${ADMIN_PREFIX_PATH}/agency-approved-discrepancy`,
              //   title: "Agency-Discrepancy (Coming Soon...)",
              //   icon: ClusterOutlined,
              //   submenu: [],
              // },
            ],
          },
        ]
        : []),
  
      // {
      //   key: "Customer Support",
      //   path: `${ADMIN_PREFIX_PATH}/client`,
      //   title: "Telegram Bot",
      //   icon: TeamOutlined,
      //   breadcrumb: true,
      //   submenu: [
      //     ...(DoesThisUserHasAPermissionToThisComponent(
      //       VIEW_TELEGRAM_BOT_MENU
      //     )
      //       ? [
      //           {
      //             key: "customer-support",
      //             title: "Telegram Bot",
      //             path : 'https://google.com/',
      //             icon: UserAddOutlined,
      //             breadcrumb : true,
      //             submenu: [],
      //             target: "_blank",
      //           },
      //           {
      //             key: "tgbot",
      //             path: "https://google.com",
      //             title: "Telegram Bot",  
      //             icon: RobotOutlined ,
      //             submenu: [],
      //             menuItem: (
      //               <Link to='https://t.me/Icladdisbot' target="_blank">
                      
      //               </Link>
      //             )
      //           }                
      //         ]
      //       : [])]
      // },


      ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TELEGRAM_BOT_MENU)
      ? [
          {
            key: "tgbot",
            path: `${ADMIN_PREFIX_PATH}/telegram_bot`,
            title: "Telegram Bot",
            icon: RobotOutlined,
            submenu: [],
          }
        ]
      : []),
    
    





    ];
  }
  else if (doesThisUserIsDoctor()) {
    navigationConfig = [...doctorsAppNavTree];
  }
  else if (role === "qc-admin") {
    navigationConfig = [...qualityControlNavTree];
  } else navigationConfig = [...dashBoardNavTree];
  
  return navigationConfig;
 }

 export default getItems();
