import fetch from "auth/FetchInterceptor";

const CouponService = {};

CouponService.index = function () {
  return fetch({
    url: "/coupons",
    method: "get",
  });
};

CouponService.create = function (data) {
  return fetch({
    url: "/coupons",
    method: "post",
    data,
  });
};

CouponService.print = function (data) {
  return fetch({
    url: `/coupons/print`,
    method: "put",
    data,
  });
};

CouponService.use = function (data) {
  return fetch({
    url: `/coupons/use`,
    method: "put",
    data,
  });
};

CouponService.delete = function (id) {
  return fetch({
    url: `/coupons/${id}`,
    method: "delete",
  });
};

export default CouponService;
