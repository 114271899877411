import{
     UPLOAD_FILE_REQUEST,
     UPLOAD_FILE_FAILURE,
     UPLOAD_FILE_SUCCESS
} from "constants/UploadFile"

export const onUploadMicroBiologyResultRequest = (formData, uploadInformation) =>{
     return {
        type : UPLOAD_FILE_REQUEST,
        payload : {
         formData,
         uploadInformation
       }
     }
}


export const onUploadMicroBiologyResultSuccess = (payload) =>{
    return {
       type : UPLOAD_FILE_SUCCESS,
       payload : payload
    }
}


export const onUploadMicroBiologyResultFailure = (errorMessage) =>{
    return {
       type : UPLOAD_FILE_FAILURE,
       paylaod : errorMessage
    }
}