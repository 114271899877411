import _ from "lodash";


export const addRole = (state, action) => {
  return {
    ...state,
    list: action.payload.roles,
    permissions: action.payload.permissions,
  };
};

export const updateRole = (state, action) => {
  let list = state.list;
  const updatedRole = action.payload;

  const index = _.findIndex(list, ["id", updatedRole.id]);
  list[index] = updatedRole;

  return [...list];
};

export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
