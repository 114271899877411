export const SHOW_ORDER_MADE_BY_GUEST_USERS_EDIT_FORM = "SHOW_ROLE_EDIT_FORM";
export const SET_ORDER_MADE_BY_GUEST_USRS_VALIDATION_ERROR = "SET_ORDER_MADE_BY_GUEST_USRS_VALIDATION_ERROR";

export const FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST = "FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST";
export const FETCH_ORDER_MADE_BY_GUEST_USERS_SUCCESS = "FETCH_ORDER_MADE_BY_GUEST_USERS_SUCCESS";
export const FETCH_ORDER_MADE_BY_GUEST_USERS_FAILURE = "FETCH_ORDER_MADE_BY_GUEST_USERS_FAILURE";

export const DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST = "DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST";
export const DELETE_ORDER_MADE_BY_GUEST_USERS_SUCCESS = "DELETE_ORDER_MADE_BY_GUEST_USERS_SUCCESS";
export const DELETE_ORDER_MADE_BY_GUEST_USERS_FAILURE = "DELETE_ORDER_MADE_BY_GUEST_USERS_FAILURE";

export const UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST = "UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST";
export const UPDATE_ORDER_MADE_BY_GUEST_USERS_SUCCESS = "UPDATE_ORDER_MADE_BY_GUEST_USERS_SUCCESS";
export const UPDATE_ORDER_MADE_BY_GUEST_USERS_FAILURE = "UPDATE_ORDER_MADE_BY_GUEST_USERS_FAILURE";