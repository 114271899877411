export const English = {
    "type": "ENG",
    "home" : "Home",
    "upload" : "Upload File",
    "start_upload" : "Start Upload",
    "uploading" : "Uploading",
    "user_management": "User Management",
    "change_password": "Change Password",
    "role": "Roles",
    "user": "Users",
    "abbrev" : "Abbrev",
    "test_menu" : "Test Menu",
    "config" : "Config",
    "currency" : "Currency",
    "credit_allowed" : "Credit Allowed",
    "allowed_for_credit" : "Allowed For Credit",
    "tin_or_arn" : "TIN or ARN",
    "npid" : "NPID",
    "contact" : "Contact",
    "speed_dial" : "Speed Dial",
    "fee_schedule" : "Fee Schedule",
    "dashboard" : "Dashboard",
    "select_agency" : "Select Agency",
    "select_department" : "Select Department",
    "edit_department" : "Edit Department",
    "edit_agency" : "Edit Client Company",
    "agency_detail" : "Agency Detail",
    "select_role" : "Select Role",
    "select_location" : "Select Location",
    "departments": "Departments",
    "agency_definition": "Agency Definition",
    "discontinued_test_results": "Discontinued Test Results",
    "assign_test_to_agency": "Assign test to agency",
    "branch_look_up": "Branch look up",
    "test_definition": "Test Definition",
    "core_lab": "Core Lab",
    "tb_micro_pending": "Tb/Micro Pending",
    "place_order": "Place Order",
    "orderable" : "Orderable",
    "none_orderable" : "None-Orderable",
    "pick_up_request": "Pick Up Request",
    "time_line": "Time Line",
    "order_status": "Order Status",
    "all": "All",
    "results": "Results",
    "completed": "Completed",
    "incomplete": "Incomplete",
    "micro_result": "Micro Result",
    "comming_soon": "Comming Soon",
    "pannic_results": "Panic Results",
    "confidential_results": "Confidential Results",
    "commulative_sum_report": "Commulative Sum Report",
    "faulty_pdf": "Faulty Pdf",
    "upload_results": "Upload Results",
    "upload_csv_results": "Upload Csv Results",
    "upload_financial_report": "Upload Financial Report",
    "corrected_results": "Corrected Results",
    "pending": "Pending",
    "approved": "Approved",
    "declined": "Declined",
    "daily_released_tests": "Daily Released Tests",
    "single_daily_released_tests": "Single Daily Released Tests",
    "daily_department_released_tests": "Daily Department Released Tests",
    "order_to_be_sent": "Pending Order",
    "stat_orders": "Stat Orders",
    "referal_orders": "Referal Orders",
    "referable" : "Referable",
    "sample_processing_in_lab": "Sample Processing in Lab",
    "rejected": "Rejected Samples",
    "tests_per_technician": "Tests Per Technician",
    "daily_financial_summary": "Daily Financial Summary",
    "specimen_tracking": "Specimen Tracking",
    "top_branches_for_walkin_customers": "Top Branches for Walk-in-Customers",
    "released_after_tat":"Released after TAT",
    "tat_routine_report": "TAT-Routine Report",
    "released_pannels_for_icl":"Released Panels For ICL",
    "top_agencies":"Top Agencies",
    "top_tests":"Top Tests",
    "top_orders":"Top Orders",
    "credit_invoice":"Credit Invoice",
    "financial":"Financial",
    "tat_approaching": "TAT Approaching",
    "submit" : "Submit",
    "test_results" : "Test Results",
    "released_tests" : "Released Tests",
    "pending_tests" : "Pending Tests",
    "panic_tests" : "Panic Tests",
    "uncommunicated_panic" : "Uncommunicated Panics",
    "average_tat" : "Average TAT",
    "average_release_time" : "AVE Release Time",
    "average_delayed_tests" : "Average Delayed Tests",
    "total_orders" : "Total Orders",
    "total_income" : "Total Income",
    "total_patients" : "Total Patients",
    "order_statistics" : "Order Statistics",
    "agency" : "Agency",
    "walkin" : "Walk-In",
    "payment" : "Payment",
    "view_detail" : "View Detail",
    "you_must_select_agency" : "You must select agency",
    "detail" : "Detail",
    "edit" : "Edit",
    "en" : "en",
    "delete" : "Delete",
    "available" : "Available",
    "not_available" : "Not Available",
    "sample_id" : "Sample Id",
    "name" : "Name",
    "agency_id" : "Agency Id",
    "agency_number" : "Agency Number",
    "status" : "Status",
    "apply_filter" : "Apply Filter",
    "reset" : "Reset",
    "patient_id" : "Patient Id ( Optional )",
    "email" : "Email",
    "firstname" : "First Name",
    "lastname" : "Last Name",
    "sex" : "Sex",
    "date_of_birth" : "DOB",
    "phone_number" : "Phone ( 09... )",
    "location" : "Location",
    "username" : "UserName",
    "active" : "Active",
    "actions" : "Actions",
    "user_detail" : "User Detail",
    "inactive" : "In Active",
    "search" : "Search",
    "filter" : "Filter",
    "add_new_user" : "Add New User",
    "add_new_department" : "Add New Department",
    "add_client_company" : "Add Client Company",
    "choose_agency_first" : "Choose Agency First",
    "edit_user" : "Edit User",
    "yes" : "Yes",
    "no" : "No",
    "notification" : "Notifications",
    "dark" : "dark",
    "light" : "light",
    "cashier_location" : "Cashier Location",
    "city" : "City",
    "cashier_code" : "Cashier Code",
    "code" : "Code",
    "password" : "Password",
    "is_icl_user" : "Is ICL uSER",
    "are_you_sure" : "Are you sure delete this?",
    "copyright" : "Copyright",
    "icl_all_right_reserved" : " All rights reserved",
    "permissions": "Permissions",
    "add_role": "Add Role",
    "add_new_role": "Add New Role",
    "select_permission": "Select Permission",
    "enter_name": "Enter Name",
    "edit_permission_for": "Edit Permission for",
    "role_detail": "Role Detail",
    "test_name" : "Test Name",
    "referral" : "Referral",
    "success" : "Success",
    "error" : "Error",
    "temporarly_discontinued_test" : "Temporarily Discontinued",
    "sample_type" : "Sample Type",
    "sample_volume" : "Sample Volume",
    "sample_container" : "Sample Container",
    "price" : "Price",
    "non_orderable" : "Non orderable",
    "add_new_test" : "Add New Test",
    "test_schedule" : "Test Schedule",
    "update_stat_and_urgent_price" : "Update Stat and Urgent Price",
    "stat_price" : "Stat Price",
    "urgent_price" : "Urgent Price",
    "field_is_required" : "Field is required",
    "updating" : "Updating",
    "update" : "Update",
    "enable_edit" : "Enable Edit",
    "test_abbreviation" : "Test Abbreviation",
    "test_full_name" : "Test FullName",
    "number_of_tests" : "Number of Tests",
    "department_name" : "Department",
    "price_etb_agency_a" : "Price ETB Agency A",
    "price_etb_agency_b" : "Price ETB Agency B",
    "price_etb_agency_c" : "Price ETB Agency C",
    "price_etb_agency_d" : "Price ETB Agency D",
    "price_etb_agency_e" : "Price ETB Agency E",
    "price_etb_agency_f" : "Price ETB Agency F",
    "price_etb_agency_g" : "Price ETB Agency G",
    "price_etb_agency_h" : "Price ETB Agency H",
    "price_etb_agency_i" : "Price ETB Agency I",
    "price_etb_agency_j" : "Price ETB Agency J",
    "price_etb_agency_k" : "Price ETB Agency K",
    "price_etb_agency_l" : "Price ETB Agency L",
    "price_etb_agency_m" : "Price ETB Agency M",
    "price_etb_agency_n" : "Price ETB Agency N",
    "price_etb_agency_o" : "Price ETB Agency O",
    "price_etb_agency_p" : "Price ETB Agency P",
    "price_etb_agency_q" : "Price ETB Agency Q",
    "price_etb_agency_r" : "Price ETB Agency R",
    "price_etb_agency_s" : "Price ETB Agency S",
    "price_etb_agency_t" : "Price ETB Agency T",
    "price_etb_agency_u" : "Price ETB Agency U",
    "price_etb_agency_v" : "Price ETB Agency V",
    "price_etb_agency_w" : "Price ETB Agency W",
    "price_etb_agency_x" : "Price ETB Agency X",
    "price_etb_agency_y" : "Price ETB Agency Y",
    "price_etb_agency_z" : "Price ETB Agency Z",

    "price_usd_agency_a" : "Price USD Agency A",
    "price_usd_agency_b" : "Price USD Agency B",
    "price_usd_agency_c" : "Price USD Agency C",
    "price_usd_agency_d" : "Price USD Agency D",
    "price_usd_agency_e" : "Price USD Agency E",
    "price_usd_agency_f" : "Price USD Agency F",
    "price_usd_agency_g" : "Price USD Agency G",

    "tat_urgent" : "Tat Urgent",
    "tat_stat" : "Tat Stat",
    "ignorable_tat" : "Ignorable Tat",
    "fake_department" : "Fake Department",
    "inperson_delivery" : "Inperson Delivery",
    "currently_available" : "Currently Available",
    "panel_number" : "Panel Number",
    "charge_code" : "Charge Code",
    "test_catagory" : "Test Catagory",
    "test_sub_category" : "Test Sub Category",
    "test_description" : "Test Description",
    "price_etb" : "Price (ETB)",
    "price_usd" : "Price (USD)",
    "standard_test_name" : "Standard Test Name",
    "standard_department_name" : "Standard Department Name",
    "standard_abbreviation" : "Standard Abbreviation",
    "test_methodology" : "Test Methodology",
    "estimated_result_time_in_hour" : "Estimated Result Time In Hour",
    "test_done_abroad" : "Test Done Abroad",
    "is_ihc_est" : "Is IHC Test",
    "constant_only_for_pathology" : "Constant (Only for pathology)",
    "walking_customer_price_usd" : "Walking Customer Price (USD)",
    "walking_customer_price_etb" : "Walking Customer Price (ETB)",
    "are_you_sure_delete_this_test" : "Are you sure delete this test?",
    "department_code" : "Department Code",
    "has_custom_data" : "Has Custom Data",
    "in_person_delivery" : "In Person Delivery",
    "icl_branch" : "ICL Branch",
    "not_icl_branch" : "Not Icl Branch",
    "parent" : "parent",
    "clear" : "Clear",
    "signout" : "Sign out",
    "main": "Main",
    "additional_info": "Additional Info",
    "insurance": "Insurance",
    "my_lab": "My Lab",
    "pathology": "Pathology",
    "mrn_no": "MRN No ( Optional )",
    "first_name": "First Name",
    "middle_name": "Middle Name",
    "last_name": "Last Name",
    "phone": "Phone",
    "primary_doctor": "Primary Doctor",
    "secondary_doctor": "Secondary Doctor",
    "test_performed_lab": "Test Performed Lab",
    "select_lab": "Select Lab",
    "phone_alt": "Phone Alt",
    "region": "Region",
    "street": "Street",
    "passport_no": "Passport No",
    "select_insurance": "Select Insurance",
    "member_id": "Member Id",
    "did_you_have_a_meal_today": "Did you have a meal today?",
    "have_you_exercised_today": "Have you exercised today?",
    "exercise_regularly": "Do you exercise regularly?",
    "how_many_times_do_you_excesise_per_week": "How many times per week do you exercise?",
    "how_long_excercise": "How many minutes does your exercise take?",
    "excercise_grade": "How would you grade your exercise mostly?",
    "feel_stressed": "Do you feel stressed?",
    "professional_help": "Have you ever sought professional help to manage stress?",
    "sleep_duration":"How many hours per day do you sleep?",
    "caffine_consumption_daily": "How many cups of coffee or tea do you normally consume daily?",
    "daily_water_intake": "How many liters of water do you normally drink daily?",
    "daily_fruit_intake": "Are you getting 3-5 servings of fruits and vegetables/day?",
    "usually":"Do you usually?",
    "smoke_status": "Do you smoke?",
    "somoke_other_than_cigar": "Do you smoke other than cigar?",
    "alcohol_consumption": "Do you consume alcohol?",
    "work_nature": "How would you describe your work?",
    "residence": "Residence?",
    "travel_status":"Travel Around?",
    "dm_family_history": "Do you have family history of Diabetes Mellitus ?",
    "htn_family_history": "Do you have family history of Hypertension ?",
    "cancer_family_history": "Do you have family history of Cancer ?",
    "education_level": "Education Level",
    "pregnancy": "Are you pregnant?",
    "breast_feeding_status": "Are you breast feeding?",
    "contraception_use":"Do you use hormonal contraceptives?",
    "less_than": "Less Than",
    "minutes": "Minutes",
    "more": "More",
    "and": "And",
    "simple": "Light",
    "moderate": "Moderate",
    "vigorous": "Vigorous",
    "hours": "Hours",
    "eat_out": "Eat Out",
    "cook_at_home": "Cook At Home",
    "work_nature_1": "Office works mostly sitting",
    "work_nature_2": "Office work but requires lots of movement/travel",
    "work_nature_3": "Physical work legally standing",
    "urban": "Urban",
    "rural": "Rural",
    "suburban": "Suburban",
    "travel_by_private":"By private car",
    "walk": "Walk",
    "read_write": "Read and write",
    "secondary_school": "Secondary School",
    "illiterate": "Illiterate",
    "primary_school": "Primary School",
    "college_and_above":"College and above",
    "search_pannel": "Search Panels with Name, Code ....",
    "search_test_pannels": "Search Test Pannels",
    "clinical_info": "Clinical Information",
    "relevant_history": "Relevant history",
    "relevant_image": "Relevant Image",
    "relevant_lab": "Relevant Lab work",
    "enter_relevant_info":"Enter Relevant history Information",
    "enter_relevant_image_info": "Enter Relevant Image Information",
    "enter_relevant_lab_work_info":"Enter Relevant Lab work Information",
    "specimen_class": "Specimen Class",
    "biopsy_type": "Biopsy Type",
    "action": "Action",
    "urgent_order": "Urgent Order",
    "search_here": "Search Here",
    "patient_search": "Patient Search",
    "test_code": "Test Code",
    "default_price": "Default Price",
    "schedule": "Schedule",
    "add_to_table": "Add to Table",
    "male": "Male",
    "female": "Female",
    "order_summary": "Order Summary",
    "full_name": "Full Name",
    "total_number_of_tests":"Total Number of Tests",
    "extra_fee": "Extra Fee",
    "total_price": "Total Price",
    "list_of_sample_container": "List of sample container",
    "show_printable_order_summary": "Show Printable Order Summary",
    "save": "Save",
    "order": "Order",
    "cancel": "Cancel",
    "costs": "This will cost you ",
    "provide_additional_patho_info": "Pathology Test has been selected. You will need to fill additional pathology information.",
    "field_required": "Field is required",
    "okay": "Okay",
    "transaction_response":"Transaction Response",
    "cashier_summary":"Order Summary For Cashier",
    "confirm": "Confirm",
    "print_cash_receipt":"Print receipt",
    "pt_agreed": "Patient Agreed",
    "reciept_no": "Reciept /FS No.",
    "add_new_branch" : "Add New Branch",
    "branch_name" : "Branch Name",
    "concatenated_code" : "Concatenated Code",
    "branch_code" : "Branch Code",
    "branch_description" : 'Branch Description',
    "telephone_1" : "Telephone 1",
    'telephone_2' : "Telephone 2",
    "sample_collection_only" : "Sample Collection Only",
    "covid_test_provided" : "Covid Test Provided",
    "wellness_test_provided" : "Wellness Test Provided",
    "speed_dial_provider" : "Speed Dial Provider",
    "open_on_holiday" : "Open On Holiday",
    "open_every_time" : 'Open Every Time',
    "visible_to_public" : "Visible to public",
    "allowed_for_coupon" : "Allowed for Coupon",
    "edit_branch_look_up" : "Edit Branch Look Up",
    "add_branch_look_up" : "Add New Branch Lookup",
    "view_branch_look_up_detail" : "Branch LookUp Detail",
    "panel_summary" : "Panel Summary",
    "are_you_sure_this_panels_are_fore" : "Are you sure this panels are for ",
    "other" : "Other",
    "send_pickup_request" : "Send Pickup Request",
    "sending" : "Sending...",
    "search_historical_record" : "Search Historical Record",
    "fullname" : "Full Name" ,
    "cardnumber" : "MRN",
    "urgency" : "Urgency",
    "age" : "Age",
    "panic_result" : "Panic Result",
    "spoiled_samples" : "Spolied Samples",
    "approval_status" : "Approval Status",
    "ordered_time" : "Ordered Time",
    "downloading" : "Downloading",
    "download_existing" : "Download Existing",
    "only_csv_files_are_allowed" : "Only csv files are allowed",
    "select_category" : "Select Category",
    "month" : "Month",
    "add_to_list" : "Add to list",
    "select_file" : "Select File",
    "total_tests" : "Total Tests",
    "total_discrepancy" : "Total Discrepancy",
    "received_time" : "Received Time",
    "released_time" : "Released Time",
    "pdf" : "PDF",
    "flag" : "Flag",
    "new_time" : "New Time",
    "discrepancy_detail" : "Discrepancy Detail",
    "technician" : "Technician",
    "inserted_date" : "Inserted Date",
    "test_count" : "Test Count",
    "type_of_report" : "Type of report",
    "invoice_summary" : "Invoice Summary",
    "invoice_attachment" : "Invoice Attachments",
    "statement_of_account" : "Statement of account",
    "only_pdf_file_is_allowed" : "Only pdf file is allowed",
    "cashier_name" : "Cashier Name",
    "fs_number" : "Fs Number",
    "payment_type" : "Payment Type",
    "date_time" : "Date time",
    "export" : "Export",
    "sample_tube" : "Sample Tube",
    "comment" : "Comment",
    "remark" : "Remark",
    "enter_comment" : "Enter comment",
    "phlebotomy_comment" : "Phlebotomy Comment",
    "draw_location" : "Draw Location",
    "upload_by" : "Upload by",
    "uploaded_at" : "Uploaded at",
    "media_type" : "Media Type",
    "select_media_type" : "Select Media Type",
    "only_jpg_jpeg_png_and_pdf_file_is_allowed" : "Only jpg jpeg png and pdf file is allowed",
    "logistics" : "Logistics",
    "selectcashier" : "Select Cashier",
    "user_type" :"User Type",
	"specimen_managment" : "Specimen Managment",
    "recieved_time": "Recieved Time",
    "medical_license": "Medical License",
    "doctor_department": "Department",
}