import{
    FETCH_FINANCIAL_COST_FAILURE,
    FETCH_FINANCIAL_COST_REQUEST,
    FETCH_FINANCIAL_COST_SUCCESS
  } from "constants/Cost";
  
  export const fetchCostsRequest = (startDate, endDate, agency, department) => ({
    type: FETCH_FINANCIAL_COST_REQUEST,
    payload : {
       startDate, 
       endDate, 
       agency, 
       department
    }
  });
  
  export const fetchCostSuccess = (financials) => ({
    type: FETCH_FINANCIAL_COST_SUCCESS,
    payload: financials,
  });
  
  export const fetchFCostFailure = (error) => ({
    type: FETCH_FINANCIAL_COST_FAILURE,
    payload: error,
  });
  
  
  