import {
    FETCH_SINGLE_DAILY_RELEASED_TESTS_REQUEST,
    FETCH_SINGLE_DAILY_RELEASED_TESTS_FAILURE,
    FETCH_SINGLE_DAILY_RELEASED_TESTS_SUCCESS
} from "constants/SingleReleasedDailyReleasedTests"

export const fetchSingleDailyReleasedTestsRequest = (startDate, endDate, agency, department) =>{
    return {
        type : FETCH_SINGLE_DAILY_RELEASED_TESTS_REQUEST,
        payload : {
            startDate,
            endDate,
            agency,
            department
        }
    }
}

export const fetchSingleDailyReleasedTestsSuccess = (payload) =>{
    return {
        type : FETCH_SINGLE_DAILY_RELEASED_TESTS_SUCCESS,
        payload : payload
    }
}

export const fetchSingleDailyReleasedTestsFailure = (errorMessage) =>{
    return {
        type : FETCH_SINGLE_DAILY_RELEASED_TESTS_FAILURE,
        payload : errorMessage
    }
}