import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_PENDINGRESULTS_OBX_REQUEST } from "constants/PendingResultObx";
import { fetchPendingResultObxFailure, fetchPendingResultObxSuccess } from "redux/actions";


import PendingResultObxService from "services/PendingResultsObx";

export function* onFetchPendingResultsObx() {
  yield takeLatest(FETCH_PENDINGRESULTS_OBX_REQUEST, function* (payload) {
    try {
      const result = yield call(PendingResultObxService.get, payload.payload);
      if (result.status) {
        yield put(fetchPendingResultObxSuccess(result.data));
      } else {
        yield put(fetchPendingResultObxFailure(result.message));
      }
    } catch (error) {
        message.error("something went wrong");
      yield put(fetchPendingResultObxFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchPendingResultsObx)]);
}
