import { 
    FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
    FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS,
    FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE,  
} from "constants/AdminDashboardReports/DashboardReport"

export const fetchAdminReportsRequest = (startDate, endDate, agency, dateDifference) =>{
     return {
         type : FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
         payload :{
            startDate,
            endDate,
            agency,
            dateDifference
         }
     }
}

export const fetchAdminReportsSuccess = (payload) =>{
    return {
         type : FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS,
         payload : payload
    }
}

export const fetchAdminReportsFailure = ( errorMessage ) =>{
    return { 
        type : FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE,
        payload : errorMessage
    }
}