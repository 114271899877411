import axios from 'axios'
import { ORBIT_PASSWORD, ORBIT_PHONE_NUMBER, ORBIT_TOKEN_KEY } from 'redux/constants/Auth';

const apiUrlForUpdateOrderStatus = 'https://externallab.orbit3.ohcore.orbithealth.et/update-order-status';
const apiUrlForUrgentAndStatPrice = 'https://externallab.orbit3.ohcore.orbithealth.et/update-price/urgent';

const loginUrl = 'https://externallab.orbit3.ohcore.orbithealth.et/icl/login';

const apiUrlForUpdateTestDirectory = "https://externallab.orbit3.ohcore.orbithealth.et/update-availability";
const apiUrlForCreateTestDirectory = "https://externallab.orbit3.ohcore.orbithealth.et/new-panels";

// Make a POST request to the API with the payload and token.
export const updateOrderStatusToOrbit= async (data)=>{
    const token = getToken();
    
    if(token){
        nowCall(data, token, apiUrlForUpdateOrderStatus);
    } else {
        login(data, apiUrlForUpdateOrderStatus);
    }
}

// Make a POST request to the API with the payload and token.
export const updateUrgentAndStatPriceChange = (prices)=>{
    const token = getToken();
    if(token){
        nowCall(prices, token, apiUrlForUrgentAndStatPrice);
    } else {
        login(prices, apiUrlForUrgentAndStatPrice);
    }
}

// Make a POST request to the API with the payload and token.
export const updateTestDirectoryForOrbit = (id,data) =>{
    const token = getToken();
    data.estimated_time_for_result = "0" +  data.estimated_time_for_result + ":00:00";
    const actualData = {
        id, 
        data : data
    }
   
    if(token){
         nowCall(actualData, token, apiUrlForUpdateTestDirectory, 'PUT');
    } else {
        login(actualData, apiUrlForUpdateTestDirectory);
    }
}

// Make a POST request to the API with the payload and token.
export const createTestDirectoryOnOrbit = (data) =>{
    data.estimated_time_for_result = "0" +  data.estimated_time_for_result + ":00:00";
    let dataToBeSent = {"data" : [data]};
    const token = getToken();
    if(token){
        nowCall(dataToBeSent,token, apiUrlForCreateTestDirectory);
    } else {
        login(dataToBeSent, apiUrlForCreateTestDirectory);
    }
}

function login(data, url){
    axios({
        url: loginUrl,
        method: 'POST',
        data : {
             'phone' : ORBIT_PHONE_NUMBER,
             'password' : ORBIT_PASSWORD
        }
    }).then((response)=>{
        setTokenAndInitiateApiCall(data, response.data.token, url);
    })
    .catch((error)=>{
        console.log('Error', error)
    });
}

function setTokenAndInitiateApiCall(data,token, url){
    //now set the token to the localstore
    localStorage.setItem(ORBIT_TOKEN_KEY, token);
    nowCall(data, token, url);
}

function nowCall(data, token, url, method = 'POST'){
    // console.log("the data is : %j",data);
    // console.log("the token is : " + token);
    // console.log("the url is : "  + url);
    // console.log("the method is : " + method);
    axios({
        url: url,
        method: method,
        data : data,
        headers : {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', 
          }
    }).then((response)=>{
        console.log('respones is ok', response.data)
    })
    .catch((error)=>{
        // console.log(error.response);
        // console.log('Error', error.response.status);
        if(error.response.status === 401){
            login(data, url);
        }
        
    });
}

function getToken(){
    return localStorage.getItem(ORBIT_TOKEN_KEY);
}

