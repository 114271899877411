import { FETCH_APPOINTMENT_METADATA_REQUEST, SAVE_TEST_REGISTRATION_REQUEST } from "constants/Appointment";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { fetchAppointmentMetadataSuccess, saveTestRegistrationsSuccess } from "redux/actions/Appointment";
import AppointmentService from "services/AppointmentService";

export function* onFetchAppointmentMetadaRequest() {
  yield takeLatest(FETCH_APPOINTMENT_METADATA_REQUEST, function* () {
    try {
      const result = yield call(AppointmentService.getMetadata);
      if (!result.status) {
        yield console.log(result.data);
      } else {
        yield put(fetchAppointmentMetadataSuccess(result.data));
      }
    } catch (error) {
    }
  });
}

export function* onSaveTestRegistrationsRequest() {
  yield takeLatest(SAVE_TEST_REGISTRATION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(AppointmentService.saveTestRegistration, payload);
      if (!result.status) {
        yield console.log(result.data);
      } else {
        yield put(saveTestRegistrationsSuccess(result.data));
      }
    } catch (error) {
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchAppointmentMetadaRequest),
    fork(onSaveTestRegistrationsRequest),
  ]);
}
