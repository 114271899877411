import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import "components/shared-components/GlobalCheckBoxCssStyle.css";
import {
  Layout,
  Grid,
} from "antd";

import navigationConfig from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import DoctorAppViews from 'views/doctors-app-views';
import ReactConfetti from 'react-confetti';
import { USER_CONGRATULATED } from 'configs/AppConfig';
import { getCurrentUserId } from 'utils/CurrentUserUtil';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const DoctorLayout = ({ navCollapsed, navType, location, direction }) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP

  const width = window.innerWidth;
  const height = window.innerHeight;
  const [recycle, setRecycle] = useState(true);
  const [loops, setLoops] = useState(0);
  const userCongratulated = localStorage.getItem(getCurrentUserId());
  useEffect(() => {
    const loopInterval = !userCongratulated ?  setInterval(() => {
      setLoops((prevLoops) => prevLoops + 1);
    }, 2300) : null;

    if (loops >= 2) {
      setRecycle(false);
      clearInterval(loopInterval); 
      localStorage.setItem(getCurrentUserId(), 1);
    }

    return () => clearInterval(loopInterval); 
  }, [loops, userCongratulated]);

  //schedule for 4second to update the message
  const animInterval = setInterval(() => {
    localStorage.setItem(getCurrentUserId(), 1);
  }, 4000)

  clearInterval(animInterval);
  
  const getLayoutGutter = () => {
    if(isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if(direction === DIR_LTR) {
      return {paddingLeft: getLayoutGutter()}
    }
    if(direction === DIR_RTL) {
      return {paddingRight: getLayoutGutter()}
    }
    return {paddingLeft: getLayoutGutter()}
  }

  return (
    <Layout style={{overflow: 'hidden'}} >
        {
          !userCongratulated ?         
          <ReactConfetti
            width={width}
            height={height}
            recycle ={recycle}
            numberOfPieces={300}
            tweenDuration={1000}
        /> : <></>
        }

      <HeaderNav isMobile={isMobile}/>
      {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}
      <Layout className="app-container">
        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo}/> : null }
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <DoctorAppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(DoctorLayout));