import {
  DELETE_BRANCH_LOOK_UP_FAILURE,
  DELETE_BRANCH_LOOK_UP_REQUEST,
  DELETE_BRANCH_LOOK_UP_SUCCESS,
  FETCH_BRANCH_LOOK_UPS_FAILURE,
  FETCH_BRANCH_LOOK_UPS_REQUEST,
  FETCH_BRANCH_LOOK_UPS_SUCCESS,
  CREATE_BRANCH_LOOK_UP_FAILURE,
  CREATE_BRANCH_LOOK_UP_REQUEST,
  CREATE_BRANCH_LOOK_UP_SUCCESS,
  SHOW_BRANCH_LOOK_UP_EDIT_FORM,
  SHOW_BRANCH_LOOK_UP_NEW_FORM,
  UPDATE_BRANCH_LOOK_UP_FAILURE,
  UPDATE_BRANCH_LOOK_UP_REQUEST,
  UPDATE_BRANCH_LOOK_UP_SUCCESS,
  SET_BRANCH_LOOK_UP_VALIDATION_ERRORS,
} from "constants/BranchLookUp";

export const fetchBranchLookUpsRequest = () => ({
  type: FETCH_BRANCH_LOOK_UPS_REQUEST,
});

export const fetchBranchLookUpsSuccess = (branchLookUps) => ({
  type: FETCH_BRANCH_LOOK_UPS_SUCCESS,
  payload: branchLookUps,
});

export const fetchBranchLookUpsFailure = (error) => ({
  type: FETCH_BRANCH_LOOK_UPS_FAILURE,
  payload: error,
});

export const setBranchLookUpValidationErrors = (validationErrors) => ({
  type: SET_BRANCH_LOOK_UP_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleBranchLookUpEditForm = () => ({
  type: SHOW_BRANCH_LOOK_UP_EDIT_FORM,
});

export const toggleBranchLookUpNewForm = () => ({
  type: SHOW_BRANCH_LOOK_UP_NEW_FORM,
});

export const updateBranchLookUpRequest = (branchLookUp) => ({
  type: UPDATE_BRANCH_LOOK_UP_REQUEST,
  payload: branchLookUp,
});

export const updateBranchLookUpSuccess = (branchLookUp) => ({
  type: UPDATE_BRANCH_LOOK_UP_SUCCESS,
  payload: branchLookUp,
});

export const updateBranchLookUpFailure = (error) => ({
  type: UPDATE_BRANCH_LOOK_UP_FAILURE,
  payload: error,
});

export const deleteBranchLookUpRequest = (id) => ({
  type: DELETE_BRANCH_LOOK_UP_REQUEST,
  payload: id,
});

export const deleteBranchLookUpSuccess = (branchLookUp) => ({
  type: DELETE_BRANCH_LOOK_UP_SUCCESS,
  payload: branchLookUp,
});

export const deleteBranchLookUpFailure = (error) => ({
  type: DELETE_BRANCH_LOOK_UP_FAILURE,
  payload: error,
});

export const createBranchLookUpRequest = (branchLookUp) => ({
  type: CREATE_BRANCH_LOOK_UP_REQUEST,
  payload: branchLookUp,
});

export const createBranchLookUpSuccess = (branchLookUp) => ({
  type: CREATE_BRANCH_LOOK_UP_SUCCESS,
  payload: branchLookUp,
});

export const createBranchLookUpFailure = (error) => ({
  type: CREATE_BRANCH_LOOK_UP_FAILURE,
  payload: error,
});
