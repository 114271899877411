import React from "react"
import { Result } from "antd"

const PageNotFoundPage = ({
  exceptionalPage
}) =>{
  if(exceptionalPage === '/admin/telegram_bot'){
    window.open('https://t.me/Icladdisbot', '_blank');
    window.history.back();
  } 
     return(
        <Result 
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
     )
}

export default PageNotFoundPage;