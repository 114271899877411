import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST,
  UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
} from "constants/ClientCompanyTestDirectory";
import {
  createClientCompanyTestDirectoryFailure,
  createClientCompanyTestDirectorySuccess,
  deleteClientCompanyTestDirectoryFailure,
  deleteClientCompanyTestDirectorySuccess,
  fetchClientCompanyTestDirectoriesFailure,
  fetchClientCompanyTestDirectoriesSuccess,
  setClientCompanyTestDirectoryValidationErrors,
  updateClientCompanyTestDirectoryFailure,
  updateClientCompanyTestDirectorySuccess,
} from "redux/actions";
import ClientCompanyTestDirectoryService from "services/ClientCompanyTestDirectory";

export function* onFetchClientCompanyTestDirectories() {
  yield takeLatest(FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST, function* () {
    try {
      const result = yield call(ClientCompanyTestDirectoryService.index);
      if (result.status) {
        yield put(fetchClientCompanyTestDirectoriesSuccess(result.data));
      } else {
        yield put(fetchClientCompanyTestDirectoriesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchClientCompanyTestDirectoriesFailure(error.message));
    }
  });
}

export function* onUpdateClientCompanyTestDirectory() {
  yield takeLatest(UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        ClientCompanyTestDirectoryService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateClientCompanyTestDirectorySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setClientCompanyTestDirectoryValidationErrors(result.data.errors));
        yield put(updateClientCompanyTestDirectoryFailure(result.message));
      } else {
        yield put(updateClientCompanyTestDirectoryFailure(result.message));
      }
    } catch (error) {
      yield put(updateClientCompanyTestDirectoryFailure(error.message));
    }
  });
}

export function* onDeleteClientCompanyTestDirectory() {
  yield takeLatest(DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(ClientCompanyTestDirectoryService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteClientCompanyTestDirectorySuccess(result.data));
      } else {
        yield put(deleteClientCompanyTestDirectoryFailure(result.message));
      }
    } catch (error) {
      yield put(deleteClientCompanyTestDirectoryFailure(error.message));
    }
  });
}

export function* onCreateClientCompanyTestDirectory() {
  yield takeLatest(CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(ClientCompanyTestDirectoryService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createClientCompanyTestDirectorySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setClientCompanyTestDirectoryValidationErrors(result.data.errors));
        yield put(createClientCompanyTestDirectoryFailure(result.message));
      } else {
        yield put(createClientCompanyTestDirectoryFailure(result.message));
      }
    } catch (error) {
      yield put(createClientCompanyTestDirectoryFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchClientCompanyTestDirectories),
    fork(onUpdateClientCompanyTestDirectory),
    fork(onDeleteClientCompanyTestDirectory),
    fork(onCreateClientCompanyTestDirectory),
  ]);
}
