import { message } from "antd";
import { env } from "configs/EnvironmentConfig";
import { doesThisUserIsCashier, doesThisUserIsSrd } from "utils/CurrentUserUtil";

export const openPdf =(sampleId, shouldOpenPdf, forCashOrForCredit)=> {
    if(shouldOpenPdf){
        if(doesThisUserIsCashier() || doesThisUserIsSrd()){
            if(forCashOrForCredit === "forCash"){
                let url = env.PDF_API_BASE_URL + "/generatePatientCashPdf?webSampleId=" +  sampleId;
                // let url = 'https://backendtest.iclpartner.com/api/generatePatientCashPdf/22065050892';
                let win = window.open(url, '_blank');
                win.focus();
            } else {
                let url = env.PDF_API_BASE_URL + "/generatePatientCreditPdf?webSampleId=" +  sampleId;
                // let url = 'https://backendtest.iclpartner.com/api/generatePatientCashPdf/22065050892';
                let win = window.open(url, '_blank');
                win.focus();
            }
        }
        else {
            let url = env.PDF_API_BASE_URL + "/order/pdf/" +  sampleId;
            let win = window.open(url, '_blank');
            win.focus();
        }
    }
    else {
        message.success("successfully ordered");
    }
}
