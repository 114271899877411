import {
  FETCH_SAMPLE_ACCEPTED_REQUEST,
  FETCH_SAMPLE_ACCEPTED_SUCCESS,
  FETCH_SAMPLE_ACCEPTED_FAILURE,
} from "../../constants/SampleAccepted";

export const fetchSampleAccpetedRequest = (
  startDate,
  endDate,
  agency,
  department
) => ({
  type: FETCH_SAMPLE_ACCEPTED_REQUEST,
  payload: {
    startDate,
    endDate,
    agency,
    department,
  },
});

export const fetchSampleAccpetedSucess = (payload) => {
  return {
    type: FETCH_SAMPLE_ACCEPTED_SUCCESS,
    payload: payload,
  };
};

export const fetchSampleAccpetedFailure = (payload) => {
  return {
    type: FETCH_SAMPLE_ACCEPTED_FAILURE,
    payload: payload,
  };
};
