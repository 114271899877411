import fetch from "auth/FetchInterceptor";

const PatientService = {};

PatientService.index = function () {
  return fetch({
    url: "/patients",
    method: "get",
  });
};

PatientService.create = function (data) {
  return fetch({
    url: "/patients",
    method: "post",
    data,
  });
};

PatientService.update = function (id, data) {
  
  return fetch({
    url: `/patients/${id}`,
    method: "put",
    data,
  });
};

PatientService.delete = function (data) {
  return fetch({
    url: `/patients/${data.webSampleId}`,
    method: "delete",
    data
  });
};

export default PatientService;