import {
  DELETE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS_FAILURE,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  SHOW_DEPARTMENT_EDIT_FORM,
  SHOW_DEPARTMENT_NEW_FORM,
  UPDATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  SET_DEPARTMENT_VALIDATION_ERRORS,
} from "constants/Department";

export const fetchDepartmentsRequest = () => ({
  type: FETCH_DEPARTMENTS_REQUEST,
});

export const fetchDepartmentsSuccess = (departments) => ({
  type: FETCH_DEPARTMENTS_SUCCESS,
  payload: departments,
});

export const fetchDepartmentsFailure = (error) => ({
  type: FETCH_DEPARTMENTS_FAILURE,
  payload: error,
});

export const setDepartmentValidationErrors = (validationErrors) => ({
  type: SET_DEPARTMENT_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleDepartmentEditForm = () => ({
  type: SHOW_DEPARTMENT_EDIT_FORM,
});

export const toggleDepartmentNewForm = () => ({
  type: SHOW_DEPARTMENT_NEW_FORM,
});

export const updateDepartmentRequest = (department) => ({
  type: UPDATE_DEPARTMENT_REQUEST,
  payload: department,
});

export const updateDepartmentSuccess = (department) => ({
  type: UPDATE_DEPARTMENT_SUCCESS,
  payload: department,
});

export const updateDepartmentFailure = (error) => ({
  type: UPDATE_DEPARTMENT_FAILURE,
  payload: error,
});

export const deleteDepartmentRequest = (id) => ({
  type: DELETE_DEPARTMENT_REQUEST,
  payload: id,
});

export const deleteDepartmentSuccess = (department) => ({
  type: DELETE_DEPARTMENT_SUCCESS,
  payload: department,
});

export const deleteDepartmentFailure = (error) => ({
  type: DELETE_DEPARTMENT_FAILURE,
  payload: error,
});

export const createDepartmentRequest = (department) => ({
  type: CREATE_DEPARTMENT_REQUEST,
  payload: department,
});

export const createDepartmentSuccess = (department) => ({
  type: CREATE_DEPARTMENT_SUCCESS,
  payload: department,
});

export const createDepartmentFailure = (error) => ({
  type: CREATE_DEPARTMENT_FAILURE,
  payload: error,
});
