import fetch from "auth/FetchInterceptor";

const DiscontinuedTestDirectoryService = {};

DiscontinuedTestDirectoryService.index = function () {
  return fetch({
    url: "/getPanels",
    method: "get",
  });
};

export default DiscontinuedTestDirectoryService;
