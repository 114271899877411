import{
    FETCH_FINANCIAL_REPORTS_REQUEST,
    FETCH_FINANCIAL_REPORTS_FAILURE,
    FETCH_FINANCIAL_REPORTS_SUCCESS
} from "constants/FinancialReport"

export const onUploadFileRequest = (formData, uploadInformation) =>{
    return {
       type : FETCH_FINANCIAL_REPORTS_REQUEST,
       payload : {
        formData,
        uploadInformation
      }
    }
}


export const onUploadFileSuccess = (payload) =>{
   return {
      type : FETCH_FINANCIAL_REPORTS_SUCCESS,
      payload : payload
   }
}


export const onUploadFileFailure = (errorMessage) =>{
   return {
      type : FETCH_FINANCIAL_REPORTS_FAILURE,
      paylaod : errorMessage
   }
}