export const SHOW_SECTION_NEW_FORM = "SHOW_SECTION_NEW_FORM";
export const SHOW_SECTION_EDIT_FORM = "SHOW_SECTION_EDIT_FORM";
export const SET_SECTION_VALIDATION_ERRORS = "SET_SECTION_VALIDATION_ERRORS";

export const FETCH_SECTIONS_REQUEST = "FETCH_SECTIONS_REQUEST";
export const FETCH_SECTIONS_SUCCESS = "FETCH_SECTIONS_SUCCESS";
export const FETCH_SECTIONS_FAILURE = "FETCH_SECTIONS_FAILURE";

export const UPDATE_SECTION_REQUEST = "UPDATE_SECTION_REQUEST";
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS";
export const UPDATE_SECTION_FAILURE = "UPDATE_SECTION_FAILURE";

export const DELETE_SECTION_REQUEST = "DELETE_SECTION_REQUEST";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";
export const DELETE_SECTION_FAILURE = "DELETE_SECTION_FAILURE";

export const CREATE_SECTION_REQUEST = "CREATE_SECTION_REQUEST";
export const CREATE_SECTION_SUCCESS = "CREATE_SECTION_SUCCESS";
export const CREATE_SECTION_FAILURE = "CREATE_SECTION_FAILURE";
