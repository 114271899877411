import fetch from "auth/FetchInterceptor";

const TatApproaching = {};

TatApproaching.get = function (payload) {
  return fetch({
    url:
      "/reports/tat-approaching-results?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" + payload.department
  });
};

export default TatApproaching;