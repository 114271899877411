export const SET_BRANCH_LOOK_UP_VALIDATION_ERRORS = "SET_BRANCH_LOOK_UP_VALIDATION_ERRORS";
export const SHOW_BRANCH_LOOK_UP_EDIT_FORM = "SHOW_BRANCH_LOOK_UP_EDIT_FORM";
export const SHOW_BRANCH_LOOK_UP_NEW_FORM = "SHOW_BRANCH_LOOK_UP_NEW_FORM";


export const FETCH_BRANCH_LOOK_UPS_REQUEST = "FETCH_BRANCH_LOOK_UPS_REQUEST";
export const FETCH_BRANCH_LOOK_UPS_SUCCESS = "FETCH_BRANCH_LOOK_UPS_SUCCESS";
export const FETCH_BRANCH_LOOK_UPS_FAILURE = "FETCH_BRANCH_LOOK_UPS_FAILURE";

export const UPDATE_BRANCH_LOOK_UP_REQUEST = "UPDATE_BRANCH_LOOK_UP_REQUEST";
export const UPDATE_BRANCH_LOOK_UP_SUCCESS = "UPDATE_BRANCH_LOOK_UP_SUCCESS";
export const UPDATE_BRANCH_LOOK_UP_FAILURE = "UPDATE_BRANCH_LOOK_UP_FAILURE";

export const DELETE_BRANCH_LOOK_UP_REQUEST = "DELETE_BRANCH_LOOK_UP_REQUEST";
export const DELETE_BRANCH_LOOK_UP_SUCCESS = "DELETE_BRANCH_LOOK_UP_SUCCESS";
export const DELETE_BRANCH_LOOK_UP_FAILURE = "DELETE_BRANCH_LOOK_UP_FAILURE";

export const CREATE_BRANCH_LOOK_UP_REQUEST = "CREATE_BRANCH_LOOK_UP_REQUEST";
export const CREATE_BRANCH_LOOK_UP_SUCCESS = "CREATE_BRANCH_LOOK_UP_SUCCESS";
export const CREATE_BRANCH_LOOK_UP_FAILURE = "CREATE_BRANCH_LOOK_UP_FAILURE";
