export const SHOW_PATIENTRESULT_NEW_FORM = "SHOW_PATIENTRESULT_NEW_FORM";
export const SHOW_PATIENTRESULT_EDIT_FORM = "SHOW_PATIENTRESULT_EDIT_FORM";
export const SET_PATIENTRESULT_VALIDATION_ERRORS = "SET_PATIENTRESULT_VALIDATION_ERRORS";

export const FETCH_PATIENTRESULTS_REQUEST = "FETCH_PATIENTRESULTS_REQUEST";
export const FETCH_PATIENTRESULTS_SUCCESS = "FETCH_PATIENTRESULTS_SUCCESS";
export const FETCH_PATIENTRESULTS_FAILURE = "FETCH_PATIENTRESULTS_FAILURE";

export const FETCH_PATIENT_HISTORY_REQUEST = "FETCH_PATIENT_HISTORY_REQUEST";
export const FETCH_PATIENT_HISTORY_SUCCESS = "FETCH_PATIENT_HISTORY_SUCCESS";
export const FETCH_PATIENT_HISTORY_FAILURE = "FETCH_PATIENT_HISTORY_FAILURE";

export const FETCH_DETAILED_PATIENT_RESULT_REQUEST = "FETCH_DETAILED_PATIENT_RESULT_REQUEST";
export const FETCH_DETAILED_PATIENT_RESULT_SUCCESS = "FETCH_DETAILED_PATIENT_RESULT_SUCCESS";
export const FETCH_DETAILED_PATIENT_RESULT_FAILURE = "FETCH_DETAILED_PATIENT_RESULT_FAILURE";

export const UPDATE_PATIENTRESULT_REQUEST = "UPDATE_PATIENTRESULT_REQUEST";
export const UPDATE_PATIENTRESULT_SUCCESS = "UPDATE_PATIENTRESULT_SUCCESS";
export const UPDATE_PATIENTRESULT_FAILURE = "UPDATE_PATIENTRESULT_FAILURE";

export const DELETE_PATIENTRESULT_REQUEST = "DELETE_PATIENTRESULT_REQUEST";
export const DELETE_PATIENTRESULT_SUCCESS = "DELETE_PATIENTRESULT_SUCCESS";
export const DELETE_PATIENTRESULT_FAILURE = "DELETE_PATIENTRESULT_FAILURE";

export const CREATE_PATIENTRESULT_REQUEST = "CREATE_PATIENTRESULT_REQUEST";
export const CREATE_PATIENTRESULT_SUCCESS = "CREATE_PATIENTRESULT_SUCCESS";
export const CREATE_PATIENTRESULT_FAILURE = "CREATE_PATIENTRESULT_FAILURE";
