import {
  DELETE_TEST_REGISTRATION_FAILURE,
  DELETE_TEST_REGISTRATION_REQUEST,
  DELETE_TEST_REGISTRATION_SUCCESS,
  FETCH_TEST_REGISTRATIONS_FAILURE,
  FETCH_TEST_REGISTRATIONS_REQUEST,
  FETCH_TEST_REGISTRATIONS_SUCCESS,
  CREATE_TEST_REGISTRATION_FAILURE,
  CREATE_TEST_REGISTRATION_REQUEST,
  CREATE_TEST_REGISTRATION_SUCCESS,
  SHOW_TEST_REGISTRATION_EDIT_FORM,
  SHOW_TEST_REGISTRATION_NEW_FORM,
  UPDATE_TEST_REGISTRATION_FAILURE,
  UPDATE_TEST_REGISTRATION_REQUEST,
  UPDATE_TEST_REGISTRATION_SUCCESS,
  SET_TEST_REGISTRATION_VALIDATION_ERRORS,
} from "constants/TestRegistration";

export const fetchTestRegistrationsRequest = () => ({
  type: FETCH_TEST_REGISTRATIONS_REQUEST,
});

export const fetchTestRegistrationsSuccess = (testRegistrations) => ({
  type: FETCH_TEST_REGISTRATIONS_SUCCESS,
  payload: testRegistrations,
});

export const fetchTestRegistrationsFailure = (error) => ({
  type: FETCH_TEST_REGISTRATIONS_FAILURE,
  payload: error,
});

export const setTestRegistrationValidationErrors = (validationErrors) => ({
  type: SET_TEST_REGISTRATION_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleTestRegistrationEditForm = () => ({
  type: SHOW_TEST_REGISTRATION_EDIT_FORM,
});

export const toggleTestRegistrationNewForm = () => ({
  type: SHOW_TEST_REGISTRATION_NEW_FORM,
});

export const updateTestRegistrationRequest = (testRegistration) => ({
  type: UPDATE_TEST_REGISTRATION_REQUEST,
  payload: testRegistration,
});

export const updateTestRegistrationSuccess = (testRegistration) => ({
  type: UPDATE_TEST_REGISTRATION_SUCCESS,
  payload: testRegistration,
});

export const updateTestRegistrationFailure = (error) => ({
  type: UPDATE_TEST_REGISTRATION_FAILURE,
  payload: error,
});

export const deleteTestRegistrationRequest = (id) => ({
  type: DELETE_TEST_REGISTRATION_REQUEST,
  payload: id,
});

export const deleteTestRegistrationSuccess = (testRegistration) => ({
  type: DELETE_TEST_REGISTRATION_SUCCESS,
  payload: testRegistration,
});

export const deleteTestRegistrationFailure = (error) => ({
  type: DELETE_TEST_REGISTRATION_FAILURE,
  payload: error,
});

export const createTestRegistrationRequest = (testRegistration) => ({
  type: CREATE_TEST_REGISTRATION_REQUEST,
  payload: testRegistration,
});

export const createTestRegistrationSuccess = (testRegistration) => ({
  type: CREATE_TEST_REGISTRATION_SUCCESS,
  payload: testRegistration,
});

export const createTestRegistrationFailure = (error) => ({
  type: CREATE_TEST_REGISTRATION_FAILURE,
  payload: error,
});
