import { all, call, fork, put, takeLatest, delay } from "@redux-saga/core/effects";
import { PENDING_RESULT_REFRESH_TIME } from "configs/AppConfig";
import { FETCH_PATHOLOGY_RESULT_REQUEST } from "constants/Pathology";
import { fetchPathologyResultFailure, fetchPathologyResultRequest, fetchPathologyResultSuccess} from "redux/actions";
import PathologyResultsService from "services/Pathology";
export function* onFetchPathologyResults() {
  yield takeLatest(FETCH_PATHOLOGY_RESULT_REQUEST, function* (payload) {
    try {
      const result = yield call(PathologyResultsService.get, payload.payload);
      if (result.status) {
        yield put(fetchPathologyResultSuccess(result.data));
        yield delay(PENDING_RESULT_REFRESH_TIME);
        yield put(fetchPathologyResultRequest());
      } else {
        yield put(fetchPathologyResultFailure(result.message));
        yield delay(PENDING_RESULT_REFRESH_TIME);
        yield put(fetchPathologyResultRequest());
      }
    } catch (error) {
      yield put(fetchPathologyResultFailure(error.message));
      yield delay(PENDING_RESULT_REFRESH_TIME);
      yield put(fetchPathologyResultRequest());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchPathologyResults)]);
}
