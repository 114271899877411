import {
  FETCH_NOTYETRELEASED_SUCCESS,
  FETCH_NOTYETRELEASED_REQUEST,
  FETCH_NOTYETRELEASED_FAILURE,
} from "constants/Notyetreleased";


const initState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const notyetreleaseds = (state = initState, action) => {
  switch (action.type) {
    case FETCH_NOTYETRELEASED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTYETRELEASED_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_NOTYETRELEASED_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

   
    default:
      return state;
  }
};

export default notyetreleaseds;
