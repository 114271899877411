import { all, call, fork, put, takeLatest,delay } from "@redux-saga/core/effects";
import { PENDING_RESULT_REFRESH_TIME } from "configs/AppConfig";
import { FETCH_CORELAB_RESULTS_REQUESET } from "constants/CoreLab";
import { fetchCoreLabResultsFailure, fetchCoreLabResultsRequests, fetchCoreLabResultsSuccess } from "redux/actions";
import CoreLabResultsServices from "services/CoreLab";
export function* onFetchCoreLabResults() {
  yield takeLatest(FETCH_CORELAB_RESULTS_REQUESET, function* (payload) {
    try {
      const result = yield call(CoreLabResultsServices.get, payload.payload);
      // const { result, timeout } = yield race({
      //   result: call(CoreLabResultsServices.get, payload.payload),
      //   timeout: delay(500000), // Adjust the timeout value (in milliseconds) as needed
      // });

     if (result.status) {
        yield put(fetchCoreLabResultsSuccess(result.data));
        yield delay(PENDING_RESULT_REFRESH_TIME);
        yield put(fetchCoreLabResultsRequests());
      } else {
        yield put(fetchCoreLabResultsFailure(result.message));
        yield delay(PENDING_RESULT_REFRESH_TIME);
        yield put(fetchCoreLabResultsRequests());
      }
    } catch (error) {
      yield put(fetchCoreLabResultsFailure(error.message));
      yield delay(PENDING_RESULT_REFRESH_TIME);
      yield put(fetchCoreLabResultsRequests());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchCoreLabResults)]);
}
