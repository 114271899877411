import {
    FETCH_ALL_NOTIFICATION_FAILURE,
    FETCH_ALL_NOTIFICATIONS_REQUEST,
    FETCH_ALL_NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICAITON_FAILURE,
    UPDATE_NOTIFICAITON_SUCCESS,
    UPDATE_NOTIFICAITON_REQUEST,
    SHOULD_SHOW_AND_ALERT_NOTIFICATION
} from "constants/Notifications"

  
  const initState = {
    loading: false,
    cart: [],
    errorMessage : "",
    shouldShowAndAlertNotification : true
};
  
  const Notifications = (state = initState, action) => {
    switch (action.type) {
      case FETCH_ALL_NOTIFICATIONS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ALL_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          cart: action.payload,
        };
      case FETCH_ALL_NOTIFICATION_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
  
      case UPDATE_NOTIFICAITON_REQUEST:
        return {
          ...state,
        };
      case UPDATE_NOTIFICAITON_SUCCESS:
        return {
          ...state,
          cart : action.payload
        };      
      case UPDATE_NOTIFICAITON_FAILURE:
        return {
          ...state,
          errorMessage : action.payload
        };
        case SHOULD_SHOW_AND_ALERT_NOTIFICATION:
          return {
            ...state,
            shouldShowAndAlertNotification : action.payload
          };
      default:
        return state;
    }
  };
  
  export default Notifications;
  