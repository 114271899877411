import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import moment from "moment";
import { initializeTheMomentStartOfWeek } from 'utils/DateTimeUtil'
import { English } from "lang/locales_latest/en_US";
import { CURRENT_USER } from "redux/constants/Auth";
import { doesThisUserIsSrd } from "utils/CurrentUserUtil";

export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const QC_PREFIX_PATH = "/qc";
export const DOCTOR_PREFIX_PATH = "/doctor";
export const AUTH_PREFIX_PATH = "/auth";
export const PASSWORD_RESET_PATH = "/reset";
export const ADMIN_PREFIX_PATH = "/admin";
export const UPDATE_ACTION = "update";
export const NEW_ACTION = "new";
export const ROLE_SUPER_ADMIN = "super-admin";
export const ROLE_GUEST = "guest";
export const SRD_ROLE = "srd";
export const ROLE_QUALITY_CONTROL = "qc-admin";
export const ROLE_DOCTOR = "agency-doctor";
export const APP_VERSION = 1.1;
export const SCATTER_GRAPH = "scatter";
export const LINE_GRAPH = "line";
export const BAR_GRAPH = "bar";
export const MAX_GRAPH_TESTS = 10;
export const PENDING_RESULT_REFRESH_TIME = 600000;
export const USER_CONGRATULATED = "USER_CONGRATULATED";

export const ICL_TELEGRAM_CONTACT = "https://t.me/ICLCustomerSupport";
export const ICL_TELEGRAM_BOT = "https://t.me/Icladdisbot";
export const FIRESTORE_DOCUMENT_REFERENCE_NAME = "chats";
export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  language : JSON.parse(localStorage.getItem("language")) ?? English,
  currentUserImagePath : JSON.parse(localStorage.getItem(CURRENT_USER)) ? JSON.parse(localStorage.getItem(CURRENT_USER))['img_path'] : "",
  languageChanged : false,
  currentSelectedLanguage : localStorage.getItem("dropdownDefaultLanguageValue") ?? "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: (localStorage.getItem("darkMode") === null || localStorage.getItem("darkMode") === "light") ? "light" : localStorage.getItem("darkMode"),
  direction: DIR_LTR,
};
initializeTheMomentStartOfWeek();
export const INITIAL_DATE_TIME_AND_AGENCY_DEPARTMENT = {
  // "startDate": moment().subtract(1, 'days').format("YYYYMMDD") + "000000",
  // "endDate": moment().format("YYYYMMDD") + "999999",
  "startDate": moment().subtract(1, 'days').format("YYYYMMDD").toString() + "000000",
  "endDate": moment().format("YYYYMMDD").toString() + "999999",
  "agency": null,
  "department": null,
  "cashiercode": null,
  "cashorcredit": "cash",
}

export const INITIAL_DATE_TIME_FOR_DOCTOR = {
  // "startDate": moment().subtract(1, 'days').format("YYYYMMDD") + "000000",
  // "endDate": moment().format("YYYYMMDD") + "999999",
  "startDate": moment().subtract(1, 'days').format("YYYYMMDD").toString() + "000000",
  "endDate": moment().format("YYYYMMDD").toString() + "999999",
  "agency": null,
  "department": null,
  "cashiercode": null,
  "cashorcredit": "cash",
}

export const DAILY_RELEASED_TESTS_DATE = {
  "startDate": moment().format("YYYYMMDD").toString() + "000000",
  "endDate": moment().format("YYYYMMDD").toString() + "999999",
  "agency": null,
  "department": null,
}

export const DSS_INITIAL_DATE = {
  "startDate": moment().format("YYYYMMDD").toString() + "000000",
  "endDate": moment().format("YYYYMMDD").toString() + "999999",
  "agency": null,
  "department": null,
  "cashiercode": null,
  "cashorcredit": doesThisUserIsSrd() ? "credit" :  "cash",
}

export const COVID_FAMILIES_TESTS = ["COVTR"];
export const PATHOLOGY_DEPARTMENT_TEST=["PATHOLOGY"];
export const HISTOLOGY_TEST=["HISTOLOGY"];
export const CYTOLOGY_TEST=["CYTOLOGY"];
export const WELLNESS=["WELLNESS"];
export const COMMUNICATION_MEDIA_TYPE  = ["Phone", "SMS", "Email", "Whatsapp"];
export const LAB_REASONS = [{name : "Refund", value : "Refund"}, {name : "QNS", value : "QNS"}, {name : "Sample not collected", value : "Sample not collected"},{name : "Other", value : "Other"}]
export const USER_TYPES = [{value : "phlebotomy", name : "Phlebotomy"},{value : "cashier", name : "Cashier"},{value : "srd", name : "Srd"},{value : "lab", name : "Lab"},{value : "finance", name : "Finance"},{value : "doctor", name : "Doctor"},{value : "admin", name : "Admin"},{value : "agencyclientuser", name : "Agency Client User"}]
export const NOTIFICATION_KEY = "NOTIFICATION_KEY";
export const ResourcesTypes = [
  {
    key : 'paper',
    label : "Paper"
  },
  {
    key: 'TRF',
    label: " TRF"
  },
  {
    key: 'SST',
    label: "SST"
  },
  {
    key: 'VTM',
    label: "VTM"
  },
  {
    key: 'Container',
    label: "Container"
  },
  {
    key: 'Lavender',
    label: "Lavender"
  },
  {
    key: 'Sodium citrate tube',
    label: "Sodium citrate tube"
  },
  {
    key: 'screw-cap',
    label: "screw-cap"
  },
  
  

];
//the below expiration times are in hour
export const TOKEN_EXPIRATION_TIME = 6;
export const LOGIS_TOKEN_EXPIRATION_IN_HOUR = 2;

export const UNPROCESSABLE_ENTITY_CODE = 422;
export const PERMISSION_DENIED_CODE = 403;