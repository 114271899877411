import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_STAT_ORDERS_REQUEST,
  UPDATE_STAT_ORDER_REQUEST,
  FETCH_STAT_ORDER_OBX_REQUEST,
} from "constants/StatOrders";
import {
  fetchStatOrdersFailure,
  fetchStatOrdersSuccess,
  updateStatOrdersFailure,
  updateStatOrdersSuccess,
  fetchStatOrdersObxFailure,
  fetchStatOrdersObxSuccess,
} from "redux/actions/StatOrders";

import StatOrders from "services/StatOrders";

export function* onFetchStatOrder() {
  yield takeLatest(FETCH_STAT_ORDERS_REQUEST, function* (payload) {
    try {
      const result = yield call(StatOrders.get, payload.payload);
      if (result.status) {
        yield put(fetchStatOrdersSuccess(result.data));
      } else {
        yield put(fetchStatOrdersFailure(result.message));
      }
    } catch (error) {
      yield put(fetchStatOrdersFailure(error.message));
    }
  });
}

export function* onFetchStatOrderObx() {
  yield takeLatest(FETCH_STAT_ORDER_OBX_REQUEST, function* (payload) {
    try {
      const result = yield call(StatOrders.getObx, payload.payload.visitUuid);
      if (result.status) {
        yield put(fetchStatOrdersObxSuccess(result.data));
      } else {
        yield put(fetchStatOrdersObxFailure(result.message));
      }
    } catch (error) {
      yield put(fetchStatOrdersObxFailure(error.message));
    }
  });
}

export function* onUpdateStatOrder() {
  yield takeLatest(UPDATE_STAT_ORDER_REQUEST, function* (payload) {
    try {
      const result = yield call(StatOrders.update, payload.payload);
      if (result.status) {
        message.success("Order Status Updated");
        yield put(updateStatOrdersSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        // yield put(setDiscrepancyValidationErrors(result.data.errors));
        yield put(updateStatOrdersFailure(result.message));
      } else {
        yield put(updateStatOrdersFailure, result.message);
      }
    } catch (error) {
      message.error("Check your inputs please");
      yield put(updateStatOrdersFailure, error.message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchStatOrder),
    fork(onUpdateStatOrder),
    fork(onFetchStatOrderObx),
  ]);
}
