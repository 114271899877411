import {
    FETCH_PICKUP_REQUEST,
    FETCH_PICKUP_REQUEST_FAILURE,
    FETCH_PICKUP_REQUSET_SUCCESS
} from "constants/PickUpRequest"

const initState = {
    list : [],
    loading : false,
    errorMessage : ""
}

const PickUpRequest = (state = initState, action) =>{
   switch(action.type)
   {
      case FETCH_PICKUP_REQUEST:
       return {
           ...state,
           loading : true
       } 
      case FETCH_PICKUP_REQUSET_SUCCESS : 
       return {
            ...state, 
            loading : false,
            list : action.payload
               }
       case FETCH_PICKUP_REQUEST_FAILURE :
       return {
           ...state,
           loading : false,
           errorMessage : action.payload
       }
       default : 
           return state
   }
}

export default PickUpRequest;