import fetch from 'auth/FetchInterceptor'

const AppointmentService = {}

AppointmentService.getMetadata = function () {
  return fetch({
    url: '/appointments/metadata',
    method: 'get',
  })
}

AppointmentService.saveTestRegistration = function (data) {
  return fetch({
    url: '/appointments/save_registrations',
    method: 'post',
    headers: {
      'public-request': 'true'
    },
		data: data
  })
}

export default AppointmentService