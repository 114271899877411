import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_REQUEST
} from "../../constants/TopBranchesForWalkInCustomer"
import { fetchTopBranchForWalkinCustomersFailure, fetchTopBranchForWalkinCustomersSuccess, setTopBranchForWalkinCustomersValidationError } from "redux/actions/TopBranchForWalkinCustomer";
import TopBranchesForWalkInCustomersService from "../../services/TopBranchForWalkinCutomersService";

export function* onFetchTopTestsRequest() {
    yield takeLatest(TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_REQUEST, function* ({payload}) {
      try {
        const result = yield call(TopBranchesForWalkInCustomersService.get, payload);
        console.log("%j", result);
        if (result.status){
          yield put(fetchTopBranchForWalkinCustomersSuccess(result.data));
        }
        else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
            yield put(setTopBranchForWalkinCustomersValidationError(result.data.errors));
        }
        else {
          yield put(fetchTopBranchForWalkinCustomersFailure(result.message));
        }
      } catch (error) {
        yield put(fetchTopBranchForWalkinCustomersFailure(error.message));
      }
    });
  }
  

  export default function* rootSaga() {
    yield all([
      fork(onFetchTopTestsRequest),
    ]);
  }