import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_MAINTENANCE_REQUEST,
  DELETE_MAINTENANCE_REQUEST,
  FETCH_MAINTENANCES_REQUEST,
} from "constants/Maintenance";
import {
  createMaintenanceFailure,
  createMaintenanceSuccess,
  deleteMaintenanceFailure,
  deleteMaintenanceSuccess,
  fetchMaintenancesFailure,
  fetchMaintenancesSuccess,
  setMaintenanceValidationErrors,
} from "redux/actions";
import MaintenanceService from "services/Maintenance";

export function* onFetchMaintenances() {
  yield takeLatest(FETCH_MAINTENANCES_REQUEST, function* () {
    try {
      const result = yield call(MaintenanceService.index);
      if (result.status) {
        yield put(fetchMaintenancesSuccess(result.data));
      } else {
        yield put(fetchMaintenancesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchMaintenancesFailure(error.message));
    }
  });
}

export function* onDeleteMaintenance() {
  yield takeLatest(DELETE_MAINTENANCE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MaintenanceService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteMaintenanceSuccess(result.data));
      } else {
        yield put(deleteMaintenanceFailure(result.message));
      }
    } catch (error) {
      yield put(deleteMaintenanceFailure(error.message));
    }
  });
}

export function* onCreateMaintenance() {
  yield takeLatest(CREATE_MAINTENANCE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MaintenanceService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createMaintenanceSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setMaintenanceValidationErrors(result.data.errors));
        yield put(createMaintenanceFailure(result.message));
      } else {
        yield put(createMaintenanceFailure(result.message));
      }
    } catch (error) {
      yield put(deleteMaintenanceFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchMaintenances),
    fork(onDeleteMaintenance),
    fork(onCreateMaintenance),
  ]);
}
