import {
  FETCH_RELEASEDS_SUCCESS,
  FETCH_RELEASEDS_REQUEST,
  FETCH_RELEASEDS_FAILURE,
} from "constants/Released";
import {changeDateToHumanReadableFormat} from "../../utils/DateTimeUtil"

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const released = (state = initState, action) => {
  switch (action.type) {
    case FETCH_RELEASEDS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_RELEASEDS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: changeDateToHumanReadableFormat(action.payload),
      };
    case FETCH_RELEASEDS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default released;
