import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";

import{
    FETCH_DOCTORS_LIST_REQUEST,
}from "constants/doctors_list";

import {
    fetchDoctorsListSuccess,
    fetchDoctorsListFailure,
  } from "redux/actions/DoctorsList";

  
// import SpecimenTracking from "services/SpecimenTracking";
import DoctorsList from "services/DoctorsList";

export function* onFetchDoctorsList() {
    yield takeLatest(FETCH_DOCTORS_LIST_REQUEST, function* (payload) {
      try {
        const result = yield call(DoctorsList.get);
        if (result.status) {
          // console.log("Fetched", result);
          yield put(fetchDoctorsListSuccess(result.data));
        } else {
          yield put(fetchDoctorsListFailure(result,message));
        }
      } catch (error) {
        yield put(fetchDoctorsListFailure(error.message));
      }
    });
  }

  export default function* rootSaga(){
    yield all([
      fork(onFetchDoctorsList)
    ]);
  }
  