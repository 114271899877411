import {
    FETCH_TATAPPROACHING_REQUEST, 
    FETCH_TATAPPROACHING_FAILURE,
    FETCH_TATAPPROACHING_SUCCESS
} from "constants/TATApproaching";

export const fetchTatApproachingRequest = (payload) =>({
    type : FETCH_TATAPPROACHING_REQUEST,
    payload : payload
});

export const fetchTatApproachingSuccess = (payload) =>({
    type : FETCH_TATAPPROACHING_SUCCESS,
    payload : payload
});

export const fetchTatApproachingFailure = (payload) => ({
    type : FETCH_TATAPPROACHING_FAILURE,
    payload : payload
});

