import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_DISCREPANCY_REQUEST,
  FETCH_DISCREPANCYOBX_REQUEST,
  UPDATE_DISCREPANCY_REQUEST,
} from "constants/Discrepancy";
import { updatePendingDiscrepancySuccess} from "redux/actions/DiscrepanciesStatus/DiscrepancyPending";
import {
  fetchDiscrepancyFailure,
  fetchDiscrepancySuccess,
  fetchDiscrepancyObxFailure,
  updateDiscrepancyFailure,
  updateDiscrepancySuccess,
  fetchDiscrepancyObxSuccess,
} from "redux/actions/Discrepancy";

import DiscrepancyService from "services/DiscrepancyService";

export function* onFetchDiscrepancy() {
  yield takeLatest(FETCH_DISCREPANCY_REQUEST, function* (payload) {
    try {
      const result = yield call(DiscrepancyService.index, payload.payload);
      if (result.status) {
        yield put(fetchDiscrepancySuccess(result.data));
      } else {
        yield put(fetchDiscrepancyFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDiscrepancyFailure(error.message));
    }
  });
}

export function* onUpdateDiscrepancy() {
  yield takeLatest(UPDATE_DISCREPANCY_REQUEST, function* ({ payload }) {
    let discrepancyData = [payload.discrepancyObx];
    try {
      const result = yield call(
        DiscrepancyService.update,
        discrepancyData.id,
        discrepancyData
      );
      if (result.status) {
        message.success("Discrepancy Status Changed");
        payload.discrepancyType === "all" ?  yield put(updateDiscrepancySuccess(result.data)) : yield put(updatePendingDiscrepancySuccess(result.data));

      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Please check your inputs");
        // yield put(setDiscrepancyValidationErrors(result.data.errors));
        message.error("Something went wrong");
         message.error("Something went wrong");
      } else {
        message.error("Something went wrong");
        yield put(updateDiscrepancyFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateDiscrepancyFailure(error.message));
    }
  });
}

export function* onFetchDiscrepancyObx() {
  yield takeLatest(FETCH_DISCREPANCYOBX_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(DiscrepancyService.Discrepancyobx, payload);
      if (result.status) {
        yield put(fetchDiscrepancyObxSuccess(result.data));
      } else {
        yield put(fetchDiscrepancyObxFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDiscrepancyObxFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchDiscrepancy),
    fork(onFetchDiscrepancyObx),
    fork(onUpdateDiscrepancy),
  ]);
}
