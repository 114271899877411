import {
    FETCH_DAILY_RELEASED_TESTS_REQUEST,
    FETCH_DAILY_RELEASED_TESTS_FAILURE,
    FETCH_DAILY_RELEASED_TESTS_SUCCESS
} from "constants/DailyReleasedTests"

const initState = {
     list : [],
     loading : false,
     errorMessage : ""
}

const DailyReleasedTests = (state = initState, action)=>{
    switch(action.type){
        case FETCH_DAILY_RELEASED_TESTS_REQUEST : 
        return{
            loading : true
        }
        case FETCH_DAILY_RELEASED_TESTS_SUCCESS : 
        return{
            loading : false,
            list : action.payload
        }
        case FETCH_DAILY_RELEASED_TESTS_FAILURE : 
        return {
            loading : false,
            errorMessage : action.errorMessage
        }
        default : 
        return state
    }
}

export default DailyReleasedTests;