export const FETCH_RESOURCE_REQUEST = "FETCH_RESOURCE_REQUEST";
export const FETCH_RESOURCE_REQUSET_SUCCESS = "FETCH_RESOURCE_REQUSET_SUCCESS";
export const FETCH_RESOURCE_REQUEST_FAILURE  = "FETCH_RESOURCE_REQUEST_FAILURE";

export const CREATE_RESOURCE_REQUEST = "CREATE_RESOURCE_REQUEST";
export const CREATE_RESOURCE_REQUSET_SUCCESS = "CREATE_RESOURCE_REQUSET_SUCCESS";
export const CREATE_RESOURCE_REQUEST_FAILURE  = "CREATE_RESOURCE_REQUEST_FAILURE";

export const UPDATE_RESOURCE_REQUEST = "UPDATE_RESOURCE_REQUEST";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_FAILURE = "UPDATE_RESOURCE_FAILURE";

export const UPDATE_RESOURCE_REQUEST_STATUS = "UPDATE_RESOURCE_REQUEST_STATUS";
export const UPDATE_RESOURCE_REQUEST_STATUS_SUCCESS = "UPDATE_RESOURCE_REQUEST_STATUS_SUCCESS";
export const UPDATE_RESOURCE_REQUEST_STATUS_FAILURE = "UPDATE_RESOURCE_REQUEST_STATUS_FAILURE";

export const DELETE_RESOURCE_REQUEST = "UPDATE_RESOURCE_REQUEST";
export const DELETE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_FAILURE = "UPDATE_RESOURCE_FAILURE";



