import {
  DELETE_CHECK_LIST_FAILURE,
  DELETE_CHECK_LIST_REQUEST,
  DELETE_CHECK_LIST_SUCCESS,
  FETCH_CHECK_LISTS_FAILURE,
  FETCH_CHECK_LISTS_REQUEST,
  FETCH_CHECK_LISTS_SUCCESS,
  CREATE_CHECK_LIST_FAILURE,
  CREATE_CHECK_LIST_REQUEST,
  CREATE_CHECK_LIST_SUCCESS,
  SHOW_CHECK_LIST_EDIT_FORM,
  SHOW_CHECK_LIST_NEW_FORM,
  UPDATE_CHECK_LIST_FAILURE,
  UPDATE_CHECK_LIST_REQUEST,
  UPDATE_CHECK_LIST_SUCCESS,
  SET_CHECK_LIST_VALIDATION_ERRORS,
} from "constants/CheckList";

export const fetchCheckListsRequest = () => ({
  type: FETCH_CHECK_LISTS_REQUEST,
});

export const fetchCheckListsSuccess = (checkLists) => ({
  type: FETCH_CHECK_LISTS_SUCCESS,
  payload: checkLists,
});

export const fetchCheckListsFailure = (error) => ({
  type: FETCH_CHECK_LISTS_FAILURE,
  payload: error,
});

export const setCheckListValidationErrors = (validationErrors) => ({
  type: SET_CHECK_LIST_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleCheckListEditForm = () => ({
  type: SHOW_CHECK_LIST_EDIT_FORM,
});

export const toggleCheckListNewForm = () => ({
  type: SHOW_CHECK_LIST_NEW_FORM,
});

export const updateCheckListRequest = (checkList) => ({
  type: UPDATE_CHECK_LIST_REQUEST,
  payload: checkList,
});

export const updateCheckListSuccess = (checkList) => ({
  type: UPDATE_CHECK_LIST_SUCCESS,
  payload: checkList,
});

export const updateCheckListFailure = (error) => ({
  type: UPDATE_CHECK_LIST_FAILURE,
  payload: error,
});

export const deleteCheckListRequest = (id) => ({
  type: DELETE_CHECK_LIST_REQUEST,
  payload: id,
});

export const deleteCheckListSuccess = (checkList) => ({
  type: DELETE_CHECK_LIST_SUCCESS,
  payload: checkList,
});

export const deleteCheckListFailure = (error) => ({
  type: DELETE_CHECK_LIST_FAILURE,
  payload: error,
});

export const createCheckListRequest = (checkList) => ({
  type: CREATE_CHECK_LIST_REQUEST,
  payload: checkList,
});

export const createCheckListSuccess = (checkList) => ({
  type: CREATE_CHECK_LIST_SUCCESS,
  payload: checkList,
});

export const createCheckListFailure = (error) => ({
  type: CREATE_CHECK_LIST_FAILURE,
  payload: error,
});
