import{
    FETCH_COMPLETED_RESULTS_REQUEST,
    FETCH_COMPLETED_RESULTS_FIALURE,
    FETCH_COMPLETED_RESULTS_SUCCESS
   } from "constants/CompletedResults"
   
   export const fetchCompletedResultRequest = (startDate, endDate, agency, department) =>{
       return {
           type : FETCH_COMPLETED_RESULTS_REQUEST,
           payload :
            { 
               startDate,
               endDate, 
               agency,
               department
           }
       }
   }
   
   export const fetchCompletedResultSuccess = (payload) =>{
        return {
            type : FETCH_COMPLETED_RESULTS_SUCCESS,
            payload : payload
        }  
   }
   
   export const fetchCompletedResultFailure = (payload) =>{
        return { 
           type : FETCH_COMPLETED_RESULTS_FIALURE, 
           payload : payload
        }
   }