import fetch from "auth/FetchInterceptor";

const DepartmentService = {};

DepartmentService.index = function () {
  return fetch({
    url: "/departments",
    method: "get",
  });
};

DepartmentService.create = function (data) {
  return fetch({
    url: "/departments",
    method: "post",
    data,
  });
};

DepartmentService.update = function (id, data) {
  return fetch({
    url: `/departments/${id}`,
    method: "put",
    data,
  });
};

DepartmentService.delete = function (id) {
  return fetch({
    url: `/departments/${id}`,
    method: "delete",
  });
};

export default DepartmentService;
