export const SHOW_BRANCH_TEST_CAPACITY_NEW_FORM = "SHOW_BRANCH_TEST_CAPACITY_NEW_FORM";
export const SHOW_BRANCH_TEST_CAPACITY_EDIT_FORM = "SHOW_BRANCH_TEST_CAPACITY_EDIT_FORM";
export const SET_BRANCH_TEST_CAPACITY_VALIDATION_ERRORS = "SET_BRANCH_TEST_CAPACITY_VALIDATION_ERRORS";

export const FETCH_BRANCH_TEST_CAPACITIES_REQUEST = "FETCH_BRANCH_TEST_CAPACITIES_REQUEST";
export const FETCH_BRANCH_TEST_CAPACITIES_SUCCESS = "FETCH_BRANCH_TEST_CAPACITIES_SUCCESS";
export const FETCH_BRANCH_TEST_CAPACITIES_FAILURE = "FETCH_BRANCH_TEST_CAPACITIES_FAILURE";

export const UPDATE_BRANCH_TEST_CAPACITY_REQUEST = "UPDATE_BRANCH_TEST_CAPACITY_REQUEST";
export const UPDATE_BRANCH_TEST_CAPACITY_SUCCESS = "UPDATE_BRANCH_TEST_CAPACITY_SUCCESS";
export const UPDATE_BRANCH_TEST_CAPACITY_FAILURE = "UPDATE_BRANCH_TEST_CAPACITY_FAILURE";


export const DELETE_BRANCH_TEST_CAPACITY_REQUEST = "DELETE_BRANCH_TEST_CAPACITY_REQUEST";
export const DELETE_BRANCH_TEST_CAPACITY_SUCCESS = "DELETE_BRANCH_TEST_CAPACITY_SUCCESS";
export const DELETE_BRANCH_TEST_CAPACITY_FAILURE = "DELETE_BRANCH_TEST_CAPACITY_FAILURE";

export const CREATE_BRANCH_TEST_CAPACITY_REQUEST = "CREATE_BRANCH_TEST_CAPACITY_REQUEST";
export const CREATE_BRANCH_TEST_CAPACITY_SUCCESS = "CREATE_BRANCH_TEST_CAPACITY_SUCCESS";
export const CREATE_BRANCH_TEST_CAPACITY_FAILURE = "CREATE_BRANCH_TEST_CAPACITY_FAILURE";

export const FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST = "FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST";
export const FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_SUCCESS = "FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_SUCCESS";
export const FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_FAILURE = "FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_FAILURE";
export const CLEAR_AVAILABLE_BRANCH_TEST_CAPACITIES = "CLEAR_AVAILABLE_BRANCH_TEST_CAPACITIES";

export const DAY_NAMES = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
