import fetch from "auth/FetchInterceptor";

const TopPanelsService = {};

TopPanelsService.get = function (payload) {
  return fetch({
    url:
      "/reports/toppanels?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

export default TopPanelsService;
