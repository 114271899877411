import _ from "lodash";

 export const updateOrderMadeByGuestUsers = (state, action) => {
    let list = state.list;
    const updatedOrderMadeByGuestUsers = action.payload
    const index = _.findIndex(list, ["id", updatedOrderMadeByGuestUsers.id]);
    list[index] = updatedOrderMadeByGuestUsers;
    
    return [...list];
   };
  
 export const removeOrderMadeByGuestUsers = (state, action) => {
    let list = state.list;
    const removedOrderMadeByGuestUsers = action.payload;
    const filteredList = _.filter(list, (td) => {
      return td.id !== removedOrderMadeByGuestUsers.id;
    });

    return [...filteredList];
   };

 export const formValidationErrors = (validationErrors) => {
    const keys = Object.keys(validationErrors);
    const errors = [];
    keys.forEach((key) => {
      errors.push({
        name: key,
        errors: validationErrors[key],
      });
    });
    return errors;
  };
  