export const SET_CLIENT_COMPANY_TEST_DIRECTORY_VALIDATION_ERRORS = "SET_CLIENT_COMPANY_TEST_DIRECTORY_VALIDATION_ERRORS";
export const SHOW_CLIENT_COMPANY_TEST_DIRECTORY_EDIT_FORM = "SHOW_CLIENT_COMPANY_TEST_DIRECTORY_EDIT_FORM";
export const SHOW_CLIENT_COMPANY_TEST_DIRECTORY_NEW_FORM = "SHOW_CLIENT_COMPANY_TEST_DIRECTORY_NEW_FORM";

export const FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST = "FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST";
export const FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_SUCCESS = "FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_SUCCESS";
export const FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_FAILURE = "FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_FAILURE";
            
export const UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST = "UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST";
export const UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS = "UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS";
export const UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE = "UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE";

export const DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST = "DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST";
export const DELETE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS = "DELETE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS";
export const DELETE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE = "DELETE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE";

export const CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST = "CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST";
export const CREATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS = "CREATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS";
export const CREATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE = "CREATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE";
