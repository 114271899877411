import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_MACHINE_REQUEST,
  DELETE_MACHINE_REQUEST,
  FETCH_MACHINES_REQUEST,
  UPDATE_MACHINE_REQUEST,
} from "constants/Machine";
import {
  createMachineFailure,
  createMachineSuccess,
  deleteMachineFailure,
  deleteMachineSuccess,
  fetchMachinesFailure,
  fetchMachinesSuccess,
  setMachineValidationErrors,
  updateMachineFailure,
  updateMachineSuccess,
} from "redux/actions";
import MachineService from "services/Machine";

export function* onFetchMachines() {
  yield takeLatest(FETCH_MACHINES_REQUEST, function* () {
    try {
      const result = yield call(MachineService.index);
      if (result.status) {
        yield put(fetchMachinesSuccess(result.data));
      } else {
        yield put(fetchMachinesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchMachinesFailure(error.message));
    }
  });
}

export function* onUpdateMachine() {
  yield takeLatest(UPDATE_MACHINE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        MachineService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateMachineSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setMachineValidationErrors(result.data.errors));
        yield put(updateMachineFailure(result.message));
      } else {
        yield put(updateMachineFailure(result.message));
      }
    } catch (error) {
      yield put(updateMachineFailure(error.message));
    }
  });
}

export function* onDeleteMachine() {
  yield takeLatest(DELETE_MACHINE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MachineService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteMachineSuccess(result.data));
      } else {
        yield put(deleteMachineFailure(result.message));
      }
    } catch (error) {
      yield put(deleteMachineFailure(error.message));
    }
  });
}

export function* onCreateMachine() {
  yield takeLatest(CREATE_MACHINE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MachineService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createMachineSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setMachineValidationErrors(result.data.errors));
        yield put(createMachineFailure(result.message));
      } else {
        yield put(createMachineFailure(result.message));
      }
    } catch (error) {
      yield put(deleteMachineFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchMachines),
    fork(onUpdateMachine),
    fork(onDeleteMachine),
    fork(onCreateMachine),
  ]);
}
