import {
  FETCH_MAINTENANCES_SUCCESS,
  FETCH_MAINTENANCES_REQUEST,
  FETCH_MAINTENANCES_FAILURE,
  DELETE_MAINTENANCE_REQUEST,
  DELETE_MAINTENANCE_SUCCESS,
  DELETE_MAINTENANCE_FAILURE,
  SET_MAINTENANCE_VALIDATION_ERRORS,
  SHOW_MAINTENANCE_NEW_FORM,
  CREATE_MAINTENANCE_REQUEST,
  CREATE_MAINTENANCE_SUCCESS,
  CREATE_MAINTENANCE_FAILURE,
} from "constants/Maintenance";

import {
  addMaintenance,
  formtValidationErrors,
  removeMaintenance,
} from "utils/Maintenance";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showNewForm: false,
  deleting: false,
};

const maintenance = (state = initState, action) => {
  switch (action.type) {
    case FETCH_MAINTENANCES_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_MAINTENANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_MAINTENANCES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_MAINTENANCE_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_MAINTENANCE_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };      
    case DELETE_MAINTENANCE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeMaintenance(state, action),
      };
    case DELETE_MAINTENANCE_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_MAINTENANCE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addMaintenance(state, action),
      };
    case CREATE_MAINTENANCE_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default maintenance;
