import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_FAULT_RESULT_REQUEST } from "constants/FaultResults";
import { 
    fetchFaultResultFailure,
    fetchFaultResultSuccess
} from "redux/actions/FaultResults"

import FaultResults from "services/FaultResults";

export function* onFetchFaultResults() {
  yield takeLatest(FETCH_FAULT_RESULT_REQUEST, function* (payload) {
    try {
      const result = yield call(FaultResults.get, payload.payload);
      if (result.status) {
        yield put(fetchFaultResultSuccess(result.data));
      } else {
        yield put(fetchFaultResultFailure(result.message));
      }
    } catch (error) {
      yield put(fetchFaultResultFailure(error.message));
    }
    // console.log('aaaa');
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchFaultResults)]);
}
