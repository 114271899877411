import {
    FETCH_DAILY_RELEASED_TESTS_REQUEST,
    FETCH_DAILY_RELEASED_TESTS_FAILURE,
    FETCH_DAILY_RELEASED_TESTS_SUCCESS
} from "constants/DailyReleasedTests"

export const fetchDailyReleasedTestsRequest = (startDate, endDate, agency, department) =>{
    return {
        type : FETCH_DAILY_RELEASED_TESTS_REQUEST,
        payload : {
            startDate,
            endDate,
            agency,
            department
        }
    }
}

export const fetchDailyReleasedTestsSuccess = (payload) =>{
    return {
        type : FETCH_DAILY_RELEASED_TESTS_SUCCESS,
        payload : payload
    }
}

export const fetchDailyReleasedTestsFailure = (errorMessage) =>{
    return {
        type : FETCH_DAILY_RELEASED_TESTS_FAILURE,
        payload : errorMessage
    }
}