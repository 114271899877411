import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { env } from "configs/EnvironmentConfig";
import { FORGOT_PASSWORD_REQUEST, SHOW_PASSWORD_RESET_FORM_REQUEST, UPDATE_PASSWORD_REQUEST } from "constants/ForgotPassword";
import { forgotPasswordFilure, forgotPasswordSuccess, passwordResetFilure, passwordResetSuccess } from "redux/actions/ForgotPassword";
import { PREVIOUSLY_LOGGED_IN } from "redux/constants/Auth";
import forgotPasswordService from "services/ForgotPassword";

export function* onPasswordForgotRequest() {
    yield takeLatest(FORGOT_PASSWORD_REQUEST, function* ({ payload }) {
        try {
            const result = yield call(forgotPasswordService.sendRequest, payload);
            if (result.status) {
                yield put(forgotPasswordSuccess(result.data));
                    message.success(result.data.message);
            } else {
                yield put(forgotPasswordFilure(result.data.message));
                    message.error(result.data.message);
            }
        } catch (error) {
            message.error("Server Error");
            yield put(forgotPasswordFilure(error.message));
        }
    });
}

export function* onPasswordResetFormRequest() {
    yield takeLatest(UPDATE_PASSWORD_REQUEST, function* ({payload}) {
        try {
            const result = yield call(forgotPasswordService.updatePassword, payload);
            if (result.status) {
                localStorage.setItem(PREVIOUSLY_LOGGED_IN, "1");
                yield put(passwordResetSuccess(result.data));
                // window.location.reload(true);
            } else {
                yield put(passwordResetFilure(result.message));
            }
        } catch (error) {
            message.error("Please try again");
            yield put(passwordResetFilure(error.message));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(onPasswordForgotRequest),
        fork(onPasswordResetFormRequest)
    ]);
}
