import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";

import {FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST} from "constants/DailyFinancialSummary";

import { fetchDailyFinancialSummaryFailure, 
    fetchDailyFinancialSummarySuccess } from "redux/actions/DailyFinancialSummary";

import DailyFinancialSummary from "services/DailyFinancialSummary";

export function* onFetchDailyFinancialSummary() {
  yield takeLatest(FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST, function* (payload) {
    try {

      const result = yield call(DailyFinancialSummary.get, payload.payload);
      if (result.status) {
        yield put(fetchDailyFinancialSummarySuccess(result.data));
      } else {
        yield put(fetchDailyFinancialSummaryFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDailyFinancialSummaryFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(onFetchDailyFinancialSummary),
  ]);
}
