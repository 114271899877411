import {
  DELETE_MACHINE_FAILURE,
  DELETE_MACHINE_REQUEST,
  DELETE_MACHINE_SUCCESS,
  FETCH_MACHINES_FAILURE,
  FETCH_MACHINES_REQUEST,
  FETCH_MACHINES_SUCCESS,
  CREATE_MACHINE_FAILURE,
  CREATE_MACHINE_REQUEST,
  CREATE_MACHINE_SUCCESS,
  SHOW_MACHINE_EDIT_FORM,
  SHOW_MACHINE_NEW_FORM,
  UPDATE_MACHINE_FAILURE,
  UPDATE_MACHINE_REQUEST,
  UPDATE_MACHINE_SUCCESS,
  SET_MACHINE_VALIDATION_ERRORS,
} from "constants/Machine";

export const fetchMachinesRequest = () => ({
  type: FETCH_MACHINES_REQUEST,
});

export const fetchMachinesSuccess = (machines) => ({
  type: FETCH_MACHINES_SUCCESS,
  payload: machines,
});

export const fetchMachinesFailure = (error) => ({
  type: FETCH_MACHINES_FAILURE,
  payload: error,
});

export const setMachineValidationErrors = (validationErrors) => ({
  type: SET_MACHINE_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleMachineEditForm = () => ({
  type: SHOW_MACHINE_EDIT_FORM,
});

export const toggleMachineNewForm = () => ({
  type: SHOW_MACHINE_NEW_FORM,
});

export const updateMachineRequest = (machine) => ({
  type: UPDATE_MACHINE_REQUEST,
  payload: machine,
});

export const updateMachineSuccess = (machine) => ({
  type: UPDATE_MACHINE_SUCCESS,
  payload: machine,
});

export const updateMachineFailure = (error) => ({
  type: UPDATE_MACHINE_FAILURE,
  payload: error,
});

export const deleteMachineRequest = (id) => ({
  type: DELETE_MACHINE_REQUEST,
  payload: id,
});

export const deleteMachineSuccess = (machine) => ({
  type: DELETE_MACHINE_SUCCESS,
  payload: machine,
});

export const deleteMachineFailure = (error) => ({
  type: DELETE_MACHINE_FAILURE,
  payload: error,
});

export const createMachineRequest = (machine) => ({
  type: CREATE_MACHINE_REQUEST,
  payload: machine,
});

export const createMachineSuccess = (machine) => ({
  type: CREATE_MACHINE_SUCCESS,
  payload: machine,
});

export const createMachineFailure = (error) => ({
  type: CREATE_MACHINE_FAILURE,
  payload: error,
});
