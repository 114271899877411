import { 
    ICL_PAY_FAILURE,
    ICL_PAY_REQUEST,
    ICL_PAY_SUCCESS,
    UN_CLICK_SUMMIT_BUTTON
} from "constants/IclPay";

export const iclPayRequest = (payload) =>{
     return {
        type : ICL_PAY_REQUEST,
        payload : payload
     }
}

export const iclPaySuccess = (payload) =>{
    return {
       type : ICL_PAY_SUCCESS,
       payload : payload
    }
}

export const iclPayFailure = (payload) =>{
    return {
       type : ICL_PAY_FAILURE,
       payload : payload
    }
}


export const unClickSubmmitButton = () =>{
    return {
       type : UN_CLICK_SUMMIT_BUTTON
    }
}