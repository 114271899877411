export const SET_TEST_DIRECTORY_VALIDATION_ERRORS = "SET_TEST_DIRECTORY_VALIDATION_ERRORS";
export const SHOW_TEST_DIRECTORY_EDIT_FORM = "SHOW_TEST_DIRECTORY_EDIT_FORM";
export const SHOW_TEST_DIRECTORY_NEW_FORM = "SHOW_TEST_DIRECTORY_NEW_FORM";


export const FETCH_TEST_DIRECTORIES_REQUEST = "FETCH_TEST_DIRECTORIES_REQUEST";
export const FETCH_TEST_DIRECTORIES_SUCCESS = "FETCH_TEST_DIRECTORIES_SUCCESS";
export const FETCH_TEST_DIRECTORIES_FAILURE = "FETCH_TEST_DIRECTORIES_FAILURE";

export const UPDATE_TEST_DIRECTORY_REQUEST = "UPDATE_TEST_DIRECTORY_REQUEST";
export const UPDATE_TEST_DIRECTORY_SUCCESS = "UPDATE_TEST_DIRECTORY_SUCCESS";
export const UPDATE_TEST_DIRECTORY_FAILURE = "UPDATE_TEST_DIRECTORY_FAILURE";

export const DELETE_TEST_DIRECTORY_REQUEST = "DELETE_TEST_DIRECTORY_REQUEST";
export const DELETE_TEST_DIRECTORY_SUCCESS = "DELETE_TEST_DIRECTORY_SUCCESS";
export const DELETE_TEST_DIRECTORY_FAILURE = "DELETE_TEST_DIRECTORY_FAILURE";

export const CREATE_TEST_DIRECTORY_REQUEST = "CREATE_TEST_DIRECTORY_REQUEST";
export const CREATE_TEST_DIRECTORY_SUCCESS = "CREATE_TEST_DIRECTORY_SUCCESS";
export const CREATE_TEST_DIRECTORY_FAILURE = "CREATE_TEST_DIRECTORY_FAILURE";

export const FETCH_URGENT_AND_STAT_PRICE_REQUEST = "FETCH_URGENT_AND_STAT_PRICE_REQUEST";
export const FETCH_URGENT_AND_STAT_PRICE_SUCCESS = "FETCH_URGENT_AND_STAT_PRICE_SUCCESS";
export const FETCH_URGENT_AND_STAT_PRICE_FAILURE = "FETCH_URGENT_AND_STAT_PRICE_FAILURE";

export const UPDATE_URGENT_AND_STAT_PRICE_REQUEST = "UPDATE_URGENT_AND_STAT_PRICE_REQUEST";
export const UPDATE_URGENT_AND_STAT_PRICE_SUCCESS = "UPDATE_URGENT_AND_STAT_PRICE_SUCCESS";
export const UPDATE_URGENT_AND_STAT_PRICE_FAILURE = "UPDATE_URGENT_AND_STAT_PRICE_FAILURE";