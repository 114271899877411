import { CheckCircleOutlined } from '@ant-design/icons';
import { Table} from 'antd';
import React from 'react';

const columns = [
  {
    title: 'Test Name',
    dataIndex: 'testname',
    key: 'testname',
    align: 'center',
  },
  {
    title: 'Department Name',
    dataIndex: 'department_name',
    key: 'department_name',
    align: 'center', 
  },
  {
    title: 'Currently Available',
    dataIndex: 'currently_available',
    key: 'currently_available',
    render: (currently_available) => currently_available === 1 ? <CheckCircleOutlined style={{ color: '#66FF00', float: 'center' }} /> : 'Not Available',
    align: 'center',
  },
  {
    title: 'Estimated Time For Result(hr)',
    dataIndex: 'estimated_time_for_result',
    key: 'estimated_time_for_result',
    align: 'center',
  }
];

export const NewTestTable = ({ testDetail }) => {
  const testData = [
    {
      key: '1',
      testname: testDetail.name,
      department_name: testDetail.department_name,
      estimated_time_for_result: testDetail.estimated_time_for_result,
      currently_available: testDetail.currently_available
    },
  ];

  return <Table columns={columns} dataSource={testData} />;
};
