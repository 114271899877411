import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_SAMPLE_ACCEPTED_REQUEST } from "../../constants/SampleAccepted";
import {
  fetchSampleAccpetedFailure,
  fetchSampleAccpetedSucess,
} from "../actions/SampleAccepted";
import { message } from "antd";
import SampleAccepted from "../../services/SampleAccepted";
export function* onFetchSampleAcceptedRequest() {
  yield takeLatest(
    FETCH_SAMPLE_ACCEPTED_REQUEST,
    function* (payload) {
      try {
        const result = yield call(
          SampleAccepted.get,
          payload.payload
        );
        if (result.status) {
          //message.success("Success");
          yield put(fetchSampleAccpetedSucess(result.data));
        } else {
          yield put(fetchSampleAccpetedFailure(result.message));
        }
      } catch (error) {
        message.error("Something went wrong");
        yield put(fetchSampleAccpetedFailure(error.message));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(onFetchSampleAcceptedRequest)]);
}
