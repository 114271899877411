const firebaseConfigDr = {
  apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
  //apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM', // antd api key works too
  authDomain: "drschatroom-f0e8d.firebaseapp.com",
  projectId: "drschatroom-f0e8d",
  storageBucket: "drschatroom-f0e8d.appspot.com",
  messagingSenderId: "31152387794",
  appId: "1:31152387794:web:2e20010ba64ab76a329f0b",
  measurementId: "G-ETHDG8X4N1"
};
export default firebaseConfigDr
