import fetch from "auth/FetchInterceptor";

const StatOrders = {};

StatOrders.get = function (payload) {
  return fetch({
    url:
      "/reports/stat-orders?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency,
  });
};

StatOrders.getObx = function (payload = null) {
  return fetch({
    url: "/reports/getOrderObx?visitUuid=" + payload,
    method: "get",
  });
};

StatOrders.update = function (data) {
  return fetch({
    url: "/update-order-status",
    method: "put",
    data,
  });
};

export default StatOrders;
