import fetch from "auth/FetchInterceptor";

const ClientCompanyTestDirectoryService = {};

ClientCompanyTestDirectoryService.index = function () {
  return fetch({
    url: "/client-company-test-directories",
    method: "get",
  });
};

ClientCompanyTestDirectoryService.create = function (data) {
  return fetch({
    url: "/client-company-test-directories",
    method: "post",
    data,
  });
};

ClientCompanyTestDirectoryService.update = function (id, data) {
  return fetch({
    url: `/client-company-test-directories/${id}`,
    method: "put",
    data,
  });
};

ClientCompanyTestDirectoryService.delete = function (id) {
  return fetch({
    url: `/client-company-test-directories/${id}`,
    method: "delete",
  });
};

export default ClientCompanyTestDirectoryService;
