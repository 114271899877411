import fetch from "auth/FetchInterceptor";

const FaultResults = {};

FaultResults.get = function (payload) {
  return fetch({
    url:
      `/reports/fault-results?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}&department=${payload.department}`,
    method: "get",
  });
};

export default FaultResults;
