import {
    FETCH_TESTOBX_PER_TECHNICIAN_FAILURE,
    FETCH_TESTOBX_PER_TECHNICIAN_REQUEST,
    FETCH_TESTOBX_PER_TECHNICIAN_SUCCESS
} from "constants/TestobxPerTechnician";

const initState = {
     list : [],
     loading : false,
     errorMessage : ""
}

const TestobxPerTechnician = (state = initState, action) =>{
    switch(action.type)
    {
       case FETCH_TESTOBX_PER_TECHNICIAN_REQUEST:
        return {
            ...state,
            loading : true
        } 
       case FETCH_TESTOBX_PER_TECHNICIAN_SUCCESS : 
        return {
             ...state, 
             loading : false,
             list : action.payload
             //list : changeDateToHumanReadableFormat(action.payload)
        }
        case FETCH_TESTOBX_PER_TECHNICIAN_FAILURE :
        return {
            ...state,
            loading : false,
            errorMessage : action.payload
        }
        default : 
            return state
    }
}

export default TestobxPerTechnician;