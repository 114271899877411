export const SHOW_MACHINE_NEW_FORM = "SHOW_MACHINE_NEW_FORM";
export const SHOW_MACHINE_EDIT_FORM = "SHOW_MACHINE_EDIT_FORM";
export const SET_MACHINE_VALIDATION_ERRORS = "SET_MACHINE_VALIDATION_ERRORS";

export const FETCH_MACHINES_REQUEST = "FETCH_MACHINES_REQUEST";
export const FETCH_MACHINES_SUCCESS = "FETCH_MACHINES_SUCCESS";
export const FETCH_MACHINES_FAILURE = "FETCH_MACHINES_FAILURE";

export const UPDATE_MACHINE_REQUEST = "UPDATE_MACHINE_REQUEST";
export const UPDATE_MACHINE_SUCCESS = "UPDATE_MACHINE_SUCCESS";
export const UPDATE_MACHINE_FAILURE = "UPDATE_MACHINE_FAILURE";

export const DELETE_MACHINE_REQUEST = "DELETE_MACHINE_REQUEST";
export const DELETE_MACHINE_SUCCESS = "DELETE_MACHINE_SUCCESS";
export const DELETE_MACHINE_FAILURE = "DELETE_MACHINE_FAILURE";

export const CREATE_MACHINE_REQUEST = "CREATE_MACHINE_REQUEST";
export const CREATE_MACHINE_SUCCESS = "CREATE_MACHINE_SUCCESS";
export const CREATE_MACHINE_FAILURE = "CREATE_MACHINE_FAILURE";
