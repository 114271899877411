import {
  FETCH_TEST_REGISTRATIONS_SUCCESS,
  FETCH_TEST_REGISTRATIONS_REQUEST,
  FETCH_TEST_REGISTRATIONS_FAILURE,
  UPDATE_TEST_REGISTRATION_REQUEST,
  UPDATE_TEST_REGISTRATION_SUCCESS,
  UPDATE_TEST_REGISTRATION_FAILURE,
  SHOW_TEST_REGISTRATION_EDIT_FORM,
  DELETE_TEST_REGISTRATION_REQUEST,
  DELETE_TEST_REGISTRATION_SUCCESS,
  DELETE_TEST_REGISTRATION_FAILURE,
  SET_TEST_REGISTRATION_VALIDATION_ERRORS,
  SHOW_TEST_REGISTRATION_NEW_FORM,
  CREATE_TEST_REGISTRATION_REQUEST,
  CREATE_TEST_REGISTRATION_SUCCESS,
  CREATE_TEST_REGISTRATION_FAILURE,
} from "constants/TestRegistration";

import {
  addTestRegistration,
  formtValidationErrors,
  removeTestRegistration,
  updateTestRegistration,
} from "utils/TestRegistration";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const testRegistration = (state = initState, action) => {
  switch (action.type) {
    case FETCH_TEST_REGISTRATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_TEST_REGISTRATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_TEST_REGISTRATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_TEST_REGISTRATION_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_TEST_REGISTRATION_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_TEST_REGISTRATION_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_TEST_REGISTRATION_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_TEST_REGISTRATION_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateTestRegistration(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_TEST_REGISTRATION_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_TEST_REGISTRATION_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_TEST_REGISTRATION_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeTestRegistration(state, action),
      };
    case DELETE_TEST_REGISTRATION_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_TEST_REGISTRATION_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_TEST_REGISTRATION_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addTestRegistration(state, action),
      };
    case CREATE_TEST_REGISTRATION_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default testRegistration;
