import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_SAMPLE_IN_PROCESS_REQUEST } from "constants/SampleInProcess";
import{
    fetchSampleInProcessFailure, 
    fetchSampleInProcessSuccess
} from "../actions/SampleInProcess";

import { message } from "antd";
import SampleInProcess from "services/SampleInProcess";
export function* onFetchSampleInProcessResult() {
  yield takeLatest(
    FETCH_SAMPLE_IN_PROCESS_REQUEST,
    function* (payload) {
      try {
        const result = yield call(
            SampleInProcess.get,
          payload.payload
        );
        if (result.status) {
          //message.success("Success");
          yield put(fetchSampleInProcessSuccess(result.data));
        } else {
          yield put(fetchSampleInProcessFailure(result.message));
        }
      } catch (error) {
        message.error("Something went wrong");
        yield put(fetchSampleInProcessFailure(error.message));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(onFetchSampleInProcessResult)]);
}
