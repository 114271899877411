import fetch from "auth/FetchInterceptor";

const PatientSearchService = {};



PatientSearchService.searchpatient = function (searchdata) {
  return fetch({
    url: "/searchpatient",
    method: "post",
    data:searchdata
  });
 };


export default PatientSearchService;
