import{
    FETCH_DOCTORS_LIST_REQUEST,
    FETCH_DOCTORS_LIST_SUCCESS,
    FETCH_DOCTORS_LIST_FAILURE,
}from "constants/doctors_list";

const initState = {
    loading : false, 
    doctors: [],
    errorMessage: ""
}

const DoctorsList = (state = initState, action) =>{
    switch(action.type){
        case FETCH_DOCTORS_LIST_REQUEST :
            return {
               ...state,
                loading : true
            }
        case FETCH_DOCTORS_LIST_SUCCESS :
            return {
                ...state,
                doctors : action.payload,
                loading : false,
            }
        case FETCH_DOCTORS_LIST_FAILURE :
                return {
                    ...state,
                    loading : false,
                    errorMessage : action.payload
                }
        default : return state;  
}
}
export default DoctorsList;

// import {
//     FETCH_DOCTORS_LIST_REQUEST,
//     FETCH_DOCTORS_LIST_SUCCESS,
//     FETCH_DOCTORS_LIST_FAILURE,
// } from "constants/doctors_list";

// const initState = {
//     loading: false,
//     list: [],
//     listOfDoctors: [],
//     doctors: [], // Initialize doctors as an array
//     errorMessage: {}
// };

// const DoctorsList = (state = initState, action) => {
//     switch (action.type) {
//         case FETCH_DOCTORS_LIST_REQUEST:
//             return {
//                 ...state,
//                 loading: true
//             };
//         case FETCH_DOCTORS_LIST_SUCCESS:
//             return {
//                 ...state,
//                 doctors: action.payload,
//                 loading: false,
//             };
//         case FETCH_DOCTORS_LIST_FAILURE:
//             return {
//                 ...state,
//                 doctors: [], // Clear doctors on failure
//                 loading: false
//             };
//         default:
//             return state;
//     }
// };

// export default DoctorsList;
