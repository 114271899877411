import {
    FETCH_CORELAB_RESULTS_FAILURE,
    FETCH_CORELAB_RESULTS_REQUESET,
    FETCH_CORELAB_RESULTS_SUCCESS
} from "constants/CoreLab";


const coreLabResults = {
    list : [],
    loading : false, 
    errorMessage : ""
}

const CoreLabResults = (state = coreLabResults, action) =>{
     switch(action.type){
        case  FETCH_CORELAB_RESULTS_REQUESET : 
        return {
            ...state,
                loading : true
        }
        case FETCH_CORELAB_RESULTS_SUCCESS : 
        return {
            ...state,
            list : action.payload,
            loading : false
        }
        case FETCH_CORELAB_RESULTS_FAILURE : 
        return {
            ...state,
            loading : false
        }
        default : return state
     }
}

export default CoreLabResults;