import {
    FORGOT_PASSWORD_FILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS
} from "constants/ForgotPassword"

export const forgotPasswordRequest = (payload) => {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        payload : payload
    }
}

export const forgotPasswordSuccess = (payload) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload : payload
    }
}

export const forgotPasswordFilure = (payload) => {
    return {
        type: FORGOT_PASSWORD_FILURE,
        payload : payload
    }
}

export const passwordResetRequeset = (password) => {
    return {
        type: UPDATE_PASSWORD_REQUEST,
        payload: {
            password
        }
    }
}

export const passwordResetSuccess = (payload) => {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        payload: payload
    }
}

export const passwordResetFilure = (payload) => {
    return {
        type: UPDATE_PASSWORD_FAILURE,
        payload: payload
    }
}

