import fetch from 'auth/FetchInterceptor'

const Logistics = {};
Logistics.get = function () {
  return fetch({
    url: "/getlogistics",
    method: "get",
  });
};

Logistics.create = function (data) {
  return fetch({
    url: "/logistics",
    method: "post",
    data,
  });
};

Logistics.updateResourceRequestStatus = function (data) {
  return fetch({
    url: `/updateResourceRequestStatus/${data.id}`,
    method: "put",
    data,
  });
};

Logistics.update = function (data) {
  return fetch({
    url: "/updateRequest/" + data.request_id,
    method: "put",
    data,
  });
};

export default Logistics;
