export const SHOW_EQUIPMENT_NEW_FORM = "SHOW_EQUIPMENT_NEW_FORM";
export const SHOW_EQUIPMENT_EDIT_FORM = "SHOW_EQUIPMENT_EDIT_FORM";
export const SET_EQUIPMENT_VALIDATION_ERRORS = "SET_EQUIPMENT_VALIDATION_ERRORS";

export const FETCH_EQUIPMENT_REQUEST = "FETCH_EQUIPMENT_REQUEST";
export const FETCH_EQUIPMENT_SUCCESS = "FETCH_EQUIPMENT_SUCCESS";
export const FETCH_EQUIPMENT_FAILURE = "FETCH_EQUIPMENT_FAILURE";

export const UPDATE_EQUIPMENT_REQUEST = "UPDATE_EQUIPMENT_REQUEST";
export const UPDATE_EQUIPMENT_SUCCESS = "UPDATE_EQUIPMENT_SUCCESS";
export const UPDATE_EQUIPMENT_FAILURE = "UPDATE_EQUIPMENT_FAILURE";

export const DELETE_EQUIPMENT_REQUEST = "DELETE_EQUIPMENT_REQUEST";
export const DELETE_EQUIPMENT_SUCCESS = "DELETE_EQUIPMENT_SUCCESS";
export const DELETE_EQUIPMENT_FAILURE = "DELETE_EQUIPMENT_FAILURE";

export const CREATE_EQUIPMENT_REQUEST = "CREATE_EQUIPMENT_REQUEST";
export const CREATE_EQUIPMENT_SUCCESS = "CREATE_EQUIPMENT_SUCCESS";
export const CREATE_EQUIPMENT_FAILURE = "CREATE_EQUIPMENT_FAILURE";
