export const ADD_PATIENT = "ADD_PATIENT";
export const REMOVE_PATIENT = "REMOVE_PATIENT";
export const ADD_TEST_CATEGORY = "ADD_TEST_CATEGORY";
export const ADD_TEST_DIRECTORY = "ADD_TEST_DIRECTORY";
export const SET_NUMBER_OF_PATIENT = "SET_NUMBER_OF_PATIENT";
export const SAVE_TEST_REGISTRATION_REQUEST = 'SAVE_TEST_REGISTRATION_REQUEST';
export const SAVE_TEST_REGISTRATION_SUCCESS = 'SAVE_TEST_REGISTRATION_SUCCESS';
export const SAVE_TEST_REGISTRATION_FAILURE = 'SAVE_TEST_REGISTRATION_FAILURE';
export const FETCH_APPOINTMENT_METADATA_REQUEST = "FETCH_APPOINTMENT_METADATA_REQUEST";
export const FETCH_APPOINTMENT_METADATA_SUCCESS = "FETCH_APPOINTMENT_METADATA_SUCCESS";
export const FETCH_APPOINTMENT_METADATA_FAILURE = "FETCH_APPOINTMENT_METADATA_FAILURE";
export const SET_APPOINTMENT_DATE = "SET_APPOINTMENT_DATE";
export const SET_APPOINTMENT_DAY_NAME = "SET_APPOINTMENT_DAY_NAME";
export const SET_APPOINTMENT_TIME = "SET_APPOINTMENT_TIME";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const CLEAR_APPOINTMENT_TIME = "CLEAR_APPOINTMENT_TIME";
export const SET_BRANCH_ID = "SET_BRANCH_ID";

