import { FETCH_TOP_TEST_REQUEST } from "../../constants/TopTests";
import { FETCH_TOP_TEST_SUCCESS } from "../../constants/TopTests";
import { FETCH_TOP_TEST_FAILURE } from "constants/TopTests";
import { SET_TOP_TEST_VALIDATION_ERRORS } from "../../constants/TopTests";

export const fetchTopTestRequest = (startDate, endDate, agency, department) =>{
    return {
         type : FETCH_TOP_TEST_REQUEST,
         payload :{
          startDate,
          endDate,
          agency,
          department
        }
    }
}

export const fetchTopTestSuccess = (ResponseDatas) =>{
     return {
         type : FETCH_TOP_TEST_SUCCESS,
         payload : ResponseDatas
     }
}

export const fetchTopTestFailure = (Error) =>{
     return{
         type : FETCH_TOP_TEST_FAILURE,
         payload : Error
     }
}

export const fetchTopTestValidationErrors = (Error) =>{
     return{
          type : SET_TOP_TEST_VALIDATION_ERRORS,
          payload : Error
     }
}