export const SHOW_NEW_FORM = "SHOW_NEW_FORM";
export const SHOW_SEARCH_FORM = "SHOW_SEARCH_FORM";
export const SHOW_EDIT_FORM = "SHOW_EDIT_FORM";
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";


export const FETCH_EXISTING_PATIENT_REQUEST = "FETCH_EXISTING_PATIENT_REQUEST";
export const FETCH_EXISTING_PATIENT_SUCCESS = "FETCH_EXISTING_PATIENT_SUCCESS";
export const FETCH_EXISTING_PATIENT_FAILURE = "FETCH_EXISTING_PATIENT_FAILURE";
export const CLEAR_EXISTING_PATIENT_CAPACITIES = "CLEAR_EXISTING_PATIENT";

