export const Arabic = {
    "type": "النوع",
    "home": "الرئيسية",
    "upload": "رفع الملف",
    "start_upload": "بدء الرفع",
    "uploading": "جاري الرفع",
    "user_management": "إدارة المستخدمين",
    "change_password": "تغيير كلمة المرور",
    "role": "الأدوار",
    "user": "المستخدمين",
    "abbrev": "اختصار",
    "test_menu": "قائمة الاختبارات",
    "config": "التكوين",
    "currency": "العملة",
    "credit_allowed": "الائتمان المسموح به",
    "allowed_for_credit": "مسموح بالائتمان",
    "tin_or_arn": "الرقم الضريبي أو رقم التسجيل الضريبي",
    "npid": "الرقم الوطني للهوية",
    "contact": "جهة الاتصال",
    "speed_dial": "الاتصال السريع",
    "fee_schedule": "جدول الرسوم",
    "dashboard": "لوحة القيادة",
    "select_agency": "اختيار الوكالة",
    "select_department": "اختيار القسم",
    "edit_department": "تحرير القسم",
    "edit_agency": "تحرير شركة العميل",
    "agency_detail": "تفاصيل الوكالة",
    "select_role": "اختيار الدور",
    "select_location": "اختيار الموقع",
    "departments": "الأقسام",
    "agency_definition": "تعريف الوكالة",
    "discontinued_test_results": "نتائج الاختبار المتوقفة",
    "assign_test_to_agency": "تعيين الاختبار للوكالة",
    "branch_look_up": "البحث في الفروع",
    "test_definition": "تعريف الاختبار",
    "core_lab": "المختبر الأساسي",
    "tb_micro_pending": "TB/Micro قيد الانتظار",
    "place_order": "طلب المنتج",
    "orderable": "قابل للطلب",
    "none_orderable": "غير قابل للطلب",
    "pick_up_request": "طلب الاستلام",
    "time_line": "الجدول الزمني",
    "order_status": "حالة الطلب",
    "all": "الكل",
    "results": "النتائج",
    "completed": "مكتمل",
    "incomplete": "غير مكتمل",
    "micro_patho_result": "نتيجة الميكروبيولوجي والمرضية",
    "comming_soon": "قريبًا",
    "pannic_results": "نتائج البانيك",
    "confidential_results": "نتائج سرية",
    "commulative_sum_report": "تقرير المجموع التراكمي",
    "faulty_pdf": "ملف PDF معطل",
    "upload_results": "رفع النتائج",
    "upload_csv_results": "رفع نتائج CSV",
    "upload_financial_report": "رفع التقرير المالي",
    "pending": "قيد الانتظار",
    "approved": "تمت الموافقة عليه",
    "declined": "تم رفضه",
    "daily_released_tests": "الاختبارات المفرج عنها يوميًا",
    "single_daily_released_tests": "الاختبارات المفرج عنها يوميًا بشكل فردي",
    "daily_department_released_tests": "الاختبارات المفرج عنها يوميًا حسب القسم",
    "order_to_be_sent": "الطلبات المراد إرسالها",
    "stat_orders": "طلبات الإحصاء",
    "referal_orders": "طلبات الإحالة",
    "referable": "قابل للإحالة",
    "sample_processing_in_lab": "معالجة العينات في المختبر",
    "rejected": "تم رفضه",
    "tests_per_technician": "الاختبارات لكل فني",
    "daily_financial_summary": "ملخص مالي يومي",
    "specimen_tracking": "تتبع العينات",
    "top_branches_for_walkin_customers": "أفضل الفروع للعملاء الذين يتوجب عليهم الحضور شخصيًا",
    "released_after_tat": "الإفراج بعد الوقت المحدد للتحليل",
    "tat_routine_report": "تقرير TAT الروتيني",
    "released_pannels_for_icl": "لوحات الإفراج لـ ICL",
    "top_agencies": "أفضل الوكالات",
    "top_tests": "أفضل الاختبارات",
    "top_orders": "أفضل الطلبات",
    "credit_invoice": "فاتورة الائتمان",
    "financial": "مالي",
    "tat_approaching": "الوقت المقارب لـ TAT",
    "submit": "إرسال",
    "test_results": "نتائج الاختبار",
    "released_tests": "الاختبارات المفرج عنها",
    "pending_tests": "الاختبارات قيد الانتظار",
    "panic_tests": "الاختبارات الطارئة",
    "uncommunicated_panic": "الحالات الطارئة غير المبلغ عنها",
    "corrected_results": "نتائج مصححة",
    "average_tat": "متوسط ​​TAT",
    "average_release_time": "متوسط ​​وقت الإفراج",
    "average_delayed_tests": "متوسط ​​الاختبارات المتأخرة",
    "total_orders": "إجمالي الطلبات",
    "total_income": "إجمالي الدخل",
    "total_patients": "إجمالي المرضى",
    "order_statistics": "إحصاءات الطلبات",
    "agency": "وكالة",
    "walkin": "الحضور شخصيًا",
    "payment": "الدفع",
    "view_detail": "عرض التفاصيل",
    "you_must_select_agency": "يجب عليك اختيار الوكالة",
    "edit": "تعديل",
    "en": "en",
    "delete": "حذف",
    "available": "متاح",
    "not_available": "غير متاح",
    "sample_id": "معرف العينة",
    "name": "الاسم",
    "agency_id": "معرف الوكالة",
    "agency_number": "رقم الوكالة",
    "status": "الحالة",
    "apply_filter": "تطبيق الفلتر",
    "reset": "إعادة تعيين",
    "firstname": "الاسم الأول",
    "lastname": "الاسم الأخير",
    "date_of_birth": "تاريخ الميلاد",
    "phone_number": "رقم الهاتف",
    "location": "الموقع",
    "username": "اسم المستخدم",
    "active": "نشط",
    "actions": "الإجراءات",
    "user_detail": "تفاصيل المستخدم",
    "inactive": "غير نشط",
    "search": "بحث",
    "filter": "تصفية",
    "add_new_user": "إضافة مستخدم جديد",
    "add_new_department": "إضافة قسم جديد",
    "add_client_company": "إضافة شركة العميل",
    "choose_agency_first": "اختر الوكالة أولاً",
    "edit_user": "تعديل المستخدم",
    "yes": "نعم",
    "no": "لا",
    "notification": "الإشعارات",
    "dark": "داكن",
    "light": "فاتح",
    "cashier_location": "موقع الكاشير",
    "cashier_code": "رمز الكاشير",
    "password": "كلمة المرور",
    "is_icl_user": "هل المستخدم ICL",
    "are_you_sure": "هل أنت متأكد من حذف هذا؟",
    "copyright": "حقوق النشر",
    "icl_all_right_reserved": "كل الحقوق محفوظة",
    "permissions": "الصلاحيات",
    "detail": "تفاصيل",
    "add_role": "إضافة دور",
    "add_new_role": "إضافة دور جديد",
    "select_permission": "اختيار الصلاحية",
    "enter_name": "ادخل الاسم",
    "edit_permission_for": "تحرير الصلاحية لـ",
    "role_detail": "تفاصيل الدور",
    "test_name": "اسم الاختبار",
    "code": "الرمز",
    "referral": "الإحالة",
    "success": "نجاح",
    "error": "خطأ",
    "temporarly_discontinued_test": "تم تعليق الاختبار مؤقتًا",
    "sample_type": "نوع العينة",
    "sample_volume": "حجم العينة",
    "sample_container": "حاوية العينة",
    "price": "السعر",
    "non_orderable": "غير قابل للطلب",
    "add_new_test": "إضافة اختبار جديد",
    "test_schedule": "جدول الاختبار",
    "update_stat_and_urgent_price": "تحديث سعر الإحصاء والطوارئ",
    "stat_price": "سعر الإحصاء",
    "urgent_price": "سعر الطوارئ",
    "field_is_required": "الحقل مطلوب",
    "updating": "جار التحديث",
    "update": "تحديث",
    "enable_edit": "تمكين التعديل",
    "test_abbreviation": "اختصار الاختبار",
    "test_full_name": "اسم الاختبار الكامل",
    "number_of_tests": "عدد الاختبارات",
    "department_name": "اسم القسم",
    "price_etb_agency_a": "سعر ETB للوكالة A",
    "price_etb_agency_b": "سعر ETB للوكالة B",
    "price_etb_agency_c": "سعر ETB للوكالة C",
    "price_etb_agency_d": "سعر ETB للوكالة D",
    "price_etb_agency_e": "سعر ETB للوكالة E",
    "price_etb_agency_f": "سعر ETB للوكالة F",
    "price_etb_agency_g": "سعر ETB للوكالة G",
    "price_etb_agency_h": "سعر ETB للوكالة H",
    "price_usd_agency_a": "سعر USD للوكالة A",
    "price_usd_agency_b": "سعر USD للوكالة B",
    "price_usd_agency_c": "سعر USD للوكالة C",
    "price_usd_agency_d": "سعر USD للوكالة D",
    "price_usd_agency_e": "سعر USD للوكالة E",
    "price_usd_agency_f": "سعر USD للوكالة F",
    "price_usd_agency_g": "سعر USD للوكالة G",
    "tat_urgent": "TAT الطارئ",
    "tat_stat": "TAT الإحصائي",
    "ignorable_tat": "TAT يمكن تجاهله",
    "fake_department": "قسم مزيف",
    "inperson_delivery": "توصيل شخصي",
    "currently_available": "متاح حالياً",
    "panel_number": "رقم اللوحة",
    "charge_code": "رمز الرسوم",
    "test_catagory": "فئة الاختبار",
    "test_sub_category": "فئة فرعية للاختبار",
    "test_description": "وصف الاختبار",
    "price_etb": "السعر (ETB)",
    "price_usd": "السعر (USD)",
    "standard_test_name": "اسم الاختبار القياسي",
    "standard_department_name": "اسم القسم القياسي",
    "standard_abbreviation": "اختصار قياسي",
    "test_methodology": "منهجية الاختبار",
    "estimated_result_time_in_hour": "الوقت المقدر للنتيجة بالساعات",
    "test_done_abroad": "تم إجراء الاختبار في الخارج",
    "is_ihc_est": "هل الاختبار IHC",
    "constant_only_for_pathology": "ثابت (فقط لعلم الأمراض)",
    "walking_customer_price_usd": "سعر العميل المشي (USD)",
    "walking_customer_price_etb": "سعر العميل المشي (ETB)",
    "are_you_sure_delete_this_test": "هل أنت متأكد أنك تريد حذف هذا الاختبار؟",
    "department_code": "رمز القسم",
    "has_custom_data": "يحتوي على بيانات مخصصة",
    "in_person_delivery": "تسليم شخصي",
    "icl_branch": "فرع ICL",
    "not_icl_branch": "ليس فرع ICL",
    "parent": "الأصل",
    "clear": "مسح",
    "signout": "تسجيل الخروج",
    "main": "الرئيسية",
    "additional_info": "معلومات إضافية",
    "my_lab": "مختبري",
    "pathology": "علم الأمراض",
    "patient_id": "معرف المريض",
    "mrn_no": "رقم MRN",
    "first_name": "الاسم الأول",
    "middle_name": "الاسم الأوسط",
    "last_name": "الاسم الأخير",
    "sex": "الجنس",
    "phone": "الهاتف",
    "email": "البريد الإلكتروني",
    "primary_doctor": "الطبيب الأساسي",
    "secondary_doctor": "الطبيب الثانوي",
    "test_performed_lab": "الاختبار المنجز في المختبر",
    "select_lab": "اختيار المختبر",
    "phone_alt": "رقم الهاتف البديل",
    "region": "المنطقة",
    "city": "المدينة",
    "street": "الشارع",
    "passport_no": "رقم جواز السفر",
    "insurance": "التأمين",
    "select_insurance": "اختر التأمين",
    "member_id": "رقم العضو",
    "did_you_have_a_meal_today": "هل تناولت وجبة اليوم؟",
    "have_you_exercised_today": "هل مارست الرياضة اليوم؟",
    "exercise_regularly": "هل تمارس الرياضة بانتظام؟",
    "how_many_times_do_you_excesise_per_week": "كم مرة في الأسبوع تمارس الرياضة؟",
    "how_long_excercise": "كم دقيقة تستغرق ممارسة الرياضة؟",
    "excercise_grade": "كيف تقيم ممارسة الرياضة بشكل عام؟",
    "feel_stressed": "هل تشعر بالتوتر؟",
    "professional_help": "هل طلبت يوما مساعدة متخصصة للتعامل مع التوتر؟",
    "sleep_duration": "كم ساعة تنام يوميا؟",
    "caffine_consumption_daily": "كم كوبا من القهوة أو الشاي تتناولها يوميا عادة؟",
    "daily_water_intake": "كم لترا من الماء تشربها يوميا عادة؟",
    "daily_fruit_intake": "هل تتناول 3-5 وجبات من الفواكه والخضروات يوميا؟",
    "usually": "هل عادة ما تفعل؟",
    "smoke_status": "هل تدخن؟",
    "somoke_other_than_cigar": "هل تدخن غير السجائر؟",
    "alcohol_consumption": "هل تتناول الكحول؟",
    "work_nature": "كيف تصف عملك؟",
    "residence": "هل تعيش؟",
    "travel_status": "هل تسافر؟",
    "dm_family_history": "هل لديك تاريخ عائلي بالسكري؟",
    "htn_family_history": "هل لديك تاريخ عائلي بارتفاع ضغط الدم؟",
    "cancer_family_history": "هل لديك تاريخ عائلي بالسرطان؟",
    "education_level": "مستوى التعليم",
    "pregnancy": "هل أنت حامل؟",
    "breast_feeding_status": "هل ترضع؟",
    "contraception_use": "هل تستخدم وسائل منع الحمل الهرمونية؟",
    "less_than": "أقل من",
    "minutes": "دقائق",
    "more": "أكثر",
    "and": "و",
    "simple": "خفيف",
    "moderate": "معتدل",
    "vigorous": "شديد",
    "hours": "ساعات",
    "eat_out": "تناول الطعام خارج المنزل",
    "cook_at_home": "طهي الطعام في المنزل",
    "work_nature_1": "العمل في المكتب مع الجلوس لفترات طويلة",
    "work_nature_2": "العمل في المكتب ولكن يتطلب الكثير من الحركة/السفر",
    "work_nature_3": "العمل الجسدي مع الوقوف لفترات طويلة قانونيا",
    "urban": "حضري",
    "rural": "ريفي",
    "suburban": "ضواحي",
    "travel_by_private": "بالسيارة الخاصة",
    "walk": "المشي",
    "read_write": "القراءة والكتابة",
    "secondary_school": "المدرسة الثانوية",
    "illiterate": "أمي",
    "primary_school": "المدرسة الابتدائية",
    "college_and_above": "الكلية وأعلى",
    "search_pannel": "البحث في الألواح بالاسم، الرمز ....",
    "search_test_pannels": "البحث في ألواح الاختبار",
    "clinical_info": "المعلومات السريرية",
    "relevant_history": "التاريخ ذو الصلة",
    "relevant_image": "الصورة ذات الصلة",
    "relevant_lab": "العمل المختبري ذو الصلة",
    "enter_relevant_info": "أدخل معلومات التاريخ ذو الصلة",
    "enter_relevant_image_info": "أدخل معلومات الصورة ذات الصلة",
    "enter_relevant_lab_work_info": "أدخل معلومات العمل المختبري ذو الصلة",
    "specimen_class": "فئة العينة",
    "biopsy_type": "نوع الخزعة",
    "action": "العمل",
    "urgent_order": "طلب عاجل",
    "search_here": "ابحث هنا",
    "patient_search": "بحث عن المريض",
    "test_code": "رمز الاختبار",
    "default_price": "السعر الافتراضي",
    "schedule": "الجدول الزمني",
    "add_to_table": "إضافة إلى الجدول",
    "male": "ذكر",
    "female": "أنثى",
    "order_summary": "ملخص الطلب",
    "full_name": "الاسم الكامل",
    "total_number_of_tests": "إجمالي عدد الاختبارات",
    "total_price": "السعر الإجمالي",
    "list_of_sample_container": "قائمة العينات",
    "show_printable_order_summary": "عرض ملخص الطلب القابل للطباعة",
    "save": "حفظ",
    "order": "طلب",
    "cancel": "إلغاء",
    "costs": "سيكلفك ",
    "extra_fee": "رسوم إضافية بالبر",
    "provide_additional_patho_info": "تم اختيار اختبار علم الأمراض. سوف تحتاج إلى ملء معلومات علم الأمراض الإضافية ",
    "field_required": "الحقل مطلوب",
    "okay": "موافق",
    "transaction_response": "رد المعاملة",
    "cashier_summary": "ملخص الطلب للكاشير",
    "confirm": "تأكيد",
    "print_cash_receipt": "طباعة إيصال النقدية",
    "pt_agreed": "وافق المريض",
    "reciept_no": "رقم الاستلام/FS",
    "add_new_branch": "إضافة فرع جديد",
    "branch_name": "اسم الفرع",
    "concatenated_code": "الرمز المجمع",
    "branch_code": "رمز الفرع",
    "branch_description": "وصف الفرع",
    "telephone_1": "الهاتف 1",
    "telephone_2": "الهاتف 2",
    "sample_collection_only": "جمع العينات فقط",
    "covid_test_provided": "توفير اختبار كوفيد",
    "wellness_test_provided": "توفير اختبار العافية",
    "speed_dial_provider": "مزود الاتصال السريع",
    "open_on_holiday": "مفتوح في العطلات",
    "open_every_time": "مفتوح طوال الوقت",
    "visible_to_public": "مرئي للجمهور",
    "allowed_for_coupon": "مسموح بالكوبون",
    "edit_branch_look_up": "تحرير بحث الفرع",
    "add_branch_look_up": "إضافة بحث فرعي جديد",
    "view_branch_look_up_detail": "تفاصيل بحث الفرع",
    "panel_summary": "ملخص اللوحة",
    "are_you_sure_this_panels_are_fore": "هل أنت متأكد من أن هذه الألواح مخصصة لـ",
    "other": "آخر",
    "send_pickup_request": "إرسال طلب الاستلام",
    "sending": "جارٍ الإرسال...",
    "search_historical_record": "البحث في السجل التاريخي",
    "fullname": "الاسم الكامل",
    "cardnumber": "رقم البطاقة",
    "urgency": "الطارئية",
    "age": "العمر",
    "panic_result": "نتيجة الذعر",
    "spoiled_samples": "عينات تالفة",
    "approval_status": "حالة الموافقة",
    "ordered_time": "وقت الطلب",
    "downloading": "جارٍ التنزيل...",
    "download_existing": "تنزيل موجود",
    "only_csv_files_are_allowed": "يُسمح فقط بملفات CSV",
    "select_category": "اختر الفئة",
    "month": "الشهر",
    "add_to_list": "إضافة إلى القائمة",
    "select_file": "اختر ملفًا",
    "total_tests": "إجمالي الاختبارات",
    "total_discrepancy": "إجمالي الاختلاف",
    "received_time": "وقت الاستلام",
    "released_time": "وقت الإصدار",
    "pdf": "PDF",
    "flag": "علم",
    "new_time": "وقت جديد",
    "discrepancy_detail": "تفاصيل الاختلاف",
    "technician": "فني",
    "inserted_date": "تاريخ الإدراج",
    "test_count": "عدد الاختبارات",
    "type_of_report": "نوع التقرير",
    "invoice_summary": "ملخص الفاتورة",
    "invoice_attachment": "مرفق الفاتورة",
    "statement_of_account": "كشف حساب",
    "only_pdf_file_is_allowed": "يُسمح فقط بملف PDF",
    "cashier_name": "اسم الكاشير",
    "fs_number": "رقم FS",
    "payment_type": "نوع الدفع",
    "date_time": "التاريخ والوقت",
    "export": "تصدير",
    "sample_tube": "أنبوب العينة",
    "comment": "تعليق",
    "remark": "ملاحظة",
    "enter_comment": "أدخل التعليق",
    "phlebotomy_comment": "تعليق سحب الدم",
    "draw_location": "موقع السحب",
    "upload_by": "تم التحميل بواسطة",
    "uploaded_at": "تم التحميل في",
    "media_type": "نوع الوسائط",
    "select_media_type": "اختر نوع الوسائط",
    "logistics" : "الخدمات اللوجستية",
    "user_type" :"نوع المستخدم",
	"specimen_managment" : "إدارة العينات"
}