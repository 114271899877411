import{
    FETCH_TB_MICRO_RESULTS_REQUEST,
    FETCH_TB_MICRO_RESULTS_SUCCESS,
    FETCH_TB_MICRO_RESULTS_FAILURE
} from "constants/TB_Micro";


const tbmicroResults = {
    list : [],
    loading : false,
    errorMessage : ""
}

const TbmicroResults = (state = tbmicroResults, action) => {
    switch(action.type){
        case FETCH_TB_MICRO_RESULTS_REQUEST:
            return{
                ...state,
                loading:true
            }
        case FETCH_TB_MICRO_RESULTS_SUCCESS:
            return{
                ...state,
                list:action.payload,
                loading:false
            }
        case FETCH_TB_MICRO_RESULTS_FAILURE:
            return {
                ...state,
                loading:false,
            }
        default: return state
    }
}

export default TbmicroResults;