import fetch from "auth/FetchInterceptor";

const SampleAccepted = {};

SampleAccepted.get = function (payload) {
  return fetch({
    url:
      "/reports/sample-accepted?" +
      "&startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

export default SampleAccepted;
