import fetch from "auth/FetchInterceptor";

const EquipmentService = {};

EquipmentService.index = function () {
  return fetch({
    url: "/equipment",
    method: "get",
  });
};

EquipmentService.create = function (data) {
  return fetch({
    url: "/equipment",
    method: "post",
    data,
  });
};

EquipmentService.update = function (id, data) {
  return fetch({
    url: `/equipment/${id}`,
    method: "put",
    data,
  });
};

EquipmentService.delete = function (id) {
  return fetch({
    url: `/equipment/${id}`,
    method: "delete",
  });
};

export default EquipmentService;