import fetch from "auth/FetchInterceptor";

const PatientTestResultHistoryService = {};

PatientTestResultHistoryService.index = function (payload) {
  return fetch({
    url:
      "/reports/patienttestresulthistory?" +
      "patientid=" +
      payload.patientid +
      "&sampleid=" +
      payload.sampleid,
    method: "get",
  });
};

PatientTestResultHistoryService.searchpatient = function (searchdata) {
  return fetch({
    url: "/searchpatient",
    method: "post",
    data: searchdata
  });
}

export default PatientTestResultHistoryService;
