export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const USER_PREFERENCE = "user_preference";
export  const LOGIS_AUTH_TOKEN = "logis_auth_token";

export  const LOGIS_TOKEN_EXPIRATION_TIME = "logis_token_expiration_time";
export  const LOGIS_LOCATION_LIST = "logis_location_list";
export  const LOGIS_USERNAME = "icl@icladdis.com";
export  const LOGIS_PASSWORD = "8upMB5Cdhuu8X7grbp9K5xxXynxzCPZT";

export const ORBIT_TOKEN_KEY = "ORBIT_TOKEN_KEY";
export const ORBIT_PHONE_NUMBER = "+251114671818";
export const ORBIT_PASSWORD = "icl#external@1818!!";
export const ORBIT_EXPIRATION_TIME = "ORBIT_EXPIRATIONTIME";

export const SAMPLE_MANAGEMENT_TOKEN_KEY = "SAMPLE_MANAGEMENT_TOKEN_KEY";
export const SAMPLE_MANAGEMENT_EMAIL = "be@icladdis.com";
export const SAMPLE_MANAGEMENT_PASSWORD = "12312332";
export const SAMPLE_MANAGEMENT_EXPIRATION_TIME = "SAMPLE_MANAGEMENT_EXPIRATION_TIME";

export const LOGIN_EXPIRATION_TIME = 'LOGIN_EXPIRATION_TIME';
export const CURRENT_USER = 'current_user'
export const PREVIOUSLY_LOGGED_IN = "PREVIOUSLY_LOGGED_IN";
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'

//
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST = 'SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS = 'SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE = 'SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE';

export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST = 'SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS = 'SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE = 'SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE';
