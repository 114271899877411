import{
 FETCH_PENDINGRESULTS_REQUEST,
 FETCH_PENDINGRESULTS_SUCCESS,
 FETCH_PENDINGRESULTS_FAILURE
} from "constants/IncompleteResults"

export const fetchPendingResultRequest = (startDate, endDate, agency, department) =>{
    return {
        type : FETCH_PENDINGRESULTS_REQUEST,
        payload :
         { 
            startDate,
            endDate, 
            agency,
            department
        }
    }
}

export const fetchPendingResultSuccess = (payload) =>{
     return {
         type : FETCH_PENDINGRESULTS_SUCCESS,
         payload : payload
     }  
}

export const fetchPendingResultFailure = (payload) =>{
     return { 
        type : FETCH_PENDINGRESULTS_FAILURE, 
        payload : payload
     }
}