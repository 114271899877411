import {
    TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_REQUEST,
    TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_SUCCESS,
    TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_FAILURE,
    SET_TOP_BRANCH_FOR_WALKING_CUSTOMERS_VALIDATION_ERROR
} from "../../constants/TopBranchesForWalkInCustomer"

export const fetchTopBranchForWalkinCustomersRequest = (startDate, endDate, agency, department) =>{
     return{
         type : TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_REQUEST,
         payload : {
             startDate,
             endDate,
             agency,
             department
         }
     }
}

export const fetchTopBranchForWalkinCustomersSuccess = (payload) =>{
     return{
         type : TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_SUCCESS,
         payload : payload
     }
}


export const fetchTopBranchForWalkinCustomersFailure = (error) =>{
     return{
         type : TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_FAILURE,
         payload : error
     }
}


export const setTopBranchForWalkinCustomersValidationError = (error) =>{
     return{
         type : SET_TOP_BRANCH_FOR_WALKING_CUSTOMERS_VALIDATION_ERROR,
         payload : error
     }
}