import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
  FETCH_EXISTING_PATIENT_REQUEST,
} from "constants/PatientSearch";
import {

  fetchPatientSearchSuccess,
  fetchPatientSearchFailure,

} from "redux/actions";

import PatientSearchService from "services/PatientSearchService";

export function* onFetchExistingPatients() {
  yield takeLatest(FETCH_EXISTING_PATIENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(PatientSearchService.searchpatient, payload);
      if (result.status === false || result.data.length < 1) {
        if(payload.searchtype === "without_order_and_result"){
          message.warning("Not Found");
        }
        yield put(fetchPatientSearchFailure(result.message));
      }
      if (result.status === true && result.data.length > 0) {
        yield put(fetchPatientSearchSuccess(result.data));
      } else {
        yield put(fetchPatientSearchFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPatientSearchFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchExistingPatients),
  ]);
}
