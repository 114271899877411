import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { CREATE_RESOURCE_REQUEST, FETCH_RESOURCE_REQUEST, UPDATE_RESOURCE_REQUEST, UPDATE_RESOURCE_REQUEST_STATUS } from "constants/Logistics";
import {
  createResourceRequestFailure,
  createResourceRequestSuccess,
  fetchResourceRequestFailure,
  fetchResourceRequestSuccess,
  updateResourceRequestFailure,
  updateResourceRequestStatusFailure,
  updateResourceRequestStatusSuccess,
  updateResourceRequestSuccess
} from "redux/actions";
import Logistics from "services/Logistics";

export function* onFetchResourceRequest() {
  yield takeLatest(FETCH_RESOURCE_REQUEST, function* (payload) {
    try {
      
      const result = yield call(Logistics.get, payload.payload);
      if (result.status) {
        yield put(fetchResourceRequestSuccess(result.data));
      } else {
        message.error("Error fetching the resource");
        yield put(fetchResourceRequestFailure(result.message));
      }
    } catch (error) {
        message.error("something went wrong");
      yield put(fetchResourceRequestFailure(error.message));
    }
  });
}

export function* onCreateResourceRequest() {
  yield takeLatest(CREATE_RESOURCE_REQUEST, function* ({ payload }) {
    try {
      
      const result = yield call(Logistics.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createResourceRequestSuccess(result.data));
      
      } else {
        yield put(createResourceRequestFailure (result.message));
      }
    } catch (error) {
      yield put(createResourceRequestFailure (error.message));
    }
  });
}

export function* onUpdateResourceRequest() {
  yield takeLatest(UPDATE_RESOURCE_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(Logistics.update,payload);
          if(result.status){
          message.success("Success");
          yield put(updateResourceRequestSuccess(result.data));
        } else {
          message.error("Error")
          yield put(updateResourceRequestFailure (result.message));
        }
    } catch (error) {
      yield put(updateResourceRequestFailure (error.message));
    }
  });
}

export function* onUpdateResourceRequestStatus() {
  yield takeLatest(UPDATE_RESOURCE_REQUEST_STATUS, function* ({ payload }) {
    try {
      const result = yield call(Logistics.updateResourceRequestStatus,payload);
          if(result.status){
          message.success(payload.whichButton);
          yield put(updateResourceRequestStatusSuccess(result.data));
        } else {
          message.error("Error")
          yield put(updateResourceRequestStatusFailure (result.message));
        }
    } catch (error) {
      yield put(updateResourceRequestStatusFailure (error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchResourceRequest),fork(onCreateResourceRequest),fork(onUpdateResourceRequestStatus), fork(onUpdateResourceRequest)]);
}