import _ from "lodash"

export const addLogistics = (state, action) => {
  let list = state.list;
  const savedLogistics = action.payload;
  return [savedLogistics, ...list];
};

export const updateRequest = (state, action) => {
  let list = state.list;
  const updatedLogistics = action.payload;
  const index = _.findIndex(list, ["request_id", updatedLogistics.request_id]);
  list[index] = updatedLogistics;
  return [...list];
};

export const updateRequestStatus = (state, action) => {
  let list = state.list;
  const updatedLogistics = action.payload;
  const index = _.findIndex(list, ["request_id", updatedLogistics.request_id]);
  list[index] = updatedLogistics;
  return [...list];
};