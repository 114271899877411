import _ from "lodash";

export const addBranchLookup = (state, action) => {
  let list = state.list;
  const savedBranchLookup = action.payload;

  return [savedBranchLookup, ...list];
};

export const updateBranchLookup = (state, action) => {
  let list = state.list;
  const updatedBranchLookup = action.payload;

  const index = _.findIndex(list, ["id", updatedBranchLookup.id]);
  list[index] = updatedBranchLookup;

  return [...list];
};

export const removeBranchLookup = (state, action) => {
  let list = state.list;
  const deletedBranchLookup = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedBranchLookup.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
