
import {
    FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
    FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
    FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS
} from "constants/DailyFinancialSummary";


const initState = {
     loading : false, 
     list : [],
     errorMessage : {}
}

const  DailyFinancialSummary = (state = initState, action) =>{
     switch(action.type){
        case FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST :
            return {
                ...state,
                loading : true
            }

        case FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS :
            return {
                ...state,
                list : action.payload,
                loading : false
            }

        case FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE :
            return {
                ...state,
                loading : false
            }     
        default : return state;       
     }
}

export default DailyFinancialSummary;