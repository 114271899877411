import {
  FETCH_TOPAGENCIES_FAILURE,
  FETCH_TOPAGENCIES_REQUEST,
  FETCH_TOPAGENCIES_SUCCESS,
} from "constants/TopAgencies";

export const fetchTopAgenciesRequest = (startDate, endDate, agency, department) => ({
  type: FETCH_TOPAGENCIES_REQUEST,
  payload : {
     startDate, 
     endDate, 
     agency,
     department
  }
});

export const fetchTopAgenciesSuccess = (topagencies) => ({
  type: FETCH_TOPAGENCIES_SUCCESS,
  payload: topagencies,
});

export const fetchTopAgenciesFailure = (error) => ({
  type: FETCH_TOPAGENCIES_FAILURE,
  payload: error,
});
