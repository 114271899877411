import {
  FETCH_DISCREPANCY_SUCCESS,
  FETCH_DISCREPANCY_REQUEST,
  FETCH_DISCREPANCY_FAILURE,
  
  FETCH_DISCREPANCYOBX_SUCCESS,
  FETCH_DISCREPANCYOBX_REQUEST,
  FETCH_DISCREPANCYOBX_FAILURE,

  UPDATE_DISCREPANCY_REQUEST,
  UPDATE_DISCREPANCY_SUCCESS,
  UPDATE_DISCREPANCY_FAILURE,
  SHOW_DISCREPANCY_STATUS_CHANGER_DIALOG,
} from "constants/Discrepancy";

import {
  updateDiscrepancy,
} from "utils/Discrepancy";

const initState = {
  loading: false,
  list: [],
  obxlist:[],
  errorMessage: "",
  view : false,
  updating : false
};

const discrepancy = (state = initState, action) => {
  switch (action.type) {
    case SHOW_DISCREPANCY_STATUS_CHANGER_DIALOG : 
    return {
        ...state,
        view : !state.view
    }
    case FETCH_DISCREPANCY_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_DISCREPANCY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    case FETCH_DISCREPANCY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
      case FETCH_DISCREPANCYOBX_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_DISCREPANCYOBX_SUCCESS:
        return {
          ...state,
          loading: false,
          obxlist: action.payload,
        };
      case FETCH_DISCREPANCYOBX_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };      
        case UPDATE_DISCREPANCY_REQUEST:
        return {
            ...state,
            updating: true,
            errorMessage: "",
            // validationErrors: [],
          };
        case UPDATE_DISCREPANCY_SUCCESS:
          return {
            ...state,
            updating: false,
            list: updateDiscrepancy(state, action),
            errorMessage: "",
            view : !state.view,
            // validationErrors: [],
            // showEditForm: false,
          };
        case UPDATE_DISCREPANCY_FAILURE:
          return {
            ...state,
            updating: false,
            errorMessage: action.payload,
            // validationErrors: [],
          };

    default:
      return state;
  }
};

export default discrepancy;
