import{
    FETCH_PATHOLOGY_RESULT_FAILURE,
    FETCH_PATHOLOGY_RESULT_REQUEST,
    FETCH_PATHOLOGY_RESULT_SUCCESS
} from "constants/Pathology"


const pathologyResults = {
    list : [],
    loading : false, 
    errorMessage : ""
}

const Pathology = (state = pathologyResults, action) =>{
     switch(action.type){
        case  FETCH_PATHOLOGY_RESULT_REQUEST : 
        return {
            ...state,
                loading : true
        }
        case FETCH_PATHOLOGY_RESULT_SUCCESS : 
        return {
            ...state,
            list : action.payload,
            loading : false
        }
        case FETCH_PATHOLOGY_RESULT_FAILURE : 
        return {
            ...state,
            loading : false, 
        }
        default : return state
     }
}

export default Pathology;