import{ 
    FETCH_PENDING_ORDER_REQUEST,
    FETCH_PENDING_ORDER_SUCCESS,
    FETCH_PENDING_ORDER_FAILURE,
    FETCH_PENDING_ORDER_OBX_REQUEST,
    FETCH_PENDING_ORDER_OBX_SUCCESS,
    FETCH_PENDING_ORDER_OBX_FAILURE,
    UPDATE_PENDING_ORDER_REQUEST,
    UPDATE_PENDING_ORDER_SUCCESS,
    UPDATE_PENDING_ORDER_FAILURE,
    SHOW_PENDING_ORDER_OBX_DIALOG
} from "constants/OrderStatus/PendingOrders"
    
    export const fetchPendingOrderRequest = (startDate, endDate, agency, department) =>{
         return {
             type : FETCH_PENDING_ORDER_REQUEST,
             payload : {
                startDate, 
                endDate,
                agency, 
                department
             }
         }
    }
    
    export const fetchPendingOrderSuccess = (payload) =>{ 
        return { 
            type : FETCH_PENDING_ORDER_SUCCESS,
            payload : payload
        }
    }
    
    export const fetchPendingOrderFailure = (payload) => { 
        return {
             type : FETCH_PENDING_ORDER_FAILURE,
             payload : payload
        }
    }

    export const fetchPendingOrderObxRequest = (visitUuid) =>{ 
        return { 
            type : FETCH_PENDING_ORDER_OBX_REQUEST,
            payload : {
                visitUuid
            }
        }
    }
    
    export const fetchPendingOrderObxSuccess = (payload) => { 
        return {
             type : FETCH_PENDING_ORDER_OBX_SUCCESS,
             payload : payload
        }
    }

    export const fetchPendingOrderObxFailure = (payload) => { 
        return {
             type : FETCH_PENDING_ORDER_OBX_FAILURE,
             payload : payload
        }
    }
    
    export const updatePendingOrderRequest = (payload) =>{
        return { 
            type : UPDATE_PENDING_ORDER_REQUEST,
            payload : payload
        }
    }
    
    export const updatePendingOrderSuccess = (payload) =>{ 
        return { 
            type :  UPDATE_PENDING_ORDER_SUCCESS,
            payload : payload
        }
    }
    
    export const updatePendingOrderFailure = (payload) =>{
         return { 
            type : UPDATE_PENDING_ORDER_FAILURE,
            payload : payload
         }
    }

    export const showPendingOrderObxDialog = (payload) =>{
         return {
             type : SHOW_PENDING_ORDER_OBX_DIALOG,
             payload : payload
         }
    }