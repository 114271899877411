import _ from "lodash";

export const addPatient = (state, action) => {
  let list = state.list;
  const savedPatient = action.payload;

  return [savedPatient, ...list];
};

export const updatePatient = (state, action) => {
  let list = state.list;
  const updatedPatient = action.payload;

  const index = _.findIndex(list, ["id", updatedPatient.id]);
  list[index] = updatedPatient;

  return [...list];
};

export const removePatient = (state, action) => {
  let list = state.list;
  const deletedPatientId = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedPatientId;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
