import fetch from "auth/FetchInterceptor";

const TestDirectoryService = {};

TestDirectoryService.index = function () {
  return fetch({
    url: "/getPanels",
    method: "get",
  });
};

TestDirectoryService.create = function (data) {
  return fetch({
    url: "/test-directories",
    method: "post",
    data,
  });
};

TestDirectoryService.update = function (id, data) {
  return fetch({
    url: `/test-directories/${id}`,
    method: "put",
    data,
  });
};

TestDirectoryService.delete = function (id) {
  return fetch({
    url: `/test-directories/${id}`,
    method: "delete",
  });
};

TestDirectoryService.getUrgentAndStatPrice = function (){
  return fetch({
    url : `/getStatAndUrgentOrderPrice`,
    method : 'GET'
  });
};

 TestDirectoryService.updateUrgentAndStatPrice = function (payload){
  return fetch({
     url : `/updateStatandUrgentOrderPriceRoute`,
     'method' : 'POST',
     data : payload
  })
};

export default TestDirectoryService;
