import {
  FETCH_BRANCH_TEST_CAPACITIES_SUCCESS,
  FETCH_BRANCH_TEST_CAPACITIES_REQUEST,
  FETCH_BRANCH_TEST_CAPACITIES_FAILURE,
  UPDATE_BRANCH_TEST_CAPACITY_REQUEST,
  UPDATE_BRANCH_TEST_CAPACITY_SUCCESS,
  UPDATE_BRANCH_TEST_CAPACITY_FAILURE,
  SHOW_BRANCH_TEST_CAPACITY_EDIT_FORM,
  DELETE_BRANCH_TEST_CAPACITY_REQUEST,
  DELETE_BRANCH_TEST_CAPACITY_SUCCESS,
  DELETE_BRANCH_TEST_CAPACITY_FAILURE,
  SET_BRANCH_TEST_CAPACITY_VALIDATION_ERRORS,
  SHOW_BRANCH_TEST_CAPACITY_NEW_FORM,
  CREATE_BRANCH_TEST_CAPACITY_REQUEST,
  CREATE_BRANCH_TEST_CAPACITY_SUCCESS,
  CREATE_BRANCH_TEST_CAPACITY_FAILURE,
  FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST,
  FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_SUCCESS,
  FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_FAILURE,
  CLEAR_AVAILABLE_BRANCH_TEST_CAPACITIES,
} from "constants/BranchTestCapacity";

import {
  addBranchTestCapacity,
  formtValidationErrors,
  removeBranchTestCapacity,
  updateBranchTestCapacity,
} from "utils/BranchTestCapacity";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  available: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const branchTestCapacity = (state = initState, action) => {
  switch (action.type) {
    case FETCH_BRANCH_TEST_CAPACITIES_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_BRANCH_TEST_CAPACITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_BRANCH_TEST_CAPACITIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_BRANCH_TEST_CAPACITY_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_BRANCH_TEST_CAPACITY_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_BRANCH_TEST_CAPACITY_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_BRANCH_TEST_CAPACITY_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_BRANCH_TEST_CAPACITY_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateBranchTestCapacity(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_BRANCH_TEST_CAPACITY_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_BRANCH_TEST_CAPACITY_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_BRANCH_TEST_CAPACITY_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeBranchTestCapacity(state, action),
      };
    case DELETE_BRANCH_TEST_CAPACITY_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_BRANCH_TEST_CAPACITY_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_BRANCH_TEST_CAPACITY_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addBranchTestCapacity(state, action),
      };
    case CREATE_BRANCH_TEST_CAPACITY_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    case FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST:
      return {
        ...state,
        loading: true,
        available: [],
      };
    case FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        available: action.payload,
      };
    case FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case CLEAR_AVAILABLE_BRANCH_TEST_CAPACITIES:
      return {
        ...state,
        loading: false,
        available: [],
      };
    default:
      return state;
  }
};

export default branchTestCapacity;
