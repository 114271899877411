export const addTestDirectory = (state, action) => {
  let selectedTestDirectories = state.selectedTestDirectories;
  if (selectedTestDirectories.includes(action.payload)) {
    const index = selectedTestDirectories.indexOf(action.payload);
    if (index > -1) {
      selectedTestDirectories.splice(index, 1);
    }
    return [...selectedTestDirectories];
  }
  // return [...selectedTestDirectories, action.payload];
  return [action.payload]
};

export const addPatient = (state, action) => {
  const patients = state.patients;
  const id = patients.length + 1;
  const patient = { id, ...action.payload };
  return [...patients, patient];
};

export const removePatient = (state, action) => {
  const patients = state.patients;
  const id = action.payload
  const filteredPatients = patients.filter(patient => patient.id  !== id)
  return [...filteredPatients];
};
