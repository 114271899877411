import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { notification } from "antd";
import OrderMadeByGuestUsersService from "services/OrderMadeByGuestUsers";
import {
  FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
} from "../../constants/OrderMadeByGuestUsers";

import {
  fetchOrderMadeByGuestUsersSuccess,
  fetchOrderMadeByGuestUsersFailure,
  setOrderMadeByGuestUsersValidationError,
  deleteOrderMadeByGuestUsersSucess,
  deleteOrderMadeByGuestUsersFailure,
  updateOrderMadeByGuestUsersSuccess,
  updateOrderMadeByGuestUsersFailure,
} from "../actions/OrderMadeByGuestUsers";

const showNotification = (placement, type, BodyMessage) => {
  notification[type]({
    placement,
    message: "Message",
    description: BodyMessage,
  });
};

export function* onFetchOrderMadeByGuestUser() {
  yield takeLatest(
    FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST,
    function* (payload) {
      try {
        const result = yield call(
          OrderMadeByGuestUsersService.get,
          payload.payload
        );
        if (result.status) {
          yield put(fetchOrderMadeByGuestUsersSuccess(result.data));
        } else {
          showNotification("bottomRight", "error", "Error Fetching The Users");
          yield put(fetchOrderMadeByGuestUsersFailure(result.message));
        }
      } catch (error) {
        yield put(fetchOrderMadeByGuestUsersFailure(error.message));
      }
    }
  );
}

export function* onUpdateOrderMadeByGuestUser() {
  //alert("called");
  yield takeLatest(
    UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
    function* ({ payload }) {
      try {
        const result = yield call(
          OrderMadeByGuestUsersService.update,
          payload.ExistingUserName,
          payload.NewUser
        );

        if (result.status) {
          showNotification(
            "bottomRight",
            "success",
            "Successfully Updated The Data"
          );
          yield put(updateOrderMadeByGuestUsersSuccess(result.data));
        } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
          showNotification(
            "bottomRight",
            "error",
            "Oops Error Updating The Data \n, make sure that you fill the datas correctly"
          );
          yield put(
            setOrderMadeByGuestUsersValidationError(result.data.errors)
          );
          yield put(updateOrderMadeByGuestUsersFailure(result.message));
        } else {
          showNotification(
            "bottomRight",
            "error",
            "Oops Error Updating The Data"
          );
          yield put(updateOrderMadeByGuestUsersFailure(result.message));
        }
      } catch (error) {
        showNotification(
          "bottomRight",
          "error",
          "Oops Error Updating The Data"
        );
        yield put(updateOrderMadeByGuestUsersFailure(error.message));
      }
    }
  );
}

export function* onDeleteOrderMadeByGuestUser() {
  yield takeLatest(
    DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
    function* ({ payload }) {
      try {
        const result = yield call(OrderMadeByGuestUsersService.delete, payload);
        if (result.status) {
          showNotification(
            "bottomRight",
            "success",
            "Sucessfully Deleted The User"
          );
          yield put(deleteOrderMadeByGuestUsersSucess(result.data));
        } else {
          showNotification(
            "bottomRight",
            "error",
            "Oops Error Deleting The Data \n, make sure that you fill the datas correctly"
          );
          yield put(deleteOrderMadeByGuestUsersFailure(result.message));
        }
      } catch (error) {
        showNotification(
          "bottomRight",
          "error",
          "Oops Error Deleting The Data \n, make sure that you fill the datas correctly"
        );
        yield put(deleteOrderMadeByGuestUsersFailure(error.message));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(onFetchOrderMadeByGuestUser),
    fork(onUpdateOrderMadeByGuestUser),
    fork(onDeleteOrderMadeByGuestUser),
  ]);
}
