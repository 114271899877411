import { all, call, fork, put, takeLatest , delay} from "@redux-saga/core/effects";
import { FetchTbMicroFailure, FetchTbMicroRequest, FetchTbMicroSuccess } from "redux/actions";
import { FETCH_TB_MICRO_RESULTS_REQUEST } from "constants/TB_Micro";
import TbMicroResultsService from "services/TbMicro";
import { PENDING_RESULT_REFRESH_TIME } from "configs/AppConfig";

export function* onFetchTbMicroResults() {
  yield takeLatest(FETCH_TB_MICRO_RESULTS_REQUEST, function* (payload) {
    try {
      const result = yield call(TbMicroResultsService.get, payload.payload);
      if (result.status) {
        yield put(FetchTbMicroSuccess(result.data));
        yield delay(PENDING_RESULT_REFRESH_TIME);
        yield put(FetchTbMicroRequest());
      } else {
        yield put(FetchTbMicroFailure(result.message));
        yield delay(PENDING_RESULT_REFRESH_TIME);
        yield put(FetchTbMicroRequest());
      }
    } catch (error) {
      yield put(FetchTbMicroFailure(error.message));
      yield delay(PENDING_RESULT_REFRESH_TIME);
      yield put(FetchTbMicroRequest());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchTbMicroResults)]);
}
