import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_DETAILED_PATIENT_RESULT_REQUEST, FETCH_PATIENTRESULTS_REQUEST, FETCH_PATIENT_HISTORY_REQUEST } from "constants/PatientResult";
import {
  fetchDetailedPatientFailure,
  fetchDetailedPatientSuccess,
  fetchPatientHistoryFailure,
  fetchPatientHistorySucess,
  fetchPatientResultsFailure,
  fetchPatientResultsSuccess,
} from "redux/actions";
import PatientResultService from "services/PatientResult";

export function* onFetchPatientResults() {
  yield takeLatest(FETCH_PATIENTRESULTS_REQUEST, function* (payload) {
    try {
      const result = yield call(PatientResultService.index, payload.payload);
      if (result.code === 200) {
        yield put(fetchPatientResultsSuccess(result.data));
      } 
    //  else if(result.code === 401){
    //     const signOutUser = yield call(FirebaseService.signOutRequest);
    //     if (signOutUser === undefined) {
    //       localStorage.removeItem(AUTH_TOKEN);
    //       localStorage.removeItem(CURRENT_USER);
    //       localStorage.removeItem(LOGIN_EXPIRATION_TIME);
    //       yield put(signOutSuccess(signOutUser));
    //     }
    //   } 
      else {
        message.error("Something went wrong");
        yield put(fetchPatientResultsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(fetchPatientResultsFailure(error.message));
    }
    // console.log('aaaa');
  });
}

export function* onFetchPatientHistory() {
  yield takeLatest(FETCH_PATIENT_HISTORY_REQUEST, function* (payload) {
    try {
      const result = yield call(PatientResultService.searchpatient, payload.payload);
      if (result.status) {
        yield put(fetchPatientHistorySucess(result.data));
      } else {
        message.error("Error fetching your data");
        yield put (fetchPatientHistoryFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  });
}

export function* onFetchDetailedPatientResult() {
  yield takeLatest(FETCH_DETAILED_PATIENT_RESULT_REQUEST, function* (payload) {
    try {
      const result = yield call(PatientResultService.fetchDetailedPatientResult, payload.payload);
      if (result.status) {
        yield put(fetchDetailedPatientSuccess(result.data));
      } else {
        message.error("Error fetching your data");
        yield put (fetchDetailedPatientFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  });
}


export default function* rootSaga() {
  yield all([fork(onFetchPatientResults), fork(onFetchPatientHistory), fork(onFetchDetailedPatientResult)]);
}
