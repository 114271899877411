import {
    FETCH_TATAPPROACHING_REQUEST, 
    FETCH_TATAPPROACHING_FAILURE,
    FETCH_TATAPPROACHING_SUCCESS
} from "constants/TATApproaching";


const initState = {
     loading : false, 
     list : [],
     errorMessage : {}
}

const  TatApproaching = (state = initState, action) =>{
     switch(action.type){
        case FETCH_TATAPPROACHING_REQUEST :
            return {
                ...state,
                loading : true
            }

        case FETCH_TATAPPROACHING_SUCCESS :
            return {
                ...state,
                list : action.list,
                loading : false
            }

        case FETCH_TATAPPROACHING_FAILURE :
            return {
                ...state,
                loading : false
            }     
        default : return state;       
     }
}

export default TatApproaching;