import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_WEBLEDGER_REQUEST, UPDATE_WEBLEDGER_REQUEST } from "constants/WebLedgerInfo";
import {
  fetchWebedgerFailure,
  fetchWebedgerSuccess,
  updateWebLedgerFailure,
  updateWebLedgerSuccess,
} from "redux/actions";
import WebLedgerInfoService from "services/WebLedgerInfoService";


export function* onFetchWebLedgerInfoResult() {
  yield takeLatest(
    FETCH_WEBLEDGER_REQUEST,
    function* ({ payload }) {
      try {
        const result = yield call(
          WebLedgerInfoService.index,
          payload
        );
        if(result.status & (result.data.length < 1)) {
          yield put(fetchWebedgerFailure(result.message));
          message.error('No Record Found.');
        }
        if(result.status & (result.data.length > 0)) {
          yield put(fetchWebedgerSuccess(result.data));
        } else {
          yield put(fetchWebedgerFailure(result.message));
        }
      } catch (error) {
        yield put(fetchWebedgerFailure(error.message));
      }
    }
  );
}

export function* onUpdateWebLedgerInfoResult() {
  yield takeLatest(UPDATE_WEBLEDGER_REQUEST, function* ({ payload }) {
debugger
    try {
      const result =  yield call(
        WebLedgerInfoService.create,
        payload
      );
      if (result.status) {
        yield put(updateWebLedgerSuccess(result.data));
        message.success("record is successfully updated.");
      } else {
        yield put(updateWebLedgerFailure(result.message));
      }
    } catch (error) {
      yield put(updateWebLedgerFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([fork(onFetchWebLedgerInfoResult)]);
  yield all([fork(onUpdateWebLedgerInfoResult)]);
}
