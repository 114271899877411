import { UserOutlined } from "@ant-design/icons";
import { Avatar, notification } from "antd";
import { DOCTOR_PREFIX_PATH } from "configs/AppConfig";


 export const ShowNotification = (type, placement, BodyMessage, avatarUrl, history, shouldShowIcon = true) =>{

      notification[type]({
         placement : placement,
         message : "Message",
         //description: BodyMessage
         description: (
            <div>
                  {avatarUrl ? 
                        <div onClick={() => history.push(`${DOCTOR_PREFIX_PATH}/chat-room`)} style={{cursor: 'pointer', position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', right: '5%', minWidth: 'fit-content'}}>
                              <Avatar src={avatarUrl} style={{ width: '50px', height: '50px', flexShrink: 0 }} />
                              <div style={{wordBreak: 'break-all'}}>{BodyMessage}</div>   
                        </div> 
                        : 
                        BodyMessage
                  }
                  
            </div>
         ),
            icon: (
                  shouldShowIcon ? 
            <Avatar src={"https://www.gravatar.com/avatar/icldev@gmail.com"} icon={<UserOutlined />} /> : null // Custom avatar with fallback icon
          ) ,
        })
  }