import fetch from "auth/FetchInterceptor";

const InPersonDelivery = {};

InPersonDelivery.get = function (payload) {
    return fetch({
        url:
        `/reports/patientresult-in-person?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}&department=${payload.department}`,
      method: "get",
    });
  };
  
export default InPersonDelivery;
