import {
  FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_SUCCESS,
  FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST,
  FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_FAILURE,
  UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  SHOW_CLIENT_COMPANY_TEST_DIRECTORY_EDIT_FORM,
  DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  DELETE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  DELETE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  SET_CLIENT_COMPANY_TEST_DIRECTORY_VALIDATION_ERRORS,
  SHOW_CLIENT_COMPANY_TEST_DIRECTORY_NEW_FORM,
  CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  CREATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  CREATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
} from "constants/ClientCompanyTestDirectory";

import {
  addClientCompanyTestDirectory,
  formtValidationErrors,
  removeClientCompanyTestDirectory,
  updateClientCompanyTestDirectory,
} from "utils/ClientCompanyTestDirectory";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const clientCompanyTestDirectory = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_CLIENT_COMPANY_TEST_DIRECTORY_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_CLIENT_COMPANY_TEST_DIRECTORY_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_CLIENT_COMPANY_TEST_DIRECTORY_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateClientCompanyTestDirectory(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeClientCompanyTestDirectory(state, action),
      };
    case DELETE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addClientCompanyTestDirectory(state, action),
      };
    case CREATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default clientCompanyTestDirectory;
