import {
  FETCH_WEBLEDGER_FAILURE,
  FETCH_WEBLEDGER_REQUEST,
  FETCH_WEBLEDGER_SUCCESS,
  UPDATE_WEBLEDGER_FAILURE,
  UPDATE_WEBLEDGER_REQUEST,
  UPDATE_WEBLEDGER_SUCCESS,
  RESER_WEBLEDGER
} from "constants/WebLedgerInfo";

export const fetchWebedgerRequest = (
  sampleId,
) => ({
  type: FETCH_WEBLEDGER_REQUEST,
  payload: {
    sampleId
  },
});


export const fetchLedgerPatientSearch = (
  sampleId,
) => ({
  type: FETCH_WEBLEDGER_REQUEST,
  payload: {
    sampleId
  },
});


export const fetchWebedgerSuccess = (
  panicandabnormaltestresults
) => ({
  type: FETCH_WEBLEDGER_SUCCESS,
  payload: panicandabnormaltestresults,
});

export const fetchWebedgerFailure = (error) => ({
  type: FETCH_WEBLEDGER_FAILURE,
  payload: error,
});


export const updateWebLedgerRequest = (payload) => ({
  type: UPDATE_WEBLEDGER_REQUEST,
  payload: payload,
});

export const updateWebLedgerSuccess = (payload) => ({
  type: UPDATE_WEBLEDGER_SUCCESS,
  payload: payload,
});

export const updateWebLedgerFailure = (error) => ({
  type: UPDATE_WEBLEDGER_FAILURE,
  payload: error,
});

export const ResetWebLedger = () => ({
  type: RESER_WEBLEDGER,
  payload: null,
});
